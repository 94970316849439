<template>
  <div class="">
    <el-row class="filter-header" :gutter="20">
      <el-col>
        <el-date-picker v-model="itemCreated" type="datetimerange" :picker-options="pickerOptions" start-placeholder="最后操作时间开始" ange-separator="至" end-placeholder="最后操作时间结束" value-format="timestamp" align="left" @change="datePicker" :default-time="['00:00:00', '23:59:59']" placeholder="选择日期时间范围">
        </el-date-picker>

        <el-input class="input-m" placeholder="请输入订单号" v-model="params.order_id" clearable @clear="onSearch"
                  @change="onSearch()"></el-input>
<!--        <el-select v-model="params.op_name" placeholder="全部类型" clearable @change="onSearch">-->
<!--          <el-option v-for="item in options" clearable :key="item.value" :label="item.value" :value="item.label">-->
<!--          </el-option>-->
<!--        </el-select>-->

        <el-button type="primary" size="medium" @click="exportData" v-loading="exportloading">导出</el-button>

      </el-col>
    </el-row>
    <el-button @click="addDistributorAction" v-if="login_type === 'disti' || login_type === 'dealer'">筛选门店</el-button>
    <el-tag type="success" closable @close="clearShop()" v-if="shop_num >0">已选择{{shop_num}}家门店</el-tag>

    <el-tabs v-model="params.status" @tab-click="onSearch">
      <el-tab-pane v-for="(item, index) in tabsList" :key="index" :label="item.label" :name="item.value">
        <el-table :data="tableList" style="width: 100%" v-loading="loading">
          <el-table-column prop="op_time" label="最后操作时间" width="170">
            <template slot-scope="scope">
              <span>{{ scope.row.op_time | datetime('yyyy-MM-dd hh:mm:ss') }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="order_id" label="订单号"  width="250">
            <template slot-scope="scope">
              {{scope.row.order_id}}
            </template>
          </el-table-column>
          <el-table-column prop="op_name" label="最后处理动作"  width="130"></el-table-column>
<!--          <el-table-column prop="item_count" label="商品"  width="120">-->
<!--            <template slot-scope="scope">-->
<!--              共{{scope.row.item_count}}件商品-->
<!--              <el-popover-->
<!--                placement="bottom"-->
<!--                width="400"-->
<!--                trigger="click">-->
<!--                <el-form  size="mini" label-position="left" >-->
<!--                  <div v-for="(row,key) in scope.row.item_info" >-->
<!--                    <el-row>-->
<!--                      <el-col style="font-size: 14px">{{ row.name }}</el-col><br>-->
<!--                      <el-col style="font-size: 12px;color: #a2a2a2">SN：{{ row.sn }}</el-col>-->
<!--                      <el-divider></el-divider>-->
<!--                    </el-row>-->
<!--                  </div>-->
<!--                </el-form>-->
<!--                <el-link :underline="false"   slot="reference"  type="primary">查看</el-link>-->
<!--              </el-popover>-->
<!--            </template>-->
<!--          </el-table-column>-->
          <el-table-column prop="shop_name" label="门店信息">
            <template slot-scope="scope">
              {{scope.row.shop_name}}<br>
              {{scope.row.shop_code}}
            </template>
          </el-table-column>
          <el-table-column prop="option_name" label="最后操作账号">
            <template slot-scope="scope">
              {{scope.row.option_name}}
<!--              <el-popover-->
<!--                placement="bottom"-->
<!--                width="400"-->
<!--                trigger="click"-->
<!--              :content="scope.row.op_info.remark">-->
<!--                <el-button type="warning" slot="reference" plain size="mini" >操作备注</el-button>-->
<!--              </el-popover>-->
            </template>

          </el-table-column>
          <el-table-column prop="option_name" label="操作">
            <template slot-scope="scope">
              <el-link :underline="false"  type="success" @click="showRecord(scope.row.order_id)">明细</el-link>
            </template>

          </el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>

    <div class="pagination">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="params.page" :page-sizes="[10, 20, 30, 50]" :page-size="params.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total_count">
      </el-pagination>
    </div>

    <div v-if="login_type === 'dealer'">
      <DistributorSelect :store-visible="distributorVisible" :is-valid="true" :relDataIds="distributor_info"
                       :get-status="setDistributorStatus" @chooseStore="chooseDistributorAction" @closeStoreDialog="closeDialogAction"
                       returnType="selectRow"></DistributorSelect>
    </div>
    <div v-else>
      <StoreListSelect
        :store-visible="distributorVisible"
        :is-valid="true"
        :relDataIds="distributor_info"
        :get-status="setDistributorStatus"
        @chooseStore="chooseDistributorAction"
        @closeStoreDialog="closeDialogAction"
        returnType="selectRow"
      ></StoreListSelect>
    </div>
    <el-drawer
      title="订单操作记录"
      :visible.sync="polymerization"
      :before-close="handleClose" >
      <div v-loading="polymerizationLoading" element-loading-text="数据加载中。。。" style="min-height:600px">
        <el-tag style="margin-left: 50px;font-size: 14px" type="success" >订单号：{{polymerizationOrder}}</el-tag>
        <div class="block" style="margin: 20px 0 0 20px" >
          <el-timeline>
            <el-timeline-item
              v-for="(activity, index) in activities"
              :key="index"
            >
              <el-card>
                <h4 slot="header" >{{activity.op_name}}
                  <el-divider direction="vertical"></el-divider>
                  {{ activity.op_time| datetime('yyyy-MM-dd hh:mm:ss') }}</h4>
                商品信息：
                <div v-for="(row,key) in activity.item_info" style="margin-left: 30px" >
                  <el-row>
                    <el-col style="font-size: 14px">{{ row.name }}</el-col><br>
                    <el-col style="font-size: 12px;color: #a2a2a2">SN：{{ row.sn }}</el-col>
                    <el-divider></el-divider>
                  </el-row>
                </div>
                <span class="frm-tips">操作账号：{{activity.option_name}}</span><br>
                <span class="frm-tips">操作备注：{{activity.op_info.remark}}</span>
              </el-card>

            </el-timeline-item>
          </el-timeline>
        </div>
      </div>

    </el-drawer>

  </div>
</template>
<script>
import { getSelfHandleLog, getSelfHandleLogOption,getLogById,exportSHLog } from '@/api/tool'
import StoreListSelect from "@/components/storeListSelect";
import DistributorSelect from '@/components/function/distributorSelect'

import shopSelect from "@/components/shopSelect";
import ImportDialog from "@/components/importDialog";
import ExportDialog from "@/components/exportDialog";
import DealerSelect from "@/components/function/dealerSelect";
import GoodsSelector from "@/components/goodsSelect";
import PiiInfo from "@/components/piiInfo";
import {mapGetters} from "vuex";

export default {
  computed: {
    ...mapGetters(['login_type']),
  },
  components: {
    DistributorSelect,
    StoreListSelect
  },
  data() {
    return {
      polymerization:false,//订单操作记录聚合
      polymerizationLoading:false,
      polymerizationOrder:'',
      distributorVisible:false,
      setDistributorStatus: false,
      distributor_info: [],
      shop_num:0,
      activities:[],

      options: [],
      exportloading: false,
      tableList: [],
      loading: false,
      total_count: 0,
      itemCreated: '',
      params: {
        page: 1,
        pageSize: 10,
        created: '',
        op_name: '',
        order_id:'',
        distributor_id:''
      },
      time_start_begin: '',
      time_start_end: '',
      tabsList: [
        { label: '', value: '' },
      ],
      pickerOptions: {
        shortcuts: [
          {
            text: '今日',
            onClick(picker) {
              const start = new Date();
              const end = new Date();
              start.setTime(new Date(new Date().toLocaleDateString()).getTime());
              end.setTime(new Date(new Date().toLocaleDateString()).getTime() + 3600 * 1000 * 24 - 1000);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '最近一周',
            onClick(picker) {
              const start = new Date();
              const end = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 6);
              end.setTime(end.getTime() - 3600 * 1000 * 24 * 0);
              picker.$emit('pick', [start, end]);
            }
          } ,{
            text: '最近一个月',
            onClick(picker) {
              const start = new Date();
              const end = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 29);
              end.setTime(end.getTime() - 3600 * 1000 * 24 * 0);
              picker.$emit('pick', [start, end]);
            }
          }
          ]
      },
    }
  },
  methods: {
    handleClose(){
      this.polymerization=false
    },
    showRecord(orderId){
      this.polymerization=true
      this.polymerizationLoading=true
      this.activities=[]
      this.polymerizationOrder=orderId
      this.getRecordListById(orderId)

    },
    chooseDistributorAction(val) {
      this.distributor_info = val
      let arr = []
      val.forEach(i => {
        arr.push(i.distributor_id)
      });
      this.params.distributor_id = arr.join(',')
      if (val.length > 0) {
        this.shop_num = val.length
      }
      this.onSearch()
      this.closeDialogAction()
    },
    addDistributorAction() {
      this.distributorVisible = true;
      this.setDistributorStatus = true;
    },
    closeDialogAction() {
      this.distributorVisible = false
    },
    clearShop(){
      this.params.distributor_id =''
      this.shop_num=0
      this.distributor_info =[]
      this.onSearch()
    },
    optionList() {
      getSelfHandleLogOption().then(res => {
        let data = res.data.data
        let arr = []
        for (let i in data)
          arr.push(
            {
              label: i,
              value: data[i]
            }
          )
        this.options = arr
        console.log(this.options);
      })
    },
    handleSizeChange(page_size) {
      this.params.pageSize = page_size
      this.getDataList()
    },
    handleCurrentChange(page_num) {
      this.params.page = page_num
      this.getDataList()
    },
    datePicker(val) {
      if (val) {
        this.params.created = [val[0] / 1000, val[1] / 1000]
      } else {
        this.params.created = ''
      }
      this.onSearch()
    },
    onSearch() {
      this.params.page = 1
      this.getDataList()
    },
    getDataList() {
      this.loading = true
      console.log(this.params);
      getSelfHandleLog(this.params).then(response => {
        let data = response.data.data
        this.tableList = data.list
        this.total_count = data.total_count
        this.loading = false
      })
    },
    getRecordList(orderId){
      //activities
      this.params.order_id = orderId
      this.params.page = 1
      this.params.pageSize = -1
      getSelfHandleLog(this.params).then(response => {
        this.polymerizationLoading =false
        let data = response.data.data
        this.activities = data.list
      })
    },
    getRecordListById(orderId){
      //activities
      let postdata= {
        order_id:orderId
      }
      getLogById(postdata).then(response => {
        this.polymerizationLoading =false
        let data = response.data.data
        this.activities = data.list
      })
    },
    exportData() {
      let obg = { ...this.params }
      obg.export = true
      exportSHLog(obg).then(response => {
        this.$message({
          message: '已加入执行队列，请在设置-导出列表中下载',
          type: 'success'
        });
      })
    }
  },
  mounted() {
    // this.optionList()
    this.getDataList();
  }
}
</script>
<style lang="scss">
.pagination{
  margin-top: 20px;;
  text-align: center;
}
</style>
