import { createSetting } from '@shopex/finder'
import { MessageBox } from 'element-ui'
import { deletePop, setPopSort } from '../api'
import { useDialog } from '@shopex/finder'

export const commonDialogActions = [
  { label: '取消', key: 'close', size: 'default' },
  { label: '确定', key: 'save', type: 'primary', size: 'default' }
]

export const pageSetting = (vm) => createSetting({
  search: [],
  columns: [
    { name: '弹窗页面', key: 'name', minWidth: 120 }
  ],
  actions: [
    {
      name: '设置',
      type: 'button',
      buttonType: 'text',
      action: {
        handler: (row) => {
          const pageKey = row[0].key
          useDialog(null, {
            title: `设置弹窗展示规则`,
            width: '30%',
            pageKey,
            component: () => import('../components/pop-rule.vue'),
            actions: commonDialogActions
          })
        }
      }
    }
  ]
})

export const popSetting = (vm) => createSetting({
  search: [
    { name: '弹窗ID', key: 'id' },
    { name: '弹窗名称', key: 'pop_name' }
  ],
  columns: [
    { name: '弹窗ID', key: 'id', widtn: 80 },
    { name: '弹窗名称', key: 'pop_name', minWidth: 120 },
    { name: '弹窗小程序', key: 'use_wxapp', minWidth: 120 },
    { name: '有效期',
      key: 'date',
      minWidth: 150,
      render: (h, { row }) => {
        const start = vm.$options.filters['datetime'].call(vm, new Date(parseInt(row.begin_date)), 'yyyy-MM-dd hh:mm:ss' )
        const end = vm.$options.filters['datetime'].call(vm, new Date(parseInt(row.end_date)), 'yyyy-MM-dd hh:mm:ss' )
        return `${start}~${end}`
      }
    },
    { name: '展示时段',
      key: 'effect_period',
      minWidth: 150,
      formatter: v => `${v[0]}~${v[1]}`
    },
    { name: '优先级',
      key: 'sort',
      minWidth: 120,
      headRender: (h) => {
        const icon = h('i', { class: 'el-icon-info', style: { marginLeft: '4px' } })
        const arr = [
          h('span', '优先级'),
          h('el-tooltip', {
              props: {
                placement:"top",
                content: '排序数值越大，弹窗展示的位置越靠前，数值相同则参考创建时间由近到远排序',
              }
            }, [icon]
          )
        ]
        return h('div', arr)
      },
      render: (h, { row }) => {
        return h('el-input-number', {
          style: {
            width: '120px'
          },
          props: {
            value: row.sort,
            controls: false,
            min: 0,
            precision: 0
          },
          on: {
            change: async (val) => {
              const data = {
                id: row.id,
                sort: val
              }
              await setPopSort(data)
              vm.$message.success('设置优先级成功')
              vm.refresh()
            }
          }
        })
      }
    }
  ],
  actions: [
    {
      name: '编辑',
      type: 'button',
      buttonType: 'text',
      action: {
        handler: (row) => {
          const id = row[0].id
          useDialog(null, {
            title: `编辑弹窗`,
            width: '80%',
            id,
            pageOptions: vm.pageOptions,
            component: () => import('../components/pop-setting.vue'),
            actions: commonDialogActions
          }).then(arg => {
            if(arg) vm.refresh()
          })
        }
      }
    },
    {
      name: '设置弹窗',
      type: 'button',
      buttonType: 'text',
      action: {
        handler: (row) => {
          const id = row[0].id
          const use_page = vm.use_page
          useDialog(null, {
            title: `设置弹窗`,
            width: '80%',
            id,
            use_page,
            component: () => import('../components/pop-wgt/index.vue'),
            actions: commonDialogActions
          })
        }
      }
    },
    {
      name: '删除',
      type: 'button',
      buttonType: 'text',
      action: {
        handler: (row) => {
          MessageBox.confirm('请确认是否删除当前弹窗？', '提示').then(async () => {
            const id = row[0].id
            await deletePop(id)
            vm.$message.success('删除成功')
            vm.refresh()
          })
        }
      }
    }
  ]
})
export const StatusOption = [
  {
    label: '全部',
    value: 'all'
  },
  {
    label: '待开始',
    value: 'pending'
  },
  {
    label: '生效中',
    value: 'ongoing'
  },
  {
    label: '已失效',
    value: 'expired'
  }
]
