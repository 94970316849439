<template>
  <div>
    <div v-if="$route.path.indexOf('_submit') === -1 && $route.path.indexOf('_editor') === -1">
      <div class="content-bottom-padded">
        <el-button type="primary" icon="el-icon-circle-plus" @click="addTpl" v-if="login_type === 'disti'">添加模板</el-button>

        <el-input v-model="fetchParams.tpl_name" placeholder="问卷标题" class="input-m" @change="getTplList" clearable></el-input>
        <el-date-picker
          clearable
          @change="getTplList"
          v-model="create_time"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="timestamp"
          :default-time="['00:00:00', '23:59:59']">
        </el-date-picker>
      </div>

      <el-card>
        <el-table :data="tplList" v-loading="loading">
          <el-table-column prop="id" label="ID" width="80px"></el-table-column>
          <el-table-column prop="tpl_name" label="问卷标题"></el-table-column>
          <el-table-column prop="tpl_desc" label="问卷描述"></el-table-column>
          <el-table-column prop="created" label="创建时间">
            <template slot-scope="scope">
              {{scope.row.created | datetime('yyyy-MM-dd hh:mm:ss')}}
            </template>
          </el-table-column>
          <el-table-column label="适用场景" width="150px" v-if="false">
            <template slot-scope="scope">
              <el-tag type="info" v-if="scope.row.scene == '1'"
                >自提订单</el-tag
              >
              <el-tag v-else-if="scope.row.scene == '2'">快递订单</el-tag>
              <el-tag type="success" v-else>兑换券核销</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="是否启用" v-if="false">
            <template slot-scope="scope">
              <el-tag type="warning" v-if="scope.row.is_open == '0'">禁用</el-tag>
              <el-tag type="success" v-else>启用</el-tag>
            </template>
          </el-table-column>
          <el-table-column width="120" label="操作">
            <template slot-scope="scope">
              <div class="operating-icons">
                <div>
                  <span v-if="login_type == 'disti'"><router-link :to="{path: matchHidePage('editor'), query: { id: scope.row.id }}">编辑</router-link></span>
                  <!-- <el-button type="text" @click="openDialog(scope.row)">预览</el-button> -->
                  <el-button
                    type="text"
                    class="op_btn"
                    @click="delTpl(scope.row.id)">
                    删除
                  </el-button>
                  <span v-if="login_type == 'disti'"><router-link :to="{path: matchHidePage('submit'), query: { id: scope.row.id }}">统计</router-link></span>
                </div>

              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="content-padded content-center">
          <el-pagination
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :total='pagers.total'
            :page-size="pageSize">
          </el-pagination>
        </div>
      </el-card>

    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import store from '@/store'
import { mapGetters } from 'vuex'
import util from '../../../common/js/util'


import {
  getQuestionnairetplList,
  delQuestionnairetpl
} from '@/api/wxa'


export default {
  provide() {
    return {
      refresh: this.getTplList
    }
  },
  data () {
    return {
      loading: false,
      checkedType: {},
      tplList: [],
      operator_type:'',
      pagers:{
        total: 0
      },
      pageSize:10,
      currentPage:1,
      params: {},
      fetchParams: {
        currentPage: 1,
        pageSize: 10,
        tpl_name: '',
        start_time: '',
      },
      create_time: [],
    }
  },
  computed: {
    ...mapGetters([
      'wheight',
      'login_type'
    ])
  },
  methods: {
    handleCurrentChange(val) {
      this.fetchParams.currentPage = val
      this.getTplList()
    },
    handleSizeChange (val) {
      this.fetchParams.pageSize = val
      this.getTplList()
    },
    addTpl () {
      this.$router.push({path: this.matchHidePage('editor')})
    },
    chooseType (item, index) {
      this.i = index
      this.checkedType = item
    },
    delTpl (id) {
      this.$confirm('确定要删除该问卷模板？', '提示', {
        cancelButtonText: '取消',
        confirmButtonText: '确定',
        type: 'warning',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            delQuestionnairetpl(id).then( res => {
              this.$message({ type: 'success', message: '操作成功！' })
              this.getTplList()
            })
          }
        done()
        }
      })
    },
    getTplList () {
      this.loading = true
      let params = {
        page: this.fetchParams.currentPage,
        page_size: this.fetchParams.pageSize,
        tpl_name: this.fetchParams.tpl_name,
        create_time: this.create_time,
      }
      getQuestionnairetplList(params).then(res => {
        if(res.data.data.list.length > 0) {
          this.tplList = res.data.data.list
          this.pagers.total = res.data.data.total_count
          this.loading = false
        } else {
          this.tplList = []
          this.loading = false
        }
      })
      .catch( error => {
        this.loading = false
      })
    },
  },
  mounted () {
    this.fetchParams.store_self = false
    if (this.system_mode === 'platform' && store.getters.login_type !== 'distributor') {
      this.fetchParams.store_self = true
    }
    this.operator_type = store.getters.login_type;
    this.getTplList()
  }
}

</script>
<style scoped lang="scss">
  .el-icon-edit {
    cursor: pointer;
  }
  .coupon-list {
    margin-top: 10px;
  }
  .coupon-item {
    position: relative;
    height: 130px;
    padding: 25px 20px;
    overflow: hidden;
    background: #fff;
    border: 1px solid #dadadd;
    border-radius: 5px;
    text-align: center;
    font-size: 14px;
    width: 19%;
    float: left;
    margin: .5%;
    &.coupon-add {
      padding-top: 20px;
      cursor: pointer;
      i {
        font-size: 40px;
      }
      :last-child { margin-top: 10px; }
    }
    .price {
      font-size: 24px;
    }
    .color-gray {
      color: #99A9BF;
    }
    .color-gray-deep {
      color: #8492A6;
    }
    .item-code {
      display: inline-block;
      width: 90%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    span.icon-triangle {
      position: absolute;
      top: 0;
      right: 0;
      width: 0;
      height: 0;
      border-top: 34px solid #20a0ff;
      border-left: 34px solid transparent;
      color: #fff;
    }
    label {
      position: absolute;
      top: -34px;
      right: 0;
      width: 20px;
      height: 20px;
      line-height: 20px;
    }
  }
  .item-bg {
    position: absolute;
    left: 0;
    bottom: -101%;
    width: 100%;
    height: 100%;
    background: rgba(241, 244, 246, .9);
    text-align: left;
    border-radius: 5px;
    padding-top: 8px;
    transition: all .4s ease;
    p {
      padding: 0 10px;
    }
    .item-operate {
      position: absolute;
      left: 0;
      bottom: 0;
      background: rgba(0, 0, 0, .4);
      width: 100%;
      padding: 4px 0;
      text-align: center;
      cursor: pointer;
      a { color: #fff; }
    }
  }
  .coupon-item:hover {
    .item-bg {
      bottom: 0;
    }
  }
  .coupon-type-item {
    position: relative;
    padding: 30px 10px;
    border: 1px solid #ff5000;
    color: #ff5000;
    border-radius: 5px;
    text-align: center;
    margin-bottom: 20px;
    background: #fff;
    overflow: hidden;
    cursor: pointer;
    &:first-child {
      font-size: 18px;
    }
    .coupon-type-desc {
      position: absolute;
      width: 100%;
      height: 100%;
      bottom: -100%;
      left: 0;
      padding: 10px 0;
      background: #ff5000;
      color: #fff;
      font-size: 18px;
      transition: all .4s ease;
      div:last-child {
        width: 70%;
        margin: 8px auto 0;
        font-size: 12px;
      }
    }
    &:hover {
      .coupon-type-desc {
        bottom: 0;
      }
    }
  }

  .coupon-type-item, .sendout-item {
    overflow: hidden;
    .icon-checked {
      display: none;
      width: 100px;
      height: 25px;
      transform: rotate(35deg);
      position: absolute;
      top: 5px;
      right: -25px;
      font-size: 20px;
      color: #fff;
      background: #ff5000;
      i {
        transform: rotate(-35deg);
      }
    }
    &.checked {
      .icon-checked {
        display: block;
      }
    }

    &:hover .icon-checked {
      background: #fff;
      i {
        color: #ff5000;
      }
    }
  }
  .store-pop {
    padding: 20px 15px;
    .store-content {
      margin-bottom: 15px;
    }
  }
  .subtitle{
    color: #8c939d;
    margin-top: 3px;
    font-size: 10px;
  }
</style>
