<template lang="html">
  <div>
    <el-select v-model="examineStatus" placeholder="请选择" @change="selectChange">
      <el-option
        v-for="item in examineStatusSelect"
        :key="item.value"
        :label="item.label"
        :value="item.value">
      </el-option>
    </el-select>
    <el-table
      v-loading="tableLoading"
      :data="logData"
      style="width: 100%">
      <el-table-column
        prop="id"
        label="ID"
        width="100">
      </el-table-column>
      <el-table-column
        prop="template_id"
        label="模版ID"
        width="100">
      </el-table-column>
      <el-table-column
        prop="template_type"
        label="模版类型">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.template_type == 'index'">首页</el-tag>
          <el-tag v-else-if="scope.row.template_type == 'custompage'">自定义页</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="template_title"
        label="模版名称">
      </el-table-column>
      <el-table-column
        prop="examine_result"
        width="120"
        label="审核状态">
        <template slot-scope="scope">
          <el-tag effect="dark" v-if="scope.row.examine_result == 2">待审核</el-tag>
          <el-tag effect="dark" type="warning" v-if="scope.row.examine_result == 3">审核未通过</el-tag>
          <el-tag effect="dark" type="success" v-if="scope.row.examine_result == 4">审核已通过</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="examine_remark"
        label="审核备注">
      </el-table-column>
      <el-table-column
        prop="created_at"
        label="提交时间"
        width="170">
      </el-table-column>
      <el-table-column
        prop="examine_time"
        label="审核时间"
        width="170">
      </el-table-column>
      <el-table-column label="操作" width="230">
        <template slot-scope="scope">
          <div v-if="scope.row.examine_result == 2">
            <el-button  size="small" type="primary">预览</el-button>
            <el-button  size="small" type="success" @click="handleExamine(scope.row)">审核</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <div style="text-align: center; margin: 15px 0">
      <el-pagination
        background
        :page-size="pageParams.pageSize"
        layout="total, prev, pager, next"
        @current-change="handleCurrentChange"
        :total="total_count">
      </el-pagination>
    </div>

    <el-dialog
      title="审核模版"
      :visible.sync="examineVisible"
      :before-close="beforeCloseDialog"
      width="30%">
      <el-form ref="form" :model="examineForm" label-width="100px">
        <el-form-item label="审核结果">
          <div>
            <el-radio v-model="examineForm.examine_result" label="4" value="4" size="mini">审核通过</el-radio>
            <el-radio v-model="examineForm.examine_result" label="3" value="3" size="mini">审核不通过</el-radio>
          </div>
        </el-form-item>
        <el-form-item label="备注">
          <el-input
            heigh="200"
            type="textarea"
            placeholder="请输入内容"
            v-model="examineForm.remark"
            maxlength="200"
            show-word-limit
            :autosize="{ minRows: 4, maxRows: 10}">
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCloseDialog">取 消</el-button>
        <el-button type="primary" @click="examine" :loading="btnLoading">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getExamineList, examineTemplate } from '@/api/template'
export default {
  data() {
    return {
      logData: [],
      total_count: 0,
      pageParams: {
        page: 1,
        pageSize: 20
      },
      tableLoading: false,
      examineVisible: false,
      examineStatus: 0,
      examineStatusSelect: [{
        value: 0,
        label: '全部'
      }, {
        value: 1,
        label: '未审核'
      }, {
        value: 2,
        label: '已审核'
      }, {
        value: 3,
        label: '已通过'
      }, {
        value: 4,
        label: '未通过'
      }],
      examineForm: {
        examine_result: 2,
        remark: '',
        log_id: 0,
        template_id: 0,
      },
      btnLoading: false
    }
  },
  methods: {
    getList() {
      this.tableLoading = true;
      let params = {
        page: this.pageParams.page,
        pageSize: this.pageParams.pageSize,
        examineStatus: this.examineStatus,
      }
      getExamineList(params).then(response => {
        this.logData = response.data.data.list
        this.total_count = response.data.data.total_count
        this.tableLoading = false;
      }).catch(error => {
        this.logData = [];
        this.total_count = 0;
        this.tableLoading = false;
      })
    },

    selectChange() {
      this.pageParams.page = 1;
      this.getList();
    },

    handleCurrentChange (page_num) {
      this.pageParams.page = page_num
      this.getList()
    },

    handleExamine(row) {
      this.examineVisible = true
      this.examineForm.log_id = row.id
      this.examineForm.template_id = row.template_id
    },

    examine() {
      this.btnLoading = true
      if (this.examineForm.examine_result == 2) {
        this.$message.error('请选择审核结果');
        this.btnLoading = false
        return false;
      }
      examineTemplate(this.examineForm).then(response => {
        if (response.data.data.success) {
          this.$message.success('审核成功!');
          this.btnLoading = false
        } else {
          this.$message.error('审核失败!');
          this.btnLoading = false
        }
        this.handleCloseDialog()
        this.getList();
      }).catch(error => {
        this.$message.error('审核失败');
        this.handleCloseDialog()
        this.getList();
        this.btnLoading = false
      })
    },

    handleCloseExamineDialog() {
      this.examineVisible = false
      this.resetExamineForm()
    },

    beforeCloseDialog() {
      this.resetExamineForm();
      this.examineVisible = false
    },

    resetExamineForm() {
      this.examineForm = {
        examine_result: 2,
          remark: '',
          log_id: 0,
          template_id: 0,
      }
    }
  },
  mounted () {
    this.getList()
  }
}
</script>

<style scoped lang="scss">
</style>
