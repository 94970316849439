<template>
  <div class="">
    <div v-if="($route.path.indexOf('_detail') === -1 && $route.path.indexOf('_process') === -1)">

      <el-row class="filter-header" :gutter="20">
        <el-col>
          <shop-select v-if="0 && login_type !== 'distributor'" distributors @update="storeSearch"
                       style="width: 280px"></shop-select>
          <el-date-picker
            v-model="create_time"
            type="datetimerange"
            :picker-options="pickerOptions"
            start-placeholder="开始日期时间"
            ange-separator="至"
            end-placeholder="结束日期时间"
            value-format="timestamp"
            align="left"
            :default-time="['00:00:00', '23:59:59']"
            @change="dateChange"
            placeholder="选择日期时间范围">
          </el-date-picker>
          <el-popover placement="top-start" width="200" trigger="hover" content="点击之后会显示“默认显示一个月内订单，如需查看历史订单，请选择日期范围”">
            <i slot="reference" class="el-icon-question" />
          </el-popover>
          <el-input class="input-m" placeholder="请输入订单号" v-model="identifier" clearable @clear="numberSearch"
                    @change="numberSearch()"></el-input>

          <el-input class="input-m" v-if="login_type == 'distributor'" placeholder="请输入流水号" v-model="serial_number" clearable @clear="numberSearch" @change="numberSearch()">
          </el-input>
        </el-col>
      </el-row>
      <el-button @click="addDistributorAction" v-if="login_type === 'disti' || login_type === 'dealer'">筛选门店</el-button>
      <el-tag type="success" closable @close="clearShop()" v-if="shop_num >0">已选择{{shop_num}}家门店</el-tag>
      <el-tag v-if="open_self_handle" type="danger" style="font-size: 14px;margin-bottom: 15px;color: #333">
        <span style="color: #F56C6C" >已开启</span>
        订单自闭环,列表将展示
        <span style="color: #F56C6C" >可处理的订单</span>
        <span class="frm-tips"  v-if="open_status === '2' && login_type === 'disti'">【部分门店开启】</span>
      </el-tag>

<!--      <el-tag v-else type="warning" style="font-size: 14px;margin-bottom: 15px;color: #333">-->
<!--        <span style="color: #E6A23C" >已关闭</span>-->
<!--        订单自闭环,列表将展示-->
<!--        <span style="color: #E6A23C" >历史处理过的订单</span>-->

<!--      </el-tag>-->
      <el-button @click="numberSearch()" style="float: right;" icon="el-icon-refresh-right"  plain type="success" round>刷新</el-button>
      <!-- TODO 订单列表 -->
      <el-tabs type="border-card" v-model="activeName" @tab-click="handleClick" :stretch="true" v-loading="loadingJd"
               element-loading-text="处理中，请稍后。。。">
        <template v-for="(item, index) in tabPane">
          <el-tab-pane :lazy="true" :label="item.label" :key="index" :name="item.name"></el-tab-pane>
        </template>
        <el-table :data="list" stripe :height="wheight - 280" v-loading="loading" element-loading-text="数据加载中">

          <el-table-column label="订单信息" min-width="200" show-overflow-tooltip>
            <template slot-scope="scope">
              <div class="order-num">
                {{ scope.row.order_id }}
                <el-tooltip effect="dark" content="复制" placement="top-start">
                  <i v-clipboard:copy="scope.row.order_id" v-clipboard:success="onCopy" class="el-icon-document-copy"></i>
                </el-tooltip>
              </div>

              <div>
                实付金额：<span class="cur">{{ scope.row.fee_symbol }}</span>{{ scope.row.total_fee / 100 }}
              </div>
              <div class="order-time">
                <el-tooltip effect="dark" content="下单时间" placement="top-start"><i class="el-icon-time"></i></el-tooltip>
                {{ scope.row.create_time | datetime('yyyy-MM-dd hh:mm:ss') }}
              </div>
            </template>
          </el-table-column>

          <el-table-column label="综合信息" min-width="100" align="center" show-overflow-tooltip>
            <template slot-scope="scope">
              <div>{{ scope.row.distributor_name }}</div>
              <span v-if="scope.row.receipt_type === 'ziti'">到店自取</span>
              <span v-else>快递</span>
              <div><el-tag size="mini" v-if="checkPreOrderQh(scope.row)">期货预售</el-tag></div>
              <div><el-tag size="mini" v-if="scope.row.type == '0'">普通订单</el-tag></div>
              <div><el-tag type="warning" size="mini" v-if="checkPreOrder(scope.row)">预售订单</el-tag></div>
            </template>
          </el-table-column>

          <el-table-column label="订单状态" min-width="80">
            <template slot-scope="scope">
              <el-tag type="warning" size="mini" v-if="scope.row.order_status === 'NOTPAY'">待付款</el-tag>

              <el-tag type="" size="mini"
                      v-else-if="scope.row.order_status === 'PAYED' && scope.row.pay_type !== 'appointment'">

                <span v-if="scope.row.receive_status !== '0'">{{ scope.row.order_status_msg }}</span>
                <span v-else-if="scope.row.receive_status === '0'">待接单</span>
                <span v-else>已付款</span>

              </el-tag>

              <el-tag size="mini" v-else-if="scope.row.order_status === 'WAIT_BUYER_CONFIRM'
                && scope.row.cancel_status === 'NO_APPLY_CANCEL'">{{ scope.row.order_status_msg }}
              </el-tag>

              <el-tag type="danger" size="mini" v-else-if="scope.row.order_status === 'CANCEL'">
                <span>{{ scope.row.order_status_msg }} </span>
              </el-tag>

              <el-tag size="mini" v-else>{{ scope.row.order_status_msg }}</el-tag>
              <el-tag type="warning" size="mini"
                      v-if="scope.row.delivery_corp_source == 'SF_API_ERROR' || scope.row.delivery_corp_source == 'SF_API_CHEXIAO'">快递异常</el-tag>

              <el-tag type="danger" size="mini" v-if="scope.row.order_lock">订单锁定</el-tag>

            </template>
          </el-table-column>

          <el-table-column label="查看" min-width="150" show-overflow-tooltip>
            <template slot-scope="scope">
              <el-link :underline="false" style="vertical-align: top;" type="primary" @click="showDetail(scope.row)">详情</el-link>
              <el-divider direction="vertical"></el-divider>
              <el-link :underline="false" style="vertical-align: top;" type="primary" @click="setLogPage(scope.row.order_id)">日志</el-link>
              <br>
              <el-popover
                placement="bottom" v-if="scope.row.remark"
                title="备注信息"
                width="200"
                trigger="click"
                :content="scope.row.remark">
                <el-link :underline="false" slot="reference" style="vertical-align: top;" type="success">备注</el-link>
              </el-popover>
              <el-divider direction="vertical"  v-if="scope.row.serial_number && scope.row.remark"></el-divider>
              <el-link :underline="false"  type="info" v-if="scope.row.serial_number" slot="reference" style="vertical-align: top;">序号:{{scope.row.serial_number}}</el-link>
<!--              <el-link :underline="false" slot="reference" style="vertical-align: top;" type="success">{scope.row.serial_number}</el-link>-->

            </template>
          </el-table-column>

          <el-table-column label="操作" min-width="250"  v-if="login_type === 'distributor'">
            <template slot-scope="scope">
              <div>
                <div class="operate-btns" >
                  <el-button size="mini" v-if="isShowReceive(scope.row)"  @click="receivedOrder(scope.row.order_id)" plain type="primary">接单</el-button>
                  <el-button size="mini" v-if="isShowCall(scope.row) && scope.row.urgentDeliveryType === 'sftc' && scope.row.bsp_only === '0'" @click="deliveryAction(scope.row, 'delivery_tc', 'call')" class="sf-call">呼叫顺丰</el-button>
                  <el-button size="mini" v-if="isShowCall(scope.row) && scope.row.urgentDeliveryType === 'mtps' && scope.row.oms_store === '3' && scope.row.bsp_only === '0' && !scope.row.showjustCallSf" @click="deliveryAction(scope.row, 'delivery_tc', 'call')" class="sf-call">呼叫顺丰</el-button>
                  <el-button size="mini" v-if="isShowCall(scope.row) && scope.row.urgentDeliveryType === 'mtps' && scope.row.bsp_only === '0'" @click="deliveryAction(scope.row, 'delivery_mt', 'call')" class="mt-call">呼叫美团</el-button>
                  <el-button size="mini" v-if="isShowCall(scope.row)  && (!scope.row.bsp_only || scope.row.bsp_only === '1' || scope.row.showjustCallSf === true)" @click="deliveryAction(scope.row, 'delivery','call')" class="sf-call">呼叫顺丰</el-button>
                  <el-button size="mini" v-if="isShowCallBeihuo(scope.row)" @click="beihuoAction(scope.row)" plain type="primary">再次备货</el-button>

                  <el-button size="mini" v-if="isShowCancel(scope.row) && scope.row.delivery_corp_order === 'MTPS'" @click="deliveryAction(scope.row,'delivery_mt','back')" class="mt-cancel">
                    <span v-if="scope.row.oms_store === '0'">撤销美团</span>
                    <span v-else-if="scope.row.oms_store !== '0' && scope.row.back && scope.row.oms_no_exchange ===''" size="mini" type="success">撤销美团</span>
                    <span v-else-if="scope.row.oms_store !== '0' && scope.row.back && scope.row.oms_no_exchange  !=='' && scope.row.oms_store !== '3'" size="mini" type="success">拦截美团</span>
                    <span v-else-if="scope.row.oms_store !== '0' && scope.row.back && scope.row.oms_no_exchange  !=='' && scope.row.oms_store === '3'" size="mini" type="success">撤销美团</span>
                    <span v-else-if="scope.row.oms_store !== '0' && scope.row.back && scope.row.oms_no_exchange  !==''" size="mini" type="success">拦截美团</span>
                    <span v-else>拦截美团</span>
                  </el-button>
                  <el-button size="mini" v-if="isShowExchange(scope.row) &&  scope.row.showExchangeSf === true" @click="exchangeSfAction(scope.row, 'exchange','call')" class="sf-call">呼叫顺丰</el-button>
                  <el-button size="mini" v-if="isShowCancel(scope.row)  && scope.row.delivery_corp_order !== 'MTPS'" @click="deliveryAction(scope.row,'delivery','back')" class="sf-cancel">
                    <span v-if="scope.row.oms_store === '0'" size="mini" type="danger">撤销顺丰</span>
                    <span v-else-if="scope.row.oms_store !== '0' && scope.row.back && scope.row.oms_no_exchange ===''" size="mini" type="success">撤销顺丰</span>
                    <span v-else-if="scope.row.oms_store !== '0' && scope.row.back && scope.row.oms_no_exchange  !=='' && scope.row.oms_store !== '3'" size="mini" type="success">拦截顺丰</span>
                    <span v-else-if="scope.row.oms_store !== '0' && scope.row.back && scope.row.oms_no_exchange  !=='' && scope.row.oms_store === '3'" size="mini" type="success">撤销顺丰</span>
                    <span v-else size="mini" type="warning">拦截顺丰</span>
                  </el-button>
                  <el-button size="mini" v-if="isShowCallBack(scope.row)  && scope.row.delivery_corp_order !== 'MTPS'" @click="deliveryAction(scope.row,'delivery','back')" class="sf-cancel">拦截顺丰</el-button>
                  <el-button size="mini" v-if="isShowCallBack(scope.row)  && scope.row.delivery_corp_order === 'MTPS'" @click="deliveryAction(scope.row,'delivery_mt','back')" class="mt-cancel">拦截美团</el-button>


                  <el-button size="mini" v-if="isShowClose(scope.row)"
                             @click="cancelOrderAction(scope.row.order_id, scope.row)" title="强制取消并退款" plain
                             type="danger">关闭订单</el-button>
                  <el-button size="mini" v-if="isShowAudit(scope.row)"
                             @click="confirmCancelOrderAction(scope.row.order_id, scope.row)" plain type="primary">审核</el-button>

                  <div class="red small" v-if="scope.row.mtErrorMsg !== '' && showErrMsg(scope.row)">
                    {{ scope.row.mtErrorMsg }}</div>
                </div>
                <div class="muted" v-if="isShowDisabled(scope.row)">无需操作</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="流水号" v-if="login_type == 'distributor'" min-width="100" show-overflow-tooltip>
            <template slot-scope="scope">
              <span v-if="scope.row.serial_number">
                <el-tag type="warning" effect="plain" :hit="true" size="medium">
                  &nbsp;&nbsp;{{ scope.row.serial_number }}&nbsp;&nbsp;
                </el-tag>
                <br>
                <el-link type="warning" disabled style="font-size: 12px">
                  {{ scope.row.create_time | datetime('yyyy-MM-dd') }}
                </el-link>
              </span>
            </template>
          </el-table-column>
          <el-table-column label="Pos操作" min-width="270" align="center" show-overflow-tooltip  v-if="login_type === 'distributor'">
            <template slot-scope="scope">
              <el-button type="primary" size="small" round v-if="scope.row.posButton.stock_up && !isShowCallBeihuo(scope.row)"
                         @click="posAction(scope.row.order_id,'stock_up')">备货
              </el-button>
              <el-button type="success" size="small" round v-if="scope.row.posButton.outbound"
                         @click="posAction(scope.row.order_id,'outbound') ">出库
              </el-button>
              <el-button type="danger" size="small" round v-if="scope.row.posButton.return"
                         @click="posAction(scope.row.order_id,'return')">退回
              </el-button>
              <el-button type="warning" size="small" round v-if="scope.row.posButton.write_off"
                         @click="posAction(scope.row.order_id,'write_off')">核销
              </el-button>

            </template>
          </el-table-column>
        </el-table>

        <div class="content-padded content-center">
          <el-pagination background layout="total, sizes, prev, pager, next, jumper" @current-change="handleCurrentChange"
                         @size-change="handleSizeChange" :current-page.sync="params.page" :page-sizes="[10, 20, 50]"
                         :total="total_count" :page-size="params.pageSize">
          </el-pagination>
        </div>
      </el-tabs>



      <!-- 接单-dialog-->
      <el-dialog title="接单确认" width="57%" :visible.sync="receiveView" :before-close="handleColse">
        <template>
          <el-form class="demo-ruleForm" label-width="100px">
            <el-form-item label="订单号">
              <el-col :span="20">{{ orderInfo.order_id }}</el-col>
            </el-form-item>

            <el-form-item label="商品信息">
              <el-table :data="orderInfo.items" stripe border>
                <el-table-column prop="pic" label="图片" width="100px" align="center" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <el-image :src="scope.row.pic" fit="fill" style="width: 50px; height: 50px"></el-image>
                  </template>
                </el-table-column>
                <el-table-column align="center" show-overflow-tooltip prop="item_name" label="商品名"
                                 min-width="200px"></el-table-column>
                <el-table-column align="center" show-overflow-tooltip prop="num" label="数量"
                                 width="100px"></el-table-column>
                <el-table-column label="总支付价(元)" width="100px" align="center" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <span> {{ scope.row.fee_symbol }} {{ scope.row.total_fee / 100 }} </span>
                  </template>
                </el-table-column>
              </el-table>
            </el-form-item>

          </el-form>
        </template>
        <div slot="footer" class="dialog-footer content-center">
          <el-button type="success" plain @click="receivedOrder(orderInfo.order_id)"
                     :loading="buttonLoading">确定</el-button>
        </div>
      </el-dialog>

      <!-- 发货-dialog -->
      <el-dialog :title="deliveryTitle" :visible.sync="deliveryVisibleNew" :before-close="handleCancel" width="57%">
        <template>
          <el-form ref="deliveryForm" :model="deliveryForm" class="demo-ruleForm" label-width="100px">
            <el-form-item label="订单号">
              <el-col :span="20">{{ deliveryData.orderInfo.order_id }}</el-col>
            </el-form-item>
            <el-form-item label="发货类型" v-show="false">
              <el-radio-group :disabled="IsDisabled" v-model="deliveryForm.delivery_type">
                <el-radio label="batch">整单发货</el-radio>
                <el-radio label="sep">拆分发货</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="商品信息">
              <el-table :data="deliveryData.orderInfo.items" stripe border>
                <el-table-column prop="pic" label="图片" width="100" align="center" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <el-image :src="scope.row.pic" fit="fill" style="width: 50px; height: 50px"></el-image>
                  </template>
                </el-table-column>
                <el-table-column align="center" show-overflow-tooltip prop="item_name" label="商品名"
                                 min-width="200"></el-table-column>
                <el-table-column align="center" show-overflow-tooltip prop="num" label="数量" width="100"></el-table-column>
                <el-table-column v-if="false" prop="delivery_item_num" label="已发货数量" width="180"></el-table-column>
                <el-table-column label="总支付价(元)" width="100" align="center" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <span>{{ scope.row.fee_symbol }}{{ scope.row.total_fee / 100 }}</span>
                  </template>
                </el-table-column>
                <template v-if="deliveryForm.delivery_type == 'sep'">
                  <el-table-column label="发货数量" width="200">
                    <template slot-scope="scope">
                      <el-input-number v-model="scope.row.delivery_num" placeholder="发货数量" controls-position="right"
                                       :min="0" :max="scope.row.num - scope.row.delivery_item_num"
                                       v-if="scope.row.num - scope.row.delivery_item_num != 0"></el-input-number>
                      <!-- <el-input v-model="scope.row.delivery_num" :maxlength=20 placeholder="发货数量"></el-input> -->
                      <span v-if="scope.row.num - scope.row.delivery_item_num == 0">已发完</span>
                    </template>
                  </el-table-column>
                </template>
              </el-table>
            </el-form-item>

            <el-form-item label="快递公司" v-show="false">
              <el-col>
                <el-select v-model="deliveryForm.delivery_corp" filterable width="200px" placeholder="请选择快递公司，可搜索"
                           style="width: 300px">
                  <el-option v-for="item in dlycorps" :key="item.value" :label="item.name" :value="item.value">
                  </el-option>
                </el-select>
              </el-col>
            </el-form-item>
            <el-form-item label="物流单号" v-show="false">
              <el-col :span="16">
                <el-input v-model="deliveryForm.delivery_code" :maxlength=20 placeholder="物流公司单号"></el-input>
              </el-col>
            </el-form-item>
            <el-form-item v-if="deliveryData.orderInfo.delivery_status === 'DONE'" v-show="false" label=" * ">
              <template v-if="deliveryData.orderInfo.delivery_status === 'DONE'">
                <el-col :span="20">此订单已经发货，重新填写，可以修改物流单号！</el-col>
              </template>
            </el-form-item>

          </el-form>
        </template>
        <div slot="footer" class="dialog-footer content-center">
          <el-button @click.native="handleCancel">取消</el-button>
          <el-button type="primary" @click="submitDeliveryAction" :loading="buttonLoading">确定</el-button>
        </div>
      </el-dialog>

      <!-- 用户取消订单退款审核-开始 -->
      <el-dialog title="已接单，用户取消订单审核" :visible.sync="cancelVisible" :before-close="handleCancelOrderCancel" width="57%">
        <template>
          <el-row :gutter="100">
            <el-col :span="24">
              <span class="grid-content">订单号:{{ cancelData.order_id }}</span>
            </el-col>
            <el-col :span="24">
              <span v-if="cancelData.cancel_from == 'buyer'">取消来源:消费者申请</span>
              <span v-else>取消来源:系统自动取消</span>
            </el-col>
            <el-col :span="24">
              <span>申请时间:{{ cancelData.create_time | datetime('yyyy-MM-dd hh:mm:ss') }}</span>
            </el-col>
            <el-col :span="24" style="display: none">
              <span>退款状态:</span>
              <span v-if="cancelData.refund_status == 'READY'">待审核</span>
              <span v-if="cancelData.refund_status == 'AUDIT_SUCCESS'">审核成功待退款</span>
              <span v-if="cancelData.refund_status == 'SUCCESS'">退款成功</span>
              <span v-if="cancelData.refund_status == 'SHOP_CHECK_FAILS'">商家审核不通过</span>
              <span v-if="cancelData.refund_status == 'CANCEL'">撤销退款</span>
              <span v-if="cancelData.refund_status == 'PROCESSING'">已发起退款等待到账</span>
              <span v-if="cancelData.refund_status == 'FAILS'">退款失败</span>
            </el-col>
            <el-col :span="24">
              <span>处理进度:</span>
              <span v-if="cancelData.progress == '0'">待处理</span>
              <span v-if="cancelData.progress == '1'">已取消</span>
              <span v-if="cancelData.progress == '2'">退款中</span>
              <span v-if="cancelData.progress == '3'">已完成</span>
              <span v-if="cancelData.progress == '4'">已驳回</span>
            </el-col>
            <el-col :span="24">
              <span>退款金额:{{ cancelData.fee_symbol }}{{ cancelData.total_fee / 100 }}</span>
            </el-col>
            <el-col :span="24">
              <span v-if="cancelData.pay_type == 'wxpay'">支付方式:微信支付</span>
            </el-col>
            <el-col :span="24" v-if="0">
              <span>取消原因:{{ cancelData.cancel_reason }}</span>
            </el-col>
          </el-row>
          <el-divider></el-divider>

          <el-form ref="cancelForm" :model="cancelForm" class="" label-width="100px">

            <el-form-item label="商品信息">
              <el-table :data="orderInfo.items" stripe border>
                <el-table-column prop="pic" label="图片" width="100" align="center" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <el-image :src="scope.row.pic" fit="fill" style="width: 50px; height: 50px"></el-image>
                  </template>
                </el-table-column>
                <el-table-column align="center" show-overflow-tooltip prop="item_name" label="商品名"
                                 min-width="200"></el-table-column>
                <el-table-column align="center" show-overflow-tooltip prop="num" label="数量" width="100"></el-table-column>
                <el-table-column label="总支付价(元)" width="100" align="center" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <span> {{ scope.row.fee_symbol }} {{ scope.row.total_fee / 100 }} </span>
                  </template>
                </el-table-column>
              </el-table>
            </el-form-item>

            <el-form-item label="处理结果:">
              <el-row>
                <el-col :span="20">
                  <template>
                    <el-radio v-model="cancelForm.check_cancel" label="0">拒绝</el-radio>
                    <el-radio v-model="cancelForm.check_cancel" label="1">同意</el-radio>
                  </template>
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item label="拒绝原因:" v-if="cancelForm.check_cancel == '0'">
              <el-row>
                <el-col :span="24">
                  <el-input type="textarea" v-model="cancelForm.shop_reject_reason" :rows="3"
                            placeholder="请输入拒绝原因"></el-input>
                </el-col>
              </el-row>
            </el-form-item>
          </el-form>
        </template>
        <div slot="footer" class="dialog-footer">
          <el-button @click.native="handleCancelOrderCancel">取消</el-button>
          <el-button type="primary" @click="submitCancelConfirmAction" :loading="buttonLoading">确定</el-button>
        </div>
      </el-dialog>

      <!-- 取消订单 -->
      <el-dialog title="取消订单" :visible.sync="cancelOrderVisible" :before-close="handleCancelOrder" width="57%">
        <template>
          <el-form ref="cancelForm" :model="cancelOrderForm" class="" label-width="100px">
            <el-form-item label="订单号:">
              <el-col :span="20">{{ cancelOrderData.orderInfo.order_id }}</el-col>
            </el-form-item>

            <el-form-item label="商品信息">
              <el-table :data="orderInfo.items" stripe border>
                <el-table-column prop="pic" label="图片" width="100" align="center" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <el-image :src="scope.row.pic" fit="fill" style="width: 50px; height: 50px"></el-image>
                  </template>
                </el-table-column>
                <el-table-column align="center" show-overflow-tooltip prop="item_name" label="商品名"
                                 min-width="200"></el-table-column>
                <el-table-column align="center" show-overflow-tooltip prop="num" label="数量" width="100"></el-table-column>
                <el-table-column label="总支付价(元)" width="100" align="center" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <span> {{ scope.row.fee_symbol }} {{ scope.row.total_fee / 100 }} </span>
                  </template>
                </el-table-column>
              </el-table>
            </el-form-item>

            <el-form-item label="关闭原因:">
              <el-row>
                <el-col :span="20">
                  <template>
                    <el-select v-model="cancel_order" filterable placeholder="请选择关闭订单原因" @change="cancelReasonSelect"
                               style="width: 300px">
                      <el-option v-for="item in order_cancel_reason" :key="item.value" :label="item.name"
                                 :value="item.value">
                      </el-option>
                    </el-select>
                    <el-input v-show="cancelOrderInput" v-model="cancelOrderForm.other_reason" type="textarea" :rows="3"
                              resize="none" style="width: 200px; margin-top: 10px" placeholder="请填写关闭订单原因"></el-input>
                  </template>
                </el-col>
              </el-row>
            </el-form-item>
          </el-form>
        </template>
        <div slot="footer" class="dialog-footer">
          <el-button @click.native="handleCancelOrder">取消</el-button>
          <el-button type="primary" @click="submitCancelOrderConfirmAction" :loading="buttonLoading">确定</el-button>
        </div>
      </el-dialog>

      <el-drawer title="订单日志" :visible.sync="drawer_log" :direction="direction" :withHeader="false" :size="800">
        <span>
          <el-timeline :reverse="false" style="margin: 20px 0 0 20px">
            <el-timeline-item v-for="(key, index) in logList" :key="index"
                              :timestamp="key.create_time | datetime('yyyy-MM-dd hh:mm:ss')" placement="top">
              <el-card>
                <h2>{{ key.remarks }}</h2>
                <p>操作人员：{{ key.operator_name }}</p>
                <p>人员类型：
                  <span v-if="'user' == key.operator_type">
                    用户
                  </span>
                  <span v-else-if="'salesperson' == key.operator_type">
                    导购员
                  </span>
                  <span v-else-if="'admin' == key.operator_type">
                    管理员
                  </span>
                  <span v-else-if="'system' == key.operator_type">
                    系统
                  </span>
                  <span v-else-if="'system_job' == key.operator_type">
                    系统自动
                  </span>
                  <span v-else>
                    未知
                  </span>
                </p>
                <p>操作详情：{{ key.detail }}</p>
              </el-card>
            </el-timeline-item>
          </el-timeline>
        </span>
      </el-drawer>

      <!-- 以下为pos操作 -->
      <el-dialog :title="'模拟'+posTitle" :close-on-click-modal="false" :visible.sync="dialogVisiblePos" :before-close="handleCancelPos" width="40%" >
        <template >
          <el-form ref="deliveryFormPos" :model="deliveryFormPos" v-loading="posloading" :element-loading-text="posLoadingMsg" class="demo-ruleForm" label-width="100px">

          <el-form-item label="订单号：" >
            <span>{{deliveryFormPos.order_id}}</span>
          </el-form-item>
          <el-form-item label="订单商品：" prop="new_pwd">
            <div v-for="item in deliveryDataPos.orderInfo.items">
              <div style="line-height: 20px;margin-top:10px">{{item.item_name}}</div>
              <span v-if="lastOp !== ''" class="frm-tips">{{lastOp}}SN：</span>
              <span v-if="lastSn[item.item_id]" class="frm-tips">
                {{lastSn[item.item_id].sn}}
                <el-button type="success" style="margin-left: 10px" round plain @click="cpLatsSn(lastSn[item.item_id].sn,item.item_id)"  size="mini">使用</el-button>
              <br>
              </span>
              <span style="color:red;font-size: 14px">*&nbsp;</span>
              <el-input v-model="posform.snList[item.item_id].sn"   style="border-color: #a4134d;"   size="small" class="input-b" clearable  @clear="checkSn(item.item_id)"  @blur="checkSn(item.item_id)" placeholder="请输入商品SN"></el-input>
              <span class="frm-tips" style="color: #F56C6C;" >{{snErr[item.item_id].error}}</span>
              <i class="el-icon-loading"  v-if="snErr[item.item_id].load"></i>
              <i class="el-icon-circle-check" style="color: #1aad19" v-if="snErr[item.item_id].right"></i>

              <el-divider></el-divider>
            </div>
          </el-form-item>
          <el-form-item label="操作备注：" >
            <el-input v-model="posform.remark" class="input-b" size="small" placeholder="请输入操作备注"></el-input>
          </el-form-item>
          </el-form>
        </template>
        <div slot="footer" class="dialog-footer">
          <el-button @click.native="handleCancelPos">取消</el-button>
          <el-button type="primary" @click="submitPosAction" :loading="buttonLoading">确认{{posTitle}}</el-button>
        </div>
      </el-dialog>

      <el-dialog :title="posTitle" :close-on-click-modal="false" :visible.sync="dialogVisibleHx" :before-close="handleCancelPos" width="40%"  >
        <template >
          <el-form ref="deliveryFormPos" :model="deliveryFormPos" v-loading="posloading" :element-loading-text="posLoadingMsg" class="demo-ruleForm" label-width="100px">
          <el-form-item  label="核销码：" v-if="hxCode" style="margin-left: 10%;" min-width="500">
            <el-input v-model="posform.ziti_code" class="input-b" size="medium" style="margin-top: 3px;border-color: #a4134d;">
            <template slot="prepend">ZT_</template>
              <template slot="append">
                <el-button @click="checkZtCode"  size="small"  v-if="!ztloading" >查询</el-button>
                <i class="el-icon-loading" style="font-size: 20px;color: #1aad19" v-if="ztloading"></i>
              </template>

            </el-input>
            <br>
            <span  class="frm-tips" style="color: #F56C6C;" v-if="showErr">{{codeError}}</span>

          </el-form-item>
            <span v-if="hxInfo">
               <el-form-item label="订单号" >
            <span>{{deliveryFormPos.order_id}}</span>
          </el-form-item>
          <el-form-item label="订单商品">
            <div v-for="item in deliveryDataPos.orderInfo.items">
              <span >{{item.item_name}}</span><br>
              <span v-if="lastOp !== ''" class="frm-tips">{{lastOp}}SN：</span>
              <span v-if="lastSn[item.item_id]" class="frm-tips">
                {{lastSn[item.item_id].sn}}
                <el-button type="success" style="margin-left: 10px" round plain @click="cpLatsSn(lastSn[item.item_id].sn,item.item_id)"  size="mini">使用</el-button>
                 <br>
              </span>
               <span style="color:red;font-size: 14px">*&nbsp;</span>
              <el-input v-model="posform.snList[item.item_id].sn" size="small" class="input-b" clearable
                        @clear="checkSn(item.item_id)" @blur="checkSn(item.item_id)" placeholder="请输入商品SN"></el-input>
               <span class="frm-tips" style="color: #F56C6C;">{{ snErr[item.item_id].error }}</span>
              <i class="el-icon-loading"  v-if="snErr[item.item_id].load"></i>
              <i class="el-icon-circle-check" style="color: #1aad19" v-if="snErr[item.item_id].right"></i>
              <el-divider></el-divider>
            </div>
          </el-form-item>
          <el-form-item label="操作备注" >
            <el-input v-model="posform.remark" class="input-b" size="small" placeholder="请输入操作备注"></el-input>
          </el-form-item>
            </span>
          </el-form>
        </template>
        <div slot="footer" class="dialog-footer" >
          <span class="frm-tips" style="float: left;"  v-if="hxInfo">自提码：{{posform.ziti_code}}</span>
          <el-button @click.native="handleCancelPos" v-if="hxCode">取消</el-button>
          <el-button @click.native="backInputCode" v-if="hxInfo">返回上一步</el-button>
          <el-button type="primary"  v-if="hxInfo" @click="submitPosAction" :loading="buttonLoading">确认核销</el-button>
        </div>
      </el-dialog>

      <!-- 以上为pos操作 -->
      <el-drawer
        :visible.sync="orderDetailDraw"
        :direction="direction"
        :before-close="handleClose"
        :withHeader="false"
        size="70%"
      >
      <orderDetail :set-order='detail_order' ></orderDetail>
      </el-drawer>
    </div>
    <router-view></router-view>

    <div v-if="login_type === 'dealer'">
      <DistributorSelect :store-visible="distributorVisible" :is-valid="true" :relDataIds="distributor_info"
                       :get-status="setDistributorStatus" @chooseStore="chooseDistributorAction" @closeStoreDialog="closeDialogAction"
                       returnType="selectRow"></DistributorSelect>
    </div>
    <div v-else>
      <StoreListSelect
      :store-visible="distributorVisible"
      :is-valid="true"
      :relDataIds="distributor_info"
      :get-status="setDistributorStatus"
      @chooseStore="chooseDistributorAction"
      @closeStoreDialog="closeDialogAction"
      returnType="selectRow"
    ></StoreListSelect>
    </div>
  </div>
</template>
<script>

import { mapGetters } from 'vuex'
import {getProcessLog, getOrderDetail, delivery, orderExport, getCancelOrderInfo, cancelConfirm, getLogisticsList, cancelOrderConfirm, isBind, exportInvoice, isInvoiced, getWriteoff, doWriteoff, receivedOrder, exportOrderReciverInfo, getDeliveryDetail, getExporLabel, uploadOrderInvoice, updateOrderInvoice }from '@/api/trade'
import shopSelect from '@/components/shopSelect'
import store from '@/store'
import axios from 'axios'
import DistributorSelect from '@/components/function/distributorSelect'
import StoreListSelect from "@/components/storeListSelect";
import { getSelfHandleStatus ,getOrderListSelf,checkZitiCode,optionOrder,checkSn,getSn} from  '@/api/tool'
import orderDetail from "@/view/mall/trade/order/detail.vue";


export default {
  components: {
    shopSelect,
    DistributorSelect,
    StoreListSelect,
    orderDetail
  },
  data() {
    return {
      detail_order:'',
      orderDetailDraw:false,
      ztloading:false,
      dialogVisiblePos:false,//pos弹出
      dialogVisibleHx:false,//pos核销
      posloading:false,
      posLoadingMsg:'数据处理中。。。',
      posform:{
        op:'',
        remark:'',
        order_id:'',
        ziti_code:'',
        snList: []
      },
      snErr:[],
      lastSn:[],
      lastOp:'',
      write_off_key:'',
      posTitle:'',
      hxCode:true,
      hxInfo:false,
      showErr:false,
      codeError:'',

      logList: [],
      logParams: {
        is_view: false,
      },
      drawer: false,
      drawer_log: false,
      direction: 'rtl',
      options: [{
        label: '全部',
        name: 'all'
      }],
      value: [],
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const start = new Date();
            const end = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 6);
            end.setTime(end.getTime() - 3600 * 1000 * 24 * 0);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const start = new Date();
            const end = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 29);
            end.setTime(end.getTime() - 3600 * 1000 * 24 * 0);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const start = new Date();
            const end = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 89);
            end.setTime(end.getTime() - 3600 * 1000 * 24 * 0);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      tabPane: [],
      operator_type: '',
      is_distributor: false,
      IsDisabled: false,
      IsBind: false,
      open_self_handle: false,
      open_status: '',
      activeName: 'all',
      loading: false,
      create_time: '',
      params: {
        page: 1,
        pageSize: 10,
        order_class_exclude: 'drug,pointsmall',
        distributor_id: '',
      },
      cancelOrderInput: false,
      order_cancel_reason: [
        { name: '疑似黄牛单', value: 13 },
        { name: '库存超售', value: 14 },
        { name: '其他原因', value: 12 },
      ],
      order_status: '',
      time_start_begin: '',
      time_start_end: '',
      salesman_mobile: '',
      total_count: 0,
      order_type: 'normal',
      list: [],
      identifier: '',
      serial_number: '',
      source_list: [],
      source_name: '',
      source_id: '',
      order_class: '',
      cancel_order: '',
      deliveryVisible: false,
      deliveryTitle: '',
      deliveryData: {
        orderInfo: {},
      },
      deliveryDataPos: {
        orderInfo: {},
      },
      deliveryForm: {
        delivery_type: 'batch',
        order_id: '',
        delivery_corp: '',
        delivery_code: '',
        sepInfo: {},
        dOption: ''
      },
      deliveryFormPos: {
        order_id: '',
      },
      dlycorps: [],
      cancelVisible: false,
      cancelData: {
        orderInfo: {},
      },
      cancelForm: {
        order_id: '',
        check_cancel: '1',
        shop_reject_reason: '',
      },
      cancelOrderVisible: false,
      cancelOrderData: {
        orderInfo: {},
      },
      cancelOrderForm: {
        order_id: '',
        cancel_reason: 0,
        other_reason: '',
      },
      writeoffOrderVisible: false,
      writeoffOrderData: {
        items: {},
      },
      writeoffOrderForm: {
        order_id: '',
        pickupcode: '',
      },
      writeoffOrderSuccVisible: false,
      writeoffOrderSucc: {
        msg: '',
      },
      // 选择发货订单的类型，老订单还是新订单
      selectItemType: '',
      deliveryVisibleNew: false,
      receiveView: false,
      orderInfo: {},
      buttonLoading: false,
      deliveryInfo: [],
      deliveryLoading: true,
      loadingJd: false,
      oredrInvoiceUploadList: [],

      formDrawer: false,
      shop_num: 0,
      distributorVisible: false,
      distributor_info: [],
      setDistributorStatus: false,
      rolesListData: [],
    }
  },
  computed: {
    ...mapGetters(['wheight', 'login_type']),
    isSingleSelect() {
      const role = this.rolesListData.find(item => item.role_id === this.form.role_id)
      return role && role.role_name !== 'VAD_Admin'
    }
  },
  methods: {
    resetPosFrom() {
      this.posform = {
        remark: '',
        order_id: '',
        ziti_code: '',
        snList: []
      }
      this.write_off_key=''
      this.hxInfo=false
      this.hxCode=true
      this.showErr=false
      this.codeError=''
      this.lastSn=[]
      this.lastOp=''
      this.snErr =[]
    },
    showDetail(row) {
      this.orderDetailDraw = true
      this.detail_order =row.order_id
    },
    handleClose(done) {
      this.orderDetailDraw =false;
    },
    posAction(order_id, op) {
      this.posLoadingMsg ='数据处理中。。。'
      this.resetPosFrom()
      if(op === 'write_off'){
        this.dialogVisibleHx =true
      }else{
        this.dialogVisiblePos = true
      }
      this.posform.op = op
      this.posTitle = this.getOpCn(op)
      this.deliveryFormPos.order_id = ''
      this.deliveryDataPos.orderInfo = {}
      this.posloading = true
      this.getOrderDetail(order_id);
      this.getSn(order_id);
      this.numberSearch()


    },
    checkZtCode() {
      this.write_off_key = '';
      if (this.posform.ziti_code === '') {
        this.showErr = true
        this.codeError = '请填写正确的核销码';
        return
      }
      this.codeError =''
      this.ztloading = true
      let codePost = {
        order_id:this.deliveryFormPos.order_id,
        code:this.posform.ziti_code,
      }
      checkZitiCode(codePost).then((response) => {
        if(response.data.data.write_off_key){
          this.showErr = false
          //查询接口 验证核销码
          this.hxCode = false
          this.hxInfo = true
          this.write_off_key = response.data.data.write_off_key
          this.ztloading = false
        }else{
          this.showErr = true
          this.codeError = '请填写正确的核销码';
          this.ztloading = false
        }

      }).catch(err => {
        this.showErr = true
        this.codeError = '请填写正确的核销码';
        this.ztloading = false
      })
    },
    cpLatsSn(lastSn,itemId){
      this.posform.snList[itemId].sn = lastSn
      this.checkSn(itemId);
    },
    clearShop(){
      this.params.distributor_id =''
      this.shop_num=0
      this.distributor_info =[]
      this.numberSearch()
    },
    getOpCn(op){
      let title = 'Pos操作';
      switch (op) {
        case 'stock_up':
          title = '备货'
          break;
        case 'outbound':
          title = '出库'
          break;
        case 'return':
          title = '退回'
          break;
        case 'write_off':
          title = '核销'
          break;
      }
      return title;
    },
    backInputCode() {
      this.hxCode = true
      this.hxInfo = false
      this.posform.write_off_key = this.write_off_key =''
      this.posform.snList.forEach((item) => {
          this.posform.snList[item.item_id].sn = ''
          this.snErr[item.item_id].sn = ''
          this.snErr[item.item_id].error = ''
          this.snErr[item.item_id].right =false
      })
    },
    getSn(orderId){
      let postSn ={
        order_id:orderId,
        op:this.posform.op
      }
      getSn(postSn).then((response) => {
        if(response.data.data.item){
          this.lastSn =response.data.data.item;
          this.lastOp =response.data.data.op_name;
        }
      })
    },
    checkSn(itemId){
      let sn = this.posform.snList[itemId].sn
      if(!this.checkSnRule(sn)){
        this.snErr[itemId].error = '请输入正确的SN'
        this.snErr[itemId].right=false
        this.snErr[itemId].load=false
        return
      }
      let same =false
      this.posform.snList.forEach((item) => {
        if (item.sn === sn && item.item_id !== itemId) {
          this.snErr[itemId].error = '存在相同的SN！'
          this.snErr[itemId].right =false
          same = true
        }
      })
      if (same) {
        return false
      }
      let postSn ={
        sn:sn,
        op:this.posform.op
      }
      let title=''
      switch (this.posform.op) {
        case 'stock_up':
          title = '备货'
          break;
        case 'outbound':
          title = '出库'
          break;
        case 'return':
          title = '退回'
          break;
        case 'write_off':
          title = '核销'
          break;
      }

      this.snErr[itemId].load=true
      this.snErr[itemId].right=false
      this.snErr[itemId].error = ''
      checkSn(postSn).then((response) => {
        if(response.data.data.status === true) {
          //snList_err_+
          this.snErr[itemId].error = '此SN已操作过'+title
        }else{
          this.snErr[itemId].error = ''
          this.snErr[itemId].right=true

        }
        this.snErr[itemId].load=false

      })
    },
    getOrderDetail(order_id){
      getOrderDetail(order_id).then((response) => {
        this.deliveryDataPos = response.data.data
        if (this.deliveryDataPos.orderInfo.cancel_status == 'WAIT_PROCESS') {
          this.$message.error('客户已经申请退款!!')
          return false
        }
        let arr = []
        let arr2 =[];
        this.deliveryDataPos.orderInfo.items.forEach((item) => {
          let key =item.item_id;
          let data =[];
          data = {
            name: item.item_name + ' ' + item.item_spec_desc,
            sn: '',
            item_bn: item.item_bn,
            item_id: item.item_id,

          };
          arr[key] = data
          arr2[key] = {error: '',load:false,right:false}
        })
        this.posform.snList =arr;

        this.snErr = arr2;

        this.deliveryFormPos.order_id = order_id
        this.posloading=false

      })
    },
    submitPosAction() {
      let isCanOp = true
      this.posform.snList.forEach((item) => {
        if(!this.checkSnRule(item.sn)){
          this.snErr[item.item_id].error = '请输入SN'
          this.snErr[item.item_id].right =false
          isCanOp= false
        }
      })
      if (!isCanOp) {
        return false
      }
      this.posform.order_id = this.deliveryFormPos.order_id
      this.posform.write_off_key = this.write_off_key
      //提交this.posform即可
      this.posLoadingMsg = '正在处理' + this.getOpCn(this.posform.op) + '。。。'
      this.posloading = true
      optionOrder(this.posform).then((response) => {
        this.deliveryLoading = false;
        this.dialogVisiblePos =false
        this.dialogVisibleHx = false
        setTimeout(() => {
          this.getOrders(this.params)
        }, 2000)
          if (response.data.data.status && response.data.data.notice !=='') {
            this.$message({
              message:response.data.data.notice,
              type: 'success'
            });
          }
      });
      //optionOrder
    },
    checkSnRule(sn){
      //去除空格
      sn = sn.replace(/\s+/g, '');
      if(sn === ''){
        return false;
      }
      let re
      const regex= /^[\a-\z\A-\Z0-9\u4e00-\u9fe5]+$/
      return regex.test(sn)
    },
    getDeliveryInfo(row,order_id) {
      console.log(row);
      this.deliveryInfo = [];
      this.deliveryLoading = true;
      let query = {
        delivery_corp: row.delivery_corp,
        delivery_code: row.delivery_code,
        order_id: row.order_id,
      }
      getDeliveryDetail(query).then((response) => {
        this.deliveryInfo = response.data.data;
        console.log(this.deliveryInfo);
        this.deliveryLoading = false;
      });
    },

    handleColse() {
      this.receiveView = false;
      this.orderInfo = {};
      this.buttonLoading = false;
      this.oredrInvoiceUploadList = []
    },
    isShowReceive(row) {
      if (this.isShowAudit(row)) {
        return false;
      }
      // 对接了  oms/erp  但是 erp没有落地好订单时  不能接单！
      if (row.send_oms == 'true' && row.oms_status != '3') {
        return false;
      }

      return this.login_type !== 'disti' && (row.receive_status === '0') && row.order_status === 'PAYED';
    },


    isShowDelivery(row) {
      let now = (new Date).getTime() / 1000;
      if (this.login_type !== 'disti' && row.receipt_type === 'logistics'               // 非 经销商端 并且 是物流
        && (row.order_status === 'PAYED' || row.order_status === 'WAIT_BUYER_CONFIRM')  // 已付款 或者 待收货[并有顺丰数据]
        && (row.cancel_status === 'NO_APPLY_CANCEL' || row.cancel_status === 'FAILS')   // 没有申请取消 或者 取消审核被拒
        && (row.receive_status === '1' || row.receive_status === '4')                   // 接单1 接单后拒绝取消订单4   才可以 显示 发货 === 2021年08月27日16:44:41 新增
        // && (row.sf_is_cancel === '0')                                                // 未 撤销或取消
        && row.pay_type !== 'appointment'
        && row.is_show_delivery === true
        && ((row.send_oms == "true" && (row.oms_status == 51 || row.oms_status == 5)) || row.send_oms == "false") // 51备货完成// || row.oms_status == 5
      ) {


        //优化  预售订单，未到预售开售期， 根据订单附加信息记录的是否可呼叫快递来判断 2023.08.25 pre_call_delivery=1则不限制
        let just_open_slae_call = row.extInfo.pre_call_delivery === 0 ? true : false
        // 新增  预售订单，未到预售开售期，不可发货 === 2021年08月27日16:44:46 新增
        if (just_open_slae_call) {
          if (row.sale_start_time && row.sale_start_time > now) {
            return false;
          }
        }
        return true;
      }

      return false;
    },

    // 再次备货
    isShowCallBeihuo(row) {

      if (this.login_type == 'disti') {
        return false;
      }
      // 如果有审核 就不要有 呼叫快递
      if (this.isShowAudit(row)) {
        return false;
      }

      console.log("isShowCallBeihuo 11 oms_status:", row.oms_status)
      //发货
      if (row.send_oms != 'true') {
        return false;
      }

      //  if (row.oms_status == 0 ){
      //   return false;
      // }
      // if(row.is_show_delivery === true && row.oms_store == 1){
      //   //已出库
      //   return true;
      // }
      if (row.order_status === 'CANCEL' || row.order_status === 'DONE') {
        return false;
      }

      if (row.oms_store === '1' && row.delivery_corp_source !== 'SF_API_RETURNED') {//
        //这里还要判断一下是否已经入库了
        if(!row.back){
          return false;
        }
      }

      if( row.oms_status == 5 && row.delivery_corp_source === 'SF_API_FIX' &&  row.receipt_type != 'logistics' && row.order_status === 'PAYED' ){
        //快递转自提 且备货已完成
        return true;
      }


      // 已撤销的 订单 支持 呼叫快递
      // if (row.is_show_delivery === true && row.sf_is_cancel == '1' && (row.delivery_corp_source === 'SF_API_ERROR' || row.delivery_corp_source === 'SF_API_RETURNED') && row.receive_status < 5) {
      //   return true;
      // }
      //发货
      // if (row.send_oms == 'true' && row.oms_status != 0 ){
      //   return false;
      // }

      // console.log("isShowCallBeihuo 22 row              :",row )
      console.log("isShowCallBeihuo 22 order_id       :", row.order_id)

      console.log("isShowCallBeihuo 22 oms_status       :", row.oms_status)
      console.log("isShowCallBeihuo 33 receive_status   :", row.receive_status)
      console.log("isShowCallBeihuo sf_is_cancel        :", row.sf_is_cancel)
      console.log("isShowCallBeihuo delivery_corp_source:", row.delivery_corp_source)

      if (!row.delivery_corp_source) {
        return false;
      }


      if (row.oms_status == 51) {
        return false;
      }

      // 已撤销的 订单 支持 呼叫快递
      if (row.is_show_delivery === true && row.sf_is_cancel == '1'
        && (row.delivery_corp_source === 'SF_API_ERROR'
          || row.delivery_corp_source === 'SF_API_RETURNED')
        && row.receive_status < 5
        && row.send_oms == 'true'
        && (row.oms_status == 3 || row.oms_status == 0)) {
        console.log("异常订单（SF_API_ERROR）")
        return true;
      }
      //  撤销（SF_API_CHEXIAO） 创单成功（oms_status=3）
      if (row.delivery_corp_source === 'SF_API_CHEXIAO_BEIHUO'
        && row.send_oms == 'true'
        && (row.oms_status == 0)) {
        console.log("撤销（SF_API_CHEXIAO_BEIHUO） 创单成功（oms_status=3）")
        return true;
      }
      if (row.delivery_corp_source === 'SF_API_CHEXIAO'
        && row.send_oms == 'true'
        && (row.oms_status == 0)) {
        console.log("撤销（SF_API_CHEXIAO） 创单成功（oms_status=3）")
        return true;
      }



      return row.sf_is_cancel == '0' && row.sf_order_id == '0' && this.isShowDelivery(row);
    },

    // 呼叫快递
    isShowCall(row) {

      if (this.login_type == 'disti') {
        return false;
      }

      // 如果有审核 就不要有 呼叫快递
      if (this.isShowAudit(row)) {
        return false;
      }

      console.log("呼叫快递-------000001----")

      if (row.order_status == "CANCEL") {
        return false;
      }

      if (row.receipt_type !== 'logistics') {
        return false;
      }

      console.log("呼叫快递 2222 oms_status       :", row.oms_status)
      console.log("呼叫快递 3333 receive_status   :", row.receive_status)
      console.log("呼叫快递 sf_is_cancel        :", row.sf_is_cancel)
      console.log("呼叫快递 delivery_corp_source:", row.delivery_corp_source)

      // 已撤销的 订单 支持 呼叫快递
      if (row.is_show_delivery === true && row.sf_is_cancel == '1'
        && (row.delivery_corp_source === 'SF_API_ERROR'
          || row.delivery_corp_source === 'SF_API_RETURNED')
        && row.receive_status < 5
        && row.oms_status == 51
      ) {
        return true;
      }
      console.log("呼叫快递-------000002 ---")

      if (row.send_oms == "true"
        && row.delivery_corp_source == "SF_API_CHEXIAO"
        && row.oms_status == 51
      ) {
        return true;
      }
      console.log("呼叫快递-------000003 ---")


      // if(row.is_show_delivery === true && row.oms_store == 0){//未出库
      //   ////未出库
      //   return true;
      // }
      // if( row.send_oms== "true" && (row.oms_status != 51 && row.oms_status != 5  )){
      //   return false;
      // }
      if (row.oms_store == 1 && !row.back) {
        return false;
      }
      if(this.isShowCallBeihuo(row)  && row.oms_store !=='3')//展示了再次备货。要看一下oms_store 是不是=3
      {
        return false;
      }


      return row.sf_is_cancel == '0' && row.sf_order_id == '0' && this.isShowDelivery(row);
    },

    // 转顺丰
    isShowExchange(row) {
      if (this.login_type == 'disti') {
        return false;
      }
      if (row.receipt_type !== 'logistics') {
        return false;
      }
      if(row.oms_store === '1'){
        //已经出库了
        return false;
      }
      return  this.isShowDelivery(row);
    },
    // 撤销快递
    isShowCancel(row) {
      if (this.login_type == 'disti') {
        return false;
      }

      if (this.isShowCalBackDelivery(row)) {
        return false;
      }

      console.log("isShowCalBackDelivery =======  OK")

      return row.sf_order_id !== '0' && (row.delivery_corp_source === 'SF_API_PENDING') && this.isShowDelivery(row);
    },

    // 拦截快递
    isShowCallBack(row) {
      if (this.login_type == 'disti') {
        return false;
      }
      if (this.isShowCalBackDelivery(row) || this.isShowCancel(row)) {
        return false;
      }

      // 顺丰单号 没有发生取消，并且 已发货 就 可以支持拦截快递
      return row.sf_is_cancel == 0 && (row.delivery_corp_source === 'SF_API_COLLECT' || row.delivery_corp_source === 'SF_API_ON_WAY') && this.isShowDelivery(row);
    },
    showErrMsg(row) {
      if ((this.isShowCancel(row) || this.isShowCallBack(row)) && row.delivery_corp_order !== 'MTPS') {
        return false;
      }
      return true;
    },

    // 呼叫快递
    isShowCalBackDelivery(row) {
      if (this.login_type == 'disti') {
        return false;
      }
      // 如果有审核 就不要有 呼叫快递
      if (this.isShowAudit(row)) {
        return false;
      }
      // 已撤销的 订单 支持 呼叫快递
      if (row.is_show_delivery === true && row.sf_is_cancel == '1'
        && (row.delivery_corp_source === 'SF_API_ERROR'
          || row.delivery_corp_source === 'SF_API_CHEXIAO'
          || row.delivery_corp_source === 'SF_API_RETURNED')
        && row.receive_status < 5) {
        return true;
      }

      return row.sf_is_cancel == '0' && row.sf_order_id == '0' && this.isShowDelivery(row);
    },

    // 关闭订单
    isShowClose(row) { // 非  运营端   支付 自提未完成 且不在审核 状态的订单  才可以关闭
      if (this.login_type == 'disti') {
        return false;
      }

      if (row.order_status == "CANCEL") {
        return false;
      }

      if (row.is_warehouse > 0) {
        if (row.oms_status == 51) {
          return false
        }
        if (row.oms_store != 0) {
          return false
        }
        if (row.delivery_code) {
          return false
        }
      }

      // 能 审核订单 或者 撤销快递 就不能 关闭订单
      if (this.isShowAudit(row) || this.isShowCancel(row)) {
        return false;
      }

      // 能呼叫快递 就能 关闭订单
      if (this.isShowCall(row)) {
        return true;
      }

      if (row.is_show_delivery === true && (row.order_status === 'WAIT_BUYER_CONFIRM' || row.order_status === 'PAYED') && row.sf_order_id === '0') {
        return true;// 发货异常 的订单 支持 关闭订单
      }

      return this.login_type !== 'disti' && row.order_status === 'PAYED' && row.ziti_status !== 'DONE' && row.cancel_status !== 'WAIT_PROCESS';
    },
    isShowInvoice(row) {
      return this.login_type !== 'disti' && row.is_invoiced === '0' && row.invoice && row.invoice_source == 0 && row.order_status == 'DONE';
    },
    isShowExportReciver(row) {
      return this.login_type === 'distributor' && row.receipt_type === 'logistics' && (row.receive_status == '1' || row.receive_status == '4') && row.delivery_status == 'PENDING';
    },
    isShowExportPdf(row) {
      return this.login_type === 'dealer' || this.login_type === 'distributor';
    },
    isShowAudit(row) {
      return this.login_type !== 'disti' && (row.receive_status === '2' || row.cancel_status === 'WAIT_PROCESS') /*&& row.order_status === 'PAYED'*/;
    },
    isShowDisabled(row) {
      return !this.isShowReceive(row) && !this.isShowDelivery(row) && !this.isShowClose(row) && !this.isShowInvoice(row) && !this.isShowAudit(row) && !this.isShowExportReciver(row) && !this.isShowExportPdf(row);
    },
    onCopy() {
      this.$notify({
        message: '复制成功',
        type: 'success',
      })
    },
    checkStatus(source) {
      if (!source) {
        return false;
      }
      if (source == null) {
        return false;
      }
      if (source == '') {
        return false;
      }
      return true
    },
    checkPreOrder(row) {
      if (row.type == '2') {
        let qh = row.extInfo.pre_call_delivery === 1 ? true : false
        if (qh) {
          return false;
        }
        return true;
      }
      return false
    },
    checkPreOrderQh(row) {
      if (row.type == '2') {
        let qh = row.extInfo.pre_call_delivery === 1 ? true : false
        if (qh) {
          return true;
        }
        return false;
      }
      return false
    },
    // 切换tab
    handleClick(tab, event) {
      this.activeName = tab.name
      this.params.order_status = tab.name == 'all' ? '' : tab.name
      this.params.page = 1
      this.getParams()
      this.getOrders(this.params)
    },
    storeSearch(val) {
      val && val.shop_id
      this.params.distributor_id = val.shop_id
      this.params.page = 1
      this.getParams()
      this.getOrders(this.params)
    },
    TypeHandle(val) {
      this.params.page = 1
      this.getParams()
      this.getOrders(this.params)
    },
    numberSearch(e) {
      this.params.page = 1
      this.getParams()
      this.getOrders(this.params)
    },

    dateChange(val) {
      if (val && val.length > 0) {
        this.time_start_begin = val[0] / 1000;//this.dateStrToTimeStamp(val[0] + ' 00:00:00')
        this.time_start_end = val[1] / 1000;//this.dateStrToTimeStamp(val[1] + ' 23:59:59')
      } else {
        this.time_start_begin = ''
        this.time_start_end = ''
      }
      this.params.page = 1
      this.getParams()
      this.getOrders(this.params)
    },
    sourceSearch(item) {
      this.params.source_id = item.source_id
      this.params.page = 1
      this.getParams()
      this.getOrders(this.params)
    },
    handleCurrentChange(page_num) {
      this.loading = false
      this.params.page = page_num
      this.getParams()
      this.getOrders(this.params)
    },
    handleSizeChange(pageSize) {
      this.loading = false
      this.params.page = 1
      this.params.pageSize = pageSize
      this.getParams()
      this.getOrders(this.params)
    },
    getParams() {
      this.params.time_start_begin = this.time_start_begin
      this.params.time_start_end = this.time_start_end
      this.params.order_type = this.order_type
      this.params.order_class = this.order_class
      this.params.salesman_mobile = this.salesman_mobile
      this.params.serial_number = this.serial_number
      if (this.identifier.length == 11) {
        this.params.mobile = this.identifier
        this.params.order_id = ''
      } else {
        this.params.mobile = ''
        this.params.order_id = this.identifier
      }
    },
    dateStrToTimeStamp(str) {
      return Date.parse(new Date(str)) / 1000
    },
    getOrders(filter, type = '') {
      if (type) {
        const newFilterList = this.export_field.map(el => el.name_en)
        filter = {
          ...filter,
          type,
          export_field: newFilterList,
          order_status: this.activeName === 'all' ? '' : this.activeName,
        }
      } else {
        this.loading = true
      }
      getOrderListSelf(filter).then((response) => {
        this.exportDialogVisible = false
        if (response.data.data.status) {
          this.$message({
            type: 'success',
            message: '已加入执行队列，请在设置-导出列表中下载',
          })
          return false;
        } else {
          this.list = response.data.data.list

          this.total_count = Number(response.data.data.pager.count);
          this.options = response.data.data.pane;
          this.loading = false

        }
        this.getSelfHandleStatus('msg');
      })
    },
    getStatus() {
      isBind().then((response) => {
        this.IsBind = response.data.data.result
      })
    },
    getSelfHandleStatus(alert='box') {
      getSelfHandleStatus().then((response) => {
        console.log(response.data.data.open_status)
        this.open_status = response.data.data.open_status;
        if(response.data.data.open_status === '1' || response.data.data.shop_is_join){
          this.open_self_handle = true;
        }else{
          this.open_self_handle = false;
        }
      })
    },
    querySearch(queryString, cb) {
      var restaurants = this.source_list
      var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    createFilter(queryString) {
      return (restaurant) => {
        return restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
      }
    },
    deliveryAction(data, type = '', option = '') {
      // 编辑物料弹框
      let order_id = data.order_id

      this.selectItemType = data.delivery_type
      // this.selectItemType = 'new'
      this.deliveryTitle = '订单发货相关操作'

      if ((type === 'delivery' || type === 'delivery_mt' || type === 'delivery_tc') && data.distributor_info.sf_call_transfer != '0' && data.distributor_info.alternate_place_id != '0') {
        this.$confirm('将由' + data.distributor_info.alternatePlaceInfo.name + '发货点进行发货', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.getOrderDetailFunc(order_id, type, option)
        }).catch((error) => {
          console.log(error.message)
        });
      } else {
        this.getOrderDetailFunc(order_id, type, option)
      }
    },
    exchangeSfAction(data, type = '', option = '') {
      // 编辑物料弹框
      let order_id = data.order_id

      this.selectItemType = data.delivery_type
      // this.selectItemType = 'new'
      this.deliveryTitle = '订单发货相关操作'

      this.$confirm('确定将此订单转由顺丰配送吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.getOrderDetailFunc(order_id, type, option)
      }).catch((error) => {
        console.log(error.message)
      });

    },
    setLogPage(order_id) {
      this.drawer_log = true
      this.getProcessLogInfo(order_id)
    },
    getProcessLogInfo(order_id) {
      getProcessLog(order_id, this.logParams).then(response => {
        this.logList = response.data.data
      })
    },
    getOrderDetailFunc(order_id, type, option = '') {
      getLogisticsList().then((res) => {
        this.dlycorps = res.data.data.list
      })
      getOrderDetail(order_id).then((response) => {
        this.deliveryData = response.data.data
        if (this.deliveryData.orderInfo.cancel_status == 'WAIT_PROCESS') {
          this.$message.error('客户已经申请退款，请先处理退款操作再决定是否发货!')
          return false
        }

        if (this.selectItemType == 'old') {
          this.deliveryVisibleNew = true
        } else {
          this.deliveryVisibleNew = true
        }
        if (this.deliveryData && this.deliveryData.orderInfo.delivery_status === 'PARTAIL') {
          this.IsDisabled = true
          this.deliveryForm.delivery_type = 'sep'
        }
      })
      this.deliveryForm.order_id = order_id
      this.deliveryForm.dtype = type
      this.deliveryForm.dOption = option
    },
    confirmCancelOrderAction(order_id, row) {
      // 已支付订单的取消订单审核
      let params = { order_type: 'normal' }
      this.orderInfo = row;
      getCancelOrderInfo(order_id, params).then((response) => {
        this.cancelData = response.data.data
        this.cancelVisible = true
      })
      this.cancelForm.order_id = order_id
    },
    receivedOrder(order_id) { // 接单
      this.buttonLoading = true;
      this.loadingJd = true
      receivedOrder(order_id, {
        'receive_status': 1
      }).then((response) => {
        let res = response.data.data, msg = '操作失败！', type = 'error';
        this.loadingJd = false
        if (res) {
          this.handleColse();
          this.getOrders(this.params);
          type = 'success';
          msg = '接单成功，请准备发货';
        }
        this.loadingJd = false

        this.$message({
          type: type,
          message: msg,
        });
      });
    },
    handleCancelOrderCancel() {
      this.orderInfo = {};
      this.cancelVisible = false
      this.cancelForm.order_id = ''
      this.cancelForm.shop_reject_reason = ''
      this.buttonLoading = false;
    },
    submitCancelConfirmAction() { // 提交取消订单审核结果

      this.buttonLoading = true;
      if (this.cancelForm.check_cancel == '0') { // 拒绝
        this.cancelForm.receive_status = '4';
      }
      if (this.cancelForm.check_cancel == '1') { // 同意
        this.cancelForm.receive_status = '3';
      }
      if (this.cancelForm.check_cancel == '2') { //管理后台强制取消订单
        this.cancelForm.receive_status = '5';
      }
      if (this.cancelForm.check_cancel == '3') { //管理后台强制取消订单
        this.cancelForm.receive_status = '6';
      }

      cancelConfirm(this.cancelForm.order_id, this.cancelForm).then(response => {
        var cancelOrderStatus = response.data.data.refund_status
        if (cancelOrderStatus == 'AUDIT_SUCCESS') {
          this.$message.success('操作成功!')
          this.handleCancelOrderCancel()
          this.getOrders(this.params)
        } else if (cancelOrderStatus == 'SHOP_CHECK_FAILS') {
          this.handleCancelOrderCancel()
          this.$message.success('审核拒绝成功!')
          this.getOrders(this.params)
        } else {
          this.$message.error('审核失败!')
          this.getOrders(this.params)
          return false
        }
      });
    },
    handleCancel() {
      this.deliveryVisible = false
      this.deliveryForm.order_id = ''
      this.deliveryForm.delivery_corp = ''
      this.deliveryForm.delivery_code = ''
      this.deliveryVisibleNew = false
      this.buttonLoading = false;
      this.deliveryForm = {
        delivery_type: 'batch',
        order_id: '',
        delivery_corp: '',
        delivery_code: '',
        sepInfo: {},
      }
      this.IsDisabled = false
    },
    handleCancelPos() {
      this.dialogVisiblePos = false
      this.dialogVisibleHx = false

    },
    beihuoAction(data) {
      this.deliveryForm.type = this.selectItemType = 'new'
      this.deliveryForm.order_id = data.order_id
      this.deliveryForm.delivery_type = 'batch'
      this.deliveryForm.option_bh = 'zcbh'
      delivery(this.deliveryForm).then((response) => {
        this.deliveryForm.option_bh = false//重置数据
        // let deliveryStatus = response.data.data.delivery_status, sfOrderId = response.data.data.sf_order_id;
        if (response.data.data) {
          this.handleCancel()
          this.$message.success('操作成功！')
          this.getOrders(this.params)
        } else {
          this.$message.error('操作失败！')
          this.buttonLoading = false
          return false
        }
      })
    },
    submitDeliveryAction() {
      this.buttonLoading = true;
      // 提交物料
      this.deliveryForm.type = this.selectItemType = 'new';
      if (this.deliveryForm.delivery_type === 'sep') {
        if (this.selectItemType === 'old') {
          this.deliveryForm.sepInfo = JSON.stringify(this.deliveryData.orderInfo.items)
          this.deliveryForm.delivery_corp = ''
          this.deliveryForm.delivery_code = ''
        } else {
          this.deliveryForm.sepInfo = JSON.stringify(JSON.parse(JSON.stringify(this.deliveryData.orderInfo.items)).filter((item) => item.delivery_num && item.delivery_num != ''))
        }
      } else {
        this.deliveryForm.sepInfo = {}
      }
      delivery(this.deliveryForm).then((response) => {
        // let deliveryStatus = response.data.data.delivery_status, sfOrderId = response.data.data.sf_order_id;
        if (response.data.data) {
          this.handleCancel()
          if (response.data.data.break === true) {
            this.$message.success('操作成功,美团呼叫失败，已为您自动呼叫顺丰！')
          } else {
            this.$message.success('操作成功！')

          }
          this.getOrders(this.params)
        } else {
          this.$message.error('操作失败！')
          this.buttonLoading = false;
          return false
        }
      }).catch(() => {
        this.buttonLoading = false
        this.getOrders(this.params)
      })
    },
    //关闭订单
    cancelOrderAction(order_id, row) {
      let params = { order_type: 'normal' }
      getOrderDetail(order_id).then((response) => {
        this.cancelOrderData = response.data.data
        if (this.cancelOrderData.orderInfo.cancel_status == 'SUCCESS') {
          this.$message.error('该订单已关闭！')
          // this.$message.error('该订单已取消!')
          return false
        }
        this.orderInfo = row;
        this.cancelOrderVisible = true
      })
      this.cancelOrderForm.order_id = order_id
    },
    handleCancelOrder() {
      this.cancel_order = ''
      this.cancelOrderVisible = false
      this.cancelOrderForm.order_id = ''
      this.cancelOrderForm.other_reason = ''
      this.cancelOrderInput = false
      this.orderInfo = {};
      this.buttonLoading = false;
    },
    cancelReasonSelect(val) {
      if (val == 12) {
        this.cancelOrderInput = true
      } else {
        this.cancelOrderForm.other_reason = ''
        this.cancelOrderInput = false
      }
      this.cancelOrderForm.cancel_reason = val
    },
    submitCancelOrderConfirmAction() {
      this.buttonLoading = true;
      if (this.cancelOrderForm.cancel_reason == 0) {
        this.$message.error('请选择关闭原因!')
        return false
      }
      if (this.cancelOrderForm.cancel_reason == 12 && this.cancelOrderForm.other_reason == '') {
        this.$message.error('请输入关闭原因!')
        return false
      }

      let orderStatus = this.orderInfo.order_status, order_id = this.orderInfo.order_id, receiveStatus = this.orderInfo.receive_status;
      let sf_is_cancel = this.orderInfo.sf_is_cancel
      let sf_order_id = this.orderInfo.sf_order_id

      cancelOrderConfirm(order_id, this.cancelOrderForm).then((response) => {

        this.handleCancelOrder()
        if (orderStatus === 'PAYED'
          || (orderStatus === 'WAIT_BUYER_CONFIRM'
            && (sf_is_cancel > 0 || sf_order_id > 0))) {

          // 2021年08月23日18:54:05 取消成功  就直接  退款无需审核   需求
          if (receiveStatus == '1' || receiveStatus == '4') { // 已接单 管理员取消 未发货商家取消
            this.cancelForm.check_cancel = '3';
          } else {
            this.cancelForm.check_cancel = '2'; // 管理后台强制取消订单
          }
          this.cancelForm.order_id = order_id
          this.submitCancelConfirmAction();
        } else {

          this.$message.success('关闭订单成功！');
          this.getOrders(this.params);
        }
      }).catch((error) => {
        this.buttonLoading = false;
        this.getOrders(this.params)
      })
    },

    initDate() {
      let start = new Date(new Date().toLocaleDateString())
      let end = new Date(new Date().toLocaleDateString())
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
      end.setTime(end.getTime() + 86399999)

      this.create_time = [start, end];
      this.params.time_start_begin = this.time_start_begin = start / 1000;
      this.params.time_start_end = this.time_start_end = end / 1000;

    },
    addDistributorAction() {
      this.distributorVisible = true;
      this.setDistributorStatus = true;
    },
    // 返回已选择的片铺列表
    chooseDistributorAction(val) {
      this.distributor_info = val
      let arr = []
      val.forEach(i => {
        arr.push(i.distributor_id)
      });
      this.params.distributor_id = arr.join(',')
      if (val.length > 0) {
        this.shop_num =val.length
      }
      this.numberSearch()
      this.closeDialogAction()
    },
    // 关闭店铺和经销商弹窗
    closeDialogAction() {
      this.distributorVisible = false
    },



  },
  mounted() {
    if (store.getters.login_type === 'distributor') {
      this.is_distributor = true
    }
    if (this.$route.query.tab) {
      this.activeName = this.$route.query.tab
      this.params.order_status = this.$route.query.tab
      this.params.page = 1
    }
    if (this.$route.query.order_id) {
      this.identifier = this.$route.query.order_id
      this.params.order_id = this.$route.query.order_id
    }
    this.params.order_type = this.order_type
    this.getStatus()
    this.getSelfHandleStatus('box')
    this.initDate();
    this.getOrders(this.params)
    this.operator_type = store.getters.login_type;
  },
}
</script>
<style scoped lang="css">
.demo-table-expand {
  font-size: 0;
}

.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}

.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}

.el-row {
  margin-bottom: 20px;
}

:last-child {
  margin-bottom: 0;
}

.el-col {
  border-radius: 4px;
}

.bg-purple-dark {
  background: #99a9bf;
}

.bg-purple {
  background: #d3dce6;
}

.bg-purple-light {
  background: #e5e9f2;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

.invoice {
  cursor: pointer;
}

.filter-header .el-select .el-input {
  width: 200px;
}

.has-gutter .cell {
  text-align: center;
}

.detail-info {
  margin: 0 10px;
}

.operate-btns {
.el-button {
  margin-bottom: 5px;
}

.mt-call {
  background-color: #febd3b;
  border-color: #febd3b;
  color: #333;
  font-weight: 600;
}

.sf-call {
  background-color: #000;
  border-color: #000;
  color: #fff;
  font-weight: 600;
}

.mt-cancel {
  border-color: #febd3b;
  color: #333;
}

.sf-cancel {
  border-color: #000;
  color: #D70C23;
}
}

.ipt-tag {
  margin-right: 5px;
}

.order-compose {
  padding-bottom: 40px
}

.drawer-list{
  padding-left: 20px
}

.confirm-drawer{
  padding: 20px;
  display: flex;
  justify-content: center
}
 .borderless-input {
   border: none;
   outline: none; /* 去除焦点时的轮廓 */
   box-shadow: none; /* 如果需要的话，去除输入框的阴影 */
 }
</style>
