<template>
  <div>
    <el-dialog
      :title="title"
      class="comp-first-dialog"
      :append-to-body="true"
      :modal-append-to-body="false"
      :visible="dialogVisible"
      @close="closeDialog"
      width="800px"
    >
      <el-form ref="questionForm" :rules="questionRules" :model="questionnaireForm" label-width="110px" style="height:100%">
        <el-form-item
          :label="curlevel == 2 ? '一级问卷项' : '二级问卷项'"
          prop="cur_answer_id"
          v-if="curlevel == 2 || curlevel == 3"
        >
          <el-select v-model="questionnaireForm.cur_answer_id" clearable placeholder="请选择" style="width: 55%" @change="onChange">
            <el-option v-for="item in parentAnswerList" :key="item.id" :label="item.subject" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="curlevel == 2 ? '一级选项' : '二级选项'"
          prop="parent_answer_id"
          v-if="curlevel == 2 || curlevel == 3"
        >
          <el-select v-model="questionnaireForm.parent_answer_id" clearable placeholder="请选择" style="width: 55%">
            <el-option v-for="item in answerList" :key="item.answer_id" :label="item.content" :value="item.answer_id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="问卷项类型" prop="qu_type">
          <el-radio-group v-model="questionnaireForm.qu_type">
            <el-radio label="1">单选项</el-radio>
            <el-radio label="2">多选项</el-radio>
            <el-radio label="3">输入框</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="问卷项标题" prop="subject">
          <el-input v-model="questionnaireForm.subject" placeholder="请输入问卷项标题" style="width: 55%"></el-input>
        </el-form-item>
        <el-form-item
          v-for="(item, index) in questionnaireForm.answer"
          :label="index == 0 ? '问卷选项' : ''"
          :key="index"
          :prop="'answer.' + index + '.content'"
          :rules="{ required: true, message: '内容不能为空', trigger: 'blur' }"
          class="question-item"
          v-if="questionnaireForm.qu_type == '1' || questionnaireForm.qu_type == '2'"
        >
          <div class="question-option">
            <el-input v-model="item.content" placeholder="请输入调研选项" style="width: 40%;" class="option"></el-input>
            <el-input v-model="item.remark" placeholder="请输入问卷项描述" style="width: 40%;" class="option"></el-input>
            <div @click="handleHomeImgChange(index)" class="comp-upload-box">
              <img v-if="item.image" :src="wximageurl + item.image" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </div>
            <i v-if="item.image" @click="onCleanImg(index)" class="el-icon-error icon-delete"></i>
            <el-button v-if="index == 0" type="text" class="iconfont icon-plus-circle setting-add" @click="addQuItem"></el-button>
            <el-button v-else type="text" class="iconfont icon-trash-alt setting-remove"  @click="removeQuItem(index)"></el-button>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="closeDialog">取消</el-button>
        <el-button type="primary" @click="saveChange('questionForm')">确定</el-button>
      </div>
    </el-dialog>
    <imgPicker :dialog-visible="homeimgDialog" :sc-status="isGetImage" @chooseImg="pickImg" @closeImgDialog="closeHomeImgDialog" />
  </div>
</template>


<script>
import { getQuestionnaireLevelQuestionList } from '@/api/wxa'
import imgPicker from '@/components/imageselect'

export default {
  components: {
    imgPicker
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    curlevel: {
      type: Number,
      default: 1
    },
    tplId: {
      type: String,
      default: 0
    },
    form: {
      type: Object,
      default: {}
    }
  },
  computed: {
  },
  watch: {
    form: {
      deep: true,
      handler (nVal) {
        if (!this.visible) return
        let form = nVal
        if (this.curlevel != 1 && nVal.parent_answer_info) {
          form['cur_answer_id'] = nVal.parent_answer_info.tpl_list_id
        }
        this.questionnaireForm = JSON.parse(JSON.stringify(form))
      }
    },
    visible (val) {
      this.dialogVisible = val
      if (val) {
        console.log(this.curlevel)
        this.getList()
      }
    }
  },
  data () {
    return {
      dialogVisible: false,
      loading: false,
      questionnaireForm: {
        cur_answer_id: '',
        parent_answer_id: '',
        qu_type: '1',
        subject: '',
        answer: [{ content: '' }]
      },
      questionRules: {
        cur_answer_id: [
          { required: true, trigger: 'blur', message: '请选择' },
        ],
        parent_answer_id: [
          { required: true, trigger: 'blur', message: '请选择' },
        ],
        qu_type: [
          { required: true, trigger: 'blur', message: '请输入问卷项类型' },
        ],
        subject: [
          { required: true, trigger: 'blur', message: '请输入问卷项标题' },
        ],
      },
      parentAnswerList: [],
      answerList: [],
      homeimgDialog: false,
      isGetImage: false,
      uploadIndex: 0
    }
  },
  methods: {
    async getList () {
      if (this.curlevel == 1) return
      // const tpl_id = this.$route.query.id
      const tpl_id = this.tplId
      const resp =  await getQuestionnaireLevelQuestionList({ tpl_id, question_level: Number(this.curlevel) - 1, qu_type: [1,2] })
      const data = resp.data.data
      this.parentAnswerList = data
      console.log(this.form)
      if (this.form.parent_answer_id && this.form.parent_answer_info) {
        this.onChange(this.form.parent_answer_info.tpl_list_id, 'init')
      }
    },
    addQuItem() {
      if (!this.questionnaireForm.answer) {
        this.questionnaireForm.answer = []
      }
      let item = { content: '' }
      this.questionnaireForm.answer.push(item)
    },
    removeQuItem(index) {
      this.questionnaireForm.answer.splice(index, 1)
    },
    saveChange (formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          const params = this.questionnaireForm
          this.$emit('saveChange', params)
          this.closeDialog()
          // this.dialogVisible = false
        }
      })
    },
    closeDialog () {
      this.questionnaireForm = {
        cur_answer_id: '',
        parent_answer_id: '',
        qu_type: '1',
        subject: '',
        answer: [{ content: '', remark: '', image: '' }]
      }
      this.$emit('closeChange')
    },
    onChange (value, isInint) {
      if (!isInint) {
        this.questionnaireForm.parent_answer_id = ''
        this.answerList = []
      }
      if (value) {
        const filterList = this.parentAnswerList.find(item => item.id == value) || []
        this.answerList = filterList.answer
      }
    },
    handleHomeImgChange(index) {
      this.homeimgDialog = true
      this.isGetImage = true
      this.uploadIndex = index
    },
    pickImg(data) {
      this.questionnaireForm.answer[this.uploadIndex].image = data.url
      this.homeimgDialog = false
    },
    closeHomeImgDialog() {
      this.homeimgDialog = false
      this.uploadIndex = 0
    },
    onCleanImg (index) {
      const form = JSON.parse(JSON.stringify(this.questionnaireForm))
      form.answer[index].image = ''
      this.questionnaireForm = form
    }
  }
}
</script>

<style scoped lang="scss">
.comp-first-dialog {
  .question-option {
    display: flex;
    position: relative;
    &:first-child {
      margin-top: 0px;
    }
    margin-top: 10px;
  }
  .setting-remove, .setting-add {
    font-size: 18px;
    margin-left: 10px;
  }
  .setting-remove {
    color: #f56c6c;
  }
  .question-item {
    margin-bottom: 18px !important;
  }
  .comp-upload-box {
    border: 1px dashed #c0ccda;
    border-radius: 6px;
    cursor: pointer;
    overflow: hidden;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    .avatar, .avatar-uploader-icon {
      width: 40px;
      height: 40px;
      display: block
    }
    .avatar-uploader-icon {
      &::before {
        font-size: 20px;
      }
    }
  }
  .icon-delete {
    position: absolute;
    right: 80px;
    top: -7px;
    font-size: 20px;
    cursor: pointer;
  }
}
</style>
