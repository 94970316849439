<template>
  <div class="wxapp-customMessagepage">
    <div v-if="$route.path.indexOf('_operation') === -1">
      <SpFinder
        ref="finder"
        :url="page_list"
        noSelection
        :setting="pageSetting"
        fixed-row-action
        :split-count="10"
        :hooks="{ beforeSearch }"
        rowActionsWidth="130px"
        rowActionsAlign="left"
      >
      </SpFinder>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import { pageSetting } from './config/index'
import { page_list } from './api'

export default {
  components: {},
  computed: {
    pageSetting() {
      return pageSetting(this)
    }
  },
  data () {
    return {
      page_list

    }
  },
  async mounted () {
  },
  methods: {
    refresh() {
      this.$refs.finder.refresh()
    },
    handleClickTab() {
      this.refresh()
    },
    beforeSearch(params) {
      return {
        ...params
      }
    }
  },
}
</script>

<style scoped lang="scss">
</style>
