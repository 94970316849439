<template>
  <div>
    <section class="section section-white content-padded view-flex view-flex-middle">
      <div class="view-flex view-flex-middle view-flex-item">
        <div>主题：</div>
        <div>
          <div class="theme-item">
            <div style="background: #f3b600"></div>
            <div style="background: #fff"></div>
          </div>
        </div>
      </div>
      <div class="content-right">
        <el-button type="primary" @click="save">保存</el-button>
      </div>
    </section>
    <section class="section-white view-warp">
      <div id="submitContent"></div>
      <div class="pages-view">
        <div v-for="(item, index) in pages" :class="currentPage === index?'page active':'page'" @click="tabPage(index)">
          <img alt="" :src="item.imgurl" height="569" width="320">
        </div>
      </div>
      <div class="template-view" v-if="currentPage === 0">
        <div class="template" :style="'background:'+ theme.bodyColor">
          <div class="template-header" :style="'background:'+ theme.bgColor +' url('+ headerBg +') no-repeat 0 4px; background-size: 100% auto;'">
            <div class="template-title" :style="'color:'+ theme.fontColor">标题</div>
          </div>
          <el-carousel height="160px" :autoplay="false">
            <el-carousel-item v-for="(item, index) in form.bannerPicList" :key="index" v-if="item.url"  :style="'background: url('+ wximageurl + item.url +') no-repeat center; background-size: cover'">
            </el-carousel-item>
          </el-carousel>
          <div class="view-flex view-flex-middle activity">
            <img src="../../../../../static/img/gift.png" class="activity-icon" />
            <div class="view-flex-item">
              <div class="activity-title">新手福利</div>
              <div>新入会员立即领取88元红包</div>
            </div>
            <div class="activity-btn">领取</div>
          </div>
          <div class="view-flex view-flex-middle nav">
            <div class="view-flex view-flex-vertical view-flex-middle view-flex-item nav-item"><img class="nav-icon" src="../../../../../static/img/activity.png"/>热门活动</div>
            <div class="view-flex view-flex-vertical view-flex-middle view-flex-item nav-item"><img class="nav-icon" src="../../../../../static/img/reseveration.png"/>预约</div>
            <div class="view-flex view-flex-vertical view-flex-middle view-flex-item nav-item"><img class="nav-icon" src="../../../../../static/img/buy.png"/>买单</div>
          </div>
          <div class="goods-list">
            <div class="list-title"><text class="title-txt">精选推荐</text></div>
            <div v-for="item in form.hotGoods" class="goods view-flex view-flex-stretch">
              <div class="goods-avatar" :style="'background:'+ theme.bgColor +' url('+ wximageurl + item.url +') center / cover no-repeat;'"></div>
              <div class="goods-caption view-flex-item view-flex view-flex-vertical view-flex-justify">
                <div class="goods-name">{{item.item_name}}</div>
                <div class="goods-key">{{item.brief}}</div>
                <div class="amount view-flex view-flex-bottom">
                  <div class="mark"><text class="cur">¥</text> {{item.price/100}}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="store">
            <div class="store-more store-label">全部门店</div>
            <div class="content-center">
              <div class="store-name">桂林路店</div>
              <div class="store-desc">上海市徐汇区桂林路396号2号楼</div>
            </div>
            <div class="view-flex view-flex-center store-contact">
              <div class="store-contact-item">
                <img class="store-icon" src="../../../../../static/img/store_location.png"/>
                <div>1220 km</div>
              </div>
              <div class="store-contact-item">
                <img class="store-icon" src="../../../../../static/img/store_phone.png"/>
                <div>咨询</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="setting-view" v-if="currentPage === 0">
        <div class="section">
          <div class="section-header with-border with-bg">轮播图配置</div>
          <div class="section-body">
            <div class="setting-item slider" v-for="(item, index) in form.bannerPicList">
              <div class="setting-remove" @click="handleDelBanner(index)"><i class="iconfont icon-trash-alt1"></i></div>
              <div>
                <img v-if="item.url" :src="wximageurl + item.url" class="banner-uploader" @click="handleImgChange(index, 'banner')"/>
                <div class="banner-uploader" v-else @click="handleImgChange(index, 'banner')">
                  <div class="content-center">
                    <i class="iconfont icon-camera"></i><br>
                    上传图片
                  </div>
                </div>
              </div>
              <div class="goods-select" @click="handleGoodsChange(index, 'banner')">
                <div v-if="item.item_id">
                  <div class="thumb" :style="'background: url(' + wximageurl + item.url + ') no-repeat center; background-size: cover;'"></div>{{item.item_name}}
                </div>
                <div v-else class="content-center"><i class="iconfont icon-link" @click="handleGoodsChange(index, 'banner')"></i>绑定商品</div>
              </div>
            </div>
            <div class="content-center">
              <div class="frm-tips">只能上传jpg/png文件，且不超过2M （建议尺寸：375px * 200px）</div>
              <el-button type="primary" class="el-icon-plus banner-button-uploader" @click="handleAddBanner">添加轮播图</el-button>
            </div>
          </div>
        </div>
        <div class="section">
          <div class="section-header with-border with-bg">热门商品配置</div>
          <div class="section-body">
            <div class="setting-item hot" v-for="(item, index) in form.hotGoods">
              <div class="goods-select" @click="handleGoodsChange(index, 'hotGoods')">
                <div v-if="item.item_id">
                  <div class="thumb" :style="'background: url(' + wximageurl + item.url + ') no-repeat center; background-size: cover;'"></div>{{item.item_name}}
                </div>
                <div v-else class="content-center"><i class="iconfont icon-link"></i>绑定商品</div>
              </div>
            </div>
          </div>
        </div>
        <imgPicker :dialog-visible="imgDialog" :sc-status="isGetImage" @chooseImg="pickImg" @closeImgDialog="closeImgDialog"></imgPicker>
      </div>
      <div class="artical-setting-view" v-if="currentPage === 1">
        <div class="msgSender_content" v-if="data.news">
          <div class="msg msg-item has-first-cover" :class="data.news.content.news_item.length > 1 ? 'multi' : 'single'">
            <div class="msg-content">
              <div class="msg-info"><span>更新于 {{data.news.content.update_time | datetime}}</span></div>
              <div class="sub-msg-item" :class="data.news.content.news_item.length > 1 ? 'coverMsgItem' : ''">
                <h4 class="msg-title">
                  <a>{{data.news.content.news_item[0].title}}</a>
                </h4>
                <div class="msg-thumb-wrap" :style="{backgroundImage: 'url('+ wximageurl + data.news.content.news_item[0].thumb_url +')'}">
                </div>
                <a :href="data.news.content.news_item[0].url" class="edit-mask preview-mask">
                  <div class="edit-mask-content">
                    预览文章
                  </div>
                </a>
                <p class="msg-desc" v-if="data.news.content.news_item.length === 1">{{data.news.content.news_item[0].digest}}</p>
              </div>
              <div v-for="(item, index) in data.news.content.news_item" v-if="index !== 0" :key="">
                <div class="article-msg-item has-cover clearfix" v-if="data.news.content.news_item.length > 1">
                  <div class="msg-thumb-wrap" :style="{backgroundImage: 'url('+ wximageurl + item.thumb_url +')'}"></div>
                  <h4 class="msg-title">
                    <a>{{item.title}}</a>
                  </h4>
                  <a :href="item.url" class="edit-mask preview-mask">
                    <div class="edit-mask-content">
                      预览文章
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="">
          <div class="appmsg_preview_area">
            <div class="appmsg_preview_container">
              <div class="appmsg_container_hd">
                <h4 class="appmsg_container_title">小程序营销文章配置</h4>
              </div>
              <div class="appmsg_container_bd">
                <div class="appmsg multi has_first_cover editing">
                  <div id="js_appmsg_preview" class="appmsg_content">
                    <template v-for="(item, index) in articals">
                      <div v-if="index === 0" class="js_appmsg_item appmsg_item_wrp" :class="id === index?'current':''" @click="handleClick(index)">
                        <div class="first_appmsg_item" title="第一篇图文">
                          <div class="cover_appmsg_item">
                            <h4 class="appmsg_title">
                              <a class="js_appmsg_title" href="javascript:void(0);" onclick="return false;">
                                <template v-if="articals[0].title != ''">
                                  {{articals[0].title}}
                                </template>
                                <template v-else>
                                  标题
                                </template>
                              </a>
                            </h4>
                            <div class="appmsg_thumb_wrp" :style="{ backgroundImage: 'url(' + (item.thumb_url ? wximageurl + item.thumb_url : '') + ')' }">
                              <div class="appmsg_thumb default" v-if="!item.thumb_url">
                                <i class="icon_appmsg_thumb el-icon-picture"></i>
                              </div>
                            </div>
                          </div>
                          <div class="appmsg_edit_mask">
                            <a onclick="return false;" class="icon20_common iconfont icon-arrow-down sort_down_white" @click.prevent="sortdown(index)" title="下移"></a>
                          </div>
                        </div>
                      </div>
                      <div v-else class="js_appmsg_item appmsg_item_wrp" :class="id === index?'current':''" @click="handleClick(index)" >
                        <div class="appmsg_item has_cover">
                          <div class="appmsg_thumb_wrp" :style="{ backgroundImage: 'url(' + (item.thumb_url ? wximageurl + item.thumb_url : '') + ')' }">
                            <div class="appmsg_thumb default" v-if="!item.thumb_url">
                              <i class="icon_appmsg_thumb_small el-icon-picture"></i>
                            </div>
                          </div>
                          <h4 class="appmsg_title js_appmsg_title">
                            <template v-if="item.title != ''">
                              {{item.title}}
                            </template>
                            <template v-else>
                              标题
                            </template>
                          </h4>
                          <div class="appmsg_edit_mask">
                            <a class="icon20_common sort_up_white iconfont icon-arrow-up" @click.prevent="sortup(index)" title="上移"></a>
                            <a v-if="index !== (articals.length-1)" class="icon20_common sort_down_white iconfont icon-arrow-down" @click.prevent="sortdown(index)" title="下移"></a>
                            <el-popover placement="bottom" width="220" trigger="click" v-model="item.popVisible">
                              <p class="content-padded content-center" style="font-size: 14px">确定删除这篇文章？</p>
                              <div class="content-center">
                                <el-button @click="item.popVisible = false">取消</el-button>
                                <el-button type="primary" @click="remove(index)">确定</el-button>
                              </div>
                              <a v-if="!isEditting" class="icon20_common del_media_white el-icon-delete" slot="reference" title="删除"></a>
                            </el-popover>
                          </div>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
                <div v-if="!isEditting" @click="addmsg" title="添加一篇图文" class="create_access_primary appmsg_add">
                  <i class="icon35_common el-icon-plus"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="appmsg_input_area">
            <template v-for="(item, index) in articals" v-if="id === index">
              <el-form ref="item" :model="item" label-position="top" label-width="80px">
                <div class="content-padded">
                  <el-form-item label="标题" style="height: 80px">
                    <el-input v-model="item.title" placeholder="请输入标题"></el-input>
                  </el-form-item>
                  <el-form-item>
                    <vue-html5-editor :content="item.viewcontent" :id="index" ref="editor" @change="updateContent" :height="360"></vue-html5-editor>
                  </el-form-item>
                </div>
                <div class="content-padded appmsg_edit_highlight_area" style="height: 420px">
                  <h3 class="header-title">发布样式编辑</h3>
                  <div class="header_tips">封面小图片 <span class="form-text-tip">建议尺寸：200像素 * 200像素</span></div>
                  <el-form-item>
                    <div>
                      <el-button @click="addThumbPreview">从图片库选择</el-button>
                    </div>
                    <div class="cover_preview" v-if="item.thumb_url " :style="{ backgroundImage: 'url(' + (item.thumb_url ? wximageurl + item.thumb_url : '') + ')' }"></div>
                  </el-form-item>
                </div>
              </el-form>
            </template>
          </div>
          <div class="appmsg_tpl_area">
            <div class="appmsg_tpl_container">
              <div class="appmsg_container_hd">
                <h4 class="appmsg_container_title">多媒体</h4>
              </div>
              <div class="appmsg_container_bd">
                <ul id="js_media_list" class="tpl_list">
                  <li class="tpl_item img" @click="addImgPreview" style="">
                    <i class="iconfont icon-image"></i>图片
                  </li>
                </ul>
                <imgPicker :dialog-visible="thumbDialog" :sc-status="isGetThumb" @chooseImg="pickThumb" @closeImgDialog="closeThumbDialog"></imgPicker>
                <imgPicker :dialog-visible="articalImgDialog" :sc-status="isGetArticalImage" @chooseImg="pickArticalImg" @closeImgDialog="closeArticalImgDialog"></imgPicker>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <el-dialog title="选择商品" class="select-goods-box" :visible.sync="selectGoodsVisible">
      <template>
        <el-transfer
          v-model="selectGoods"
          :titles="['商品列表', '已选中']"
          :button-texts="['移除选择', '添加选择']"
          @change="handleSelectChange"
          :data="goodsList">
          <div class="transfer-footer" slot="left-footer">
            <el-pagination v-if="total_count > params.pageSize"
              small
              layout="prev, pager, next"
              @current-change="handleSelectGoodsChange"
              :total="total_count"
              :page-size="params.pageSize" >
            </el-pagination>
          </div>
        </el-transfer>
        <div class="section-footer with-border content-center" style="width: 100%">
          <el-button type="primary" @click="handleGoodsDialog">确定</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
  var img = require('../../../../../static/img/demo-img.jpg')
  var logo = require('../../../../../static/img/logo_ico.svg')
  var header = require('../../../../../static/img/topbar_white.png')

  import imgPicker from '../../../../components/imageselect'
  import { getItemsList } from '../../../../api/goods'
  import { setPageParams, getParamByTempName, updateParamsById } from '../../../../api/wxa'

  export default {
    components: {
      imgPicker
    },
    data() {
      return {
        currentPage: 0,
        pages: [
          {
            imgurl: '../../../../../static/img/wxapp_member_universal.jpg'
          },
          {
            imgurl: '../../../../../static/img/wxapp_member_universal01.jpg'
          }
        ],
        demoimg: img,
        logo: logo,
        headerBg: header,
        theme: {
          bgColor: '#242526',
          fontColor: '#fff',
          bodyColor: '#fff'
        },
        data: {
          hotImg: '../../../../../static/img/pro_img.jpg',
          hotImgSc: '../../../../../static/img/pro_img_01.jpg',
          sliderImgs: [
            {
              pic: '../../../../../static/img/pro_img.jpg',
              goodsId: ''
            },
            {
              pic: '../../../../../static/img/pro_img_01.jpg',
              goodsId: ''
            }
          ],
          news: null
        },
        form: {
          bannerPicList: [],
          hotGoods: [
            {url: '', item_id: '', item_name: '', item_desc: '', price: 0 },
            {url: '', item_id: '', item_name: '', item_desc: '', price: 0 }
          ],
        },
        articals: [{
          title: '',
          content: '',
          viewcontent: '',
          thumb_url: '',
          popVisible: false
        }],
        id: 0,
        isEditting: false,
        selectGoods: [],
        selectItem: {
          item_id: 0,
          item_name: ''
        },
        goodsList: [],
        params: {
          page: 1,
          pageSize: 20
        },
        total_count: 0,
        isGetImage: false,
        isGetArticalImage: false,
        imgDialog: false,
        articalImgDialog: false,
        thumbDialog: false,
        isGetThumb: false,
        isGetNews: false,
        newsVisible: false,
        selectGoodsVisible: false,
        currentType: 'banner',
        currentIndex: 0,
        bannerPicListSetId: 0,
        hotGoodsSetId: 0,
        articleSetId: 0
      }
    },
    methods: {
      tabPage (n) {
        this.currentPage = n
      },
      handleAddBanner () {
        if (!this.form.bannerPicList) {
          this.form.bannerPicList = []
        }
        let item = { url: '', item_id: 0, item_name: '' }
        if(this.form.bannerPicList.length > 4) {
          this.$message({
            message: '轮播图最多添加5个图片',
            type: 'error',
            duration: 5 * 1000
          })
        } else {
          this.form.bannerPicList.push(item)
        }
      },
      handleGoodsChange (index, type_name) {
        this.currentIndex= index
        this.currentType = type_name
        this.selectGoodsVisible= true
        this.selectGoods = []
        if(this.currentType == 'banner') {
          if(this.form.bannerPicList[this.currentIndex].item_id) {
            this.selectGoods = [this.form.bannerPicList[this.currentIndex].item_id]
          }
        } else {
          if(this.form.hotGoods[this.currentIndex].item_id) {
            this.selectGoods = [this.form.hotGoods[this.currentIndex].item_id]
          }
        }
        this.getGoodsList()
      },
      handleDelBanner (index) {
        this.form.bannerPicList.splice(index, 1)
      },
      handleImgChange (index, type_name) {
        this.imgDialog = true
        this.isGetImage = true
        this.currentType = type_name
        this.currentIndex = index
      },
      pickImg (data) {
        if(this.currentType == 'banner') {
          this.form.bannerPicList[this.currentIndex].url = data.url
        }
        this.imgDialog = false
      },
      closeImgDialog () {
        this.imgDialog = false
      },
      closeArticalImgDialog () {
        this.articalImgDialog = false
      },
      //选择商品分页
      handleSelectGoodsChange (val) {
        this.params.page = val
        this.goodsList.forEach(row => {
          //如果选中
          let index = this.selectGoods.indexOf(row.key)
          if(index != -1) {
            if(this.currentType == 'banner') {
              this.form.bannerPicList[this.currentIndex].item_id = row.key
              this.form.bannerPicList[this.currentIndex].item_name = row.label
            } else {
              this.form.hotGoods[this.currentIndex].item_id = row.key
              this.form.hotGoods[this.currentIndex].item_name = row.label
              this.form.hotGoods[this.currentIndex].url = row.imgurl
              this.form.hotGoods[this.currentIndex].brief = row.brief
              this.form.hotGoods[this.currentIndex].price = row.price
            }
          }
        })
        this.getGoodsList()
      },
      //选择商品触发事件
      handleSelectChange (value, direction, movedKeys) {
        if(value.length > 1) {
          this.$message({
            message: '最多选择一个商品',
            type: 'error',
            duration: 5 * 1000
          })
        }
        this.selectGoods.splice(1)
      },
      //选择商品确认
      handleGoodsDialog () {
        this.selectGoodsVisible= false
        if(this.selectGoods.length > 0) {
          this.goodsList.forEach(row => {
            //如果选中
            let index = this.selectGoods.indexOf(row.key)
            if(index != -1) {
              if(this.currentType == 'banner') {
                this.form.bannerPicList[this.currentIndex].item_id = row.key
                this.form.bannerPicList[this.currentIndex].item_name = row.label
              } else {
                this.form.hotGoods[this.currentIndex].item_id = row.key
                this.form.hotGoods[this.currentIndex].item_name = row.label
                this.form.hotGoods[this.currentIndex].url = row.imgurl
                this.form.hotGoods[this.currentIndex].brief = row.brief
                this.form.hotGoods[this.currentIndex].price = row.price
              }
            }
          })
        } else {
          if(this.currentType == 'banner') {
            this.form.bannerPicList[this.currentIndex].item_id = ''
            this.form.bannerPicList[this.currentIndex].item_name = ''
          } else {
            this.form.hotGoods[this.currentIndex].item_id = ''
            this.form.hotGoods[this.currentIndex].item_name = ''
            this.form.hotGoods[this.currentIndex].url = ''
            this.form.hotGoods[this.currentIndex].brief = ''
            this.form.hotGoods[this.currentIndex].price = 0
          }
        }
        this.selectGoods = []
      },
      addImgPreview () {
        this.articalImgDialog = true
        this.isGetArticalImage = true
      },
      pickArticalImg (data) {
        if (data && data.url !== '') {
          this.articalImgDialog = false
          var index = this.$refs.editor[0].$el.id
          var loc = this.$refs.editor[0]
          var img = new Image()
          img.src = this.wximageurl + data.url
          if (loc.range) {
            loc.range.insertNode(img)
          } else {
            loc.$refs.content.appendChild(img)
            loc.focus()
            loc.restoreSelection()
          }
          this.articals[index].viewcontent = loc.$refs.content.innerHTML
        }
      },
      addThumbPreview () {
        this.thumbDialog = true
        this.isGetThumb = true
      },
      pickThumb (data) {
        if (data && data.url !== '') {
          this.thumbDialog = false
          this.articals[this.id].thumb_url = data.url
        }
      },
      closeThumbDialog () {
        this.thumbDialog = false
      },
      handleClick (index) {
        this.id = +index
        if(this.isEditting){
          this.articals[this.id].viewcontent = this.articals[this.id].content.replace(/<img data-src="(.*?)/g, '<img src="' + process.env.WXIMG_URL + '"')
          var loc = this.$refs.editor[0].$refs.content
        }
      },
      addmsg () {
        if (this.articals.length > 3) {
          this.$message({message: '最多添加4条文章', type: 'error'})
          return
        }
        let baseMsgData = {
          title: '',
          content: '',
          viewcontent: '',
          thumb_url: '',
          popVisible: false
        }
        this.articals.push(baseMsgData)
      },
      sortup (index) {
        this.articals[index - 1] = this.articals.splice(index, 1, this.articals[index - 1])[0]
      },
      sortdown (index) {
        this.articals[index + 1] = this.articals.splice(index, 1, this.articals[index + 1])[0]
      },
      remove (index) {
        this.id = +(index - 1)
        this.articals.splice(index, 1)
        this.popVisible = false
      },
      updateContent (data) {
        let index = this.$refs.editor[0].$el.id
        this.articals[index].viewcontent = data
      },
      getGoodsList () {
        getItemsList(this.params).then(response => {
          this.goodsList = []
          response.data.data.list.forEach(row => {
            let itemid = ''
            if(this.currentType == 'banner') {
              itemid = this.form.bannerPicList[this.currentIndex].item_id
            } else {
              itemid = this.form.hotGoods[this.currentIndex].item_id
            }
            if(row.itemId != itemid ) {
              this.goodsList.push({
                key: row.itemId,
                label: row.itemName,
                imgurl: row.pics[0],
                brief: row.brief,
                price: row.price,
                mktprice: row.market_price
              })
            }
          })
          if(this.currentType == 'banner') {
            if(this.form.bannerPicList[this.currentIndex].item_id) {
              this.goodsList.push({
                key: this.form.bannerPicList[this.currentIndex].item_id,
                label: this.form.bannerPicList[this.currentIndex].item_name
              })
            }
          } else {
            if(this.form.hotGoods[this.currentIndex].item_id) {
              this.goodsList.push({
                key: this.form.hotGoods[this.currentIndex].item_id,
                label: this.form.hotGoods[this.currentIndex].item_name,
                imgurl: this.form.hotGoods[this.currentIndex].url,
                brief: this.form.hotGoods[this.currentIndex].brief,
                price: this.form.hotGoods[this.currentIndex].price
              })
            }
          }
          this.total_count = response.data.data.total_count
        })
      },
      save () {
        if(this.bannerPicListSetId) {
          let bannerParams = { params : this.form.bannerPicList, template_name: 'yykuniversal', name: 'banner', page_name: 'index', id: this.bannerPicListSetId }
          updateParamsById (bannerParams).then(response => {
            this.$message({
              message: '保存成功',
              type: 'success',
              duration: 5 * 1000
            })
          })
        } else {
          let bannerParams = { params : this.form.bannerPicList, template_name: 'yykuniversal', name: 'banner', page_name: 'index' }
          setPageParams (bannerParams).then(response => {
            this.$message({
              message: '保存成功',
              type: 'success',
              duration: 5 * 1000
            })
          })
        }

        if (this.hotGoodsSetId) {
          let hotGoodsParams = { params : this.form.hotGoods, template_name: 'yykuniversal', name: 'hotGoods', page_name: 'index', id: this.hotGoodsSetId }
          updateParamsById (hotGoodsParams).then(response => {
            this.$message({
              message: '保存成功',
              type: 'success',
              duration: 5 * 1000
            })
          })
        } else {
          let hotGoodsParams = { params : this.form.hotGoods, template_name: 'yykuniversal', name: 'hotGoods', page_name: 'index' }
          setPageParams (hotGoodsParams).then(response => {
            this.$message({
              message: '保存成功',
              type: 'success',
              duration: 5 * 1000
            })
          })
        }

        var reg = new RegExp(this.wximageurl,'g')
        for (var i = 0; i < this.articals.length; i++) {
          this.articals[i].content = this.articals[i].viewcontent.replace(reg,'')
          var submitContent = document.getElementById('submitContent')
          submitContent.innerHTML = this.articals[i].content
          this.articals[i].content = submitContent.innerHTML
        }
        var that = this
        let param = {}
        if (that.articleSetId) {
          param = { params : that.articals, name: 'marketing_articles', id: that.articleSetId }
          updateParamsById (param).then(response => {
            this.$message({
              message: '保存成功',
              type: 'success',
              duration: 5 * 1000
            })
          })
        } else {
          param = { params : that.articals, template_name: 'yykuniversal', name: 'marketing_articles', page_name: 'index' }
          setPageParams (param).then(response => {
            this.$message({
              message: '保存成功',
              type: 'success',
              duration: 5 * 1000
            })
          })
        }
      }
    },
    mounted () {
      let filter = {template_name: 'yykuniversal', name: 'banner', page_name: 'index'}
      getParamByTempName(filter).then(res => {
        if(res.data.data[0]) {
          this.form.bannerPicList = res.data.data[0].params
          this.bannerPicListSetId = res.data.data[0].id
        }
      })
      let hotGoodsFilter = {template_name: 'yykuniversal', name: 'hotGoods', page_name: 'index'}
      getParamByTempName(hotGoodsFilter).then(res => {
        if(res.data.data[0]) {
          this.form.hotGoods = res.data.data[0].params
          this.hotGoodsSetId = res.data.data[0].id
        }
      })

      let articalFilter = {template_name: 'yykuniversal', name: 'marketing_articles', page_name: 'index'}
      getParamByTempName(articalFilter).then(response => {
        if (response.data.data.length > 0 ) {
          var data = response.data.data[0].params
          for (var i = 0; i < data.length; i++) {
            if(data[i].popVisible === 'false') {
              data[i].popVisible = false
            }
          };
          this.articals = data
          this.articleSetId = response.data.data[0].id
          this.articals[this.id].viewcontent = this.articals[this.id].content.replace(/<img data-src="(.*?)/g, '<img src="' + process.env.WXIMG_URL + '"')
        }
      })
    }
  }
</script>

<style scoped lang="scss">
  .theme-item {
    float: left;
    display: flex;
    margin-left: 15px;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    cursor: pointer;
    box-shadow: 0 0 2px rgba(0,0,0,0.3);
    vertical-align: middle;
    & > div {
      flex: 1;
      height: 30px;
      &:first-child {
        border-radius: 30px 0 0 30px;
      }
      &:last-child {
        border-radius: 0 30px 30px 0;
      }
    }
  }
  .view-warp {
    position: relative;
    min-height: 600px;
  }
  .pages-view {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 220px;
    padding: 30px 0;
    overflow-x: hidden;
    overflow-y: auto;
    border-right: 1px solid #efefef;
    .page {
      width: 150px;
      height: 150px;
      margin-left: auto;
      margin-right: auto;
      box-shadow: 0 0 1px rgba(0,0,0,0.3);
      margin-bottom: 20px;
      border-radius: 10px;
      text-align: center;
      cursor: pointer;
      &.active {
        box-shadow: 0 0 3px rgba(255,29,0,1);
      }
      img {
        display: inline-block;
        width: auto;
        height: 100%;
      }
    }
  }
  .template-view {
    margin-left: 220px;
    margin-right: 420px;
    padding: 50px 0;
    .template {
      width: 320px;
      margin: 0 auto;
      box-shadow: 0 0 1px rgba(0,0,0,0.3);
      .template-header {
        height: 52px;
        padding-top: 15px;
      }
      .template-title {
        width: 100px;
        height: 36px;
        line-height: 36px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
      }
      .section {
        margin-bottom: 10px;
      }
    }
  }
  .setting-view {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 420px;
    overflow-y: auto;
    overflow-x: hidden;
    border-left: 1px solid #efefef;
    .app-list {
      border-bottom: 1px solid #efefef;
      .app {
        width: 32px;
        height: 32px;
        line-height: 32px;
        margin-right: 5px;
        border-radius: 50%;
        background: #ff7000;
        color: #fff;
        text-align: center;
        font-size: 20px;
        &.nonactivated {
          background: #efefef;
          color: #cacaca;
        }
      }
    }
    .setting-item {
      position: relative;
      margin-bottom: 30px;
      .setting-remove {
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
        color: #ff5000;
      }
    }
    .banner-uploader {
      display: flex;
      align-items: center;
      justify-content: center;
      i {
        font-size: 50px;

      }
      color: #8c939d;
      width: 350px;
      height: 175px;
      color: #dadadd;
      border: 1px dashed #ddd;
      margin-bottom: 10px;
      cursor: pointer;
    }
    .goods-select {
      display: flex;
      align-items: center;
      justify-content: center;
      i {
        font-size: 24px;
        margin-right: 10px;
        color: #dadadd;
      }
      color: #8c939d;
      width: 350px;
      height: 60px;
      padding: 10px;
      border: 1px dashed #ddd;
      cursor: pointer;
      .thumb {
        display: inline-block;
        width: 40px;
        height: 40px;
        vertical-align: middle;
        margin-right: 10px;
      }
    }
    .el-transfer {
      text-align: center;
      padding: 10px 0 20px;
    }
    .slider {
      .banner-uploader {
        width: 350px;
        height: 175px;
      }
      .goods-uploader {
        width: 350px;
        height: 60px;
      }
    }
    .hot {
      .banner-uploader {
        width: 150px;
        height: 175px;
      }
      .goods-uploader {
        height: 60px;
      }
    }
    .layout-tip {
      display: flex;
      width: 75px;
      height: 65px;
      border: 1px solid #dadadd;
      div {
        background: #efefef;
      }
      .layout-item {
        flex: 1;
      }
      .layout-left {
        width: 45px;
        border-right: 1px solid #dadadd;
      }
      .layout-right {
        display: flex;
        flex-direction: column;
      }
      .layout-top {
        height: 50px;
        border-bottom: 1px solid #dadadd;
      }
    }
  }

  .artical-setting-view {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 220px;
    overflow-y: auto;
    overflow-x: hidden;
    .app-list {
      border-bottom: 1px solid #efefef;
      .app {
        width: 32px;
        height: 32px;
        line-height: 32px;
        margin-right: 5px;
        border-radius: 50%;
        background: #ff7000;
        color: #fff;
        text-align: center;
        font-size: 20px;
        &.nonactivated {
          background: #efefef;
          color: #cacaca;
        }
      }
    }
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 1px;
      background: #efefef;
      content: "";
    }
  }

  .el-carousel__item h3 {
    color: #475669;
    font-size: 18px;
    opacity: 0.75;
    line-height: 160px;
    margin: 0;
    text-align: center;
  }
  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }
  .el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
  }

  .mark {
    font-size: 20px;
    color: #f3b600;
  }
  .activity {
    height: 75px;
    margin: 20px 20px;
    padding: 12px;
    background: #f3b600;
    box-shadow: 0 25px 10px -10px rgba(246,232,76,0.3);
    color: #fff;
    font-size: 13px;
    .activity-title {
      font-size: 15px;
    }
    .activity-btn {
      padding: 0 10px;
      line-height: 24px;
      border-radius: 30px;
      font-size: 12px;
      background: #fff;
      color: #f3b600;
    }
    .activity-icon {
      width: 35px;
      height: 35px;
      margin-right: 10px;
    }
  }
  .nav {
    position: relative;
    padding: 20px 25px;
    .nav-item {
      position: relative;
      padding: 0;
      font-size: 14px;
      color: #666;
      background-color: transparent;
      border: 0;
      border-radius: 0;
      line-height: 1.5;
      &::after {
        content: none;
      }
    }
    .nav-icon {
      width: 45px;
      height: 45px;
    }
  }
  .hot {
    margin: 0 15px;
    .hot-title {
      position: relative;
      font-size: 15px;
      color: #999;
      text-align: center;
      .title-txt {
        padding: 15px;
        display: inline-block;
        position: relative;
        background: #fff;
        z-index: 10;
      }
    }
    .hot-title::after {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 190px;
      height: 1px;
      transform: translateX(-50%);
      background: #efefef;
      z-index: -1;
      content: "";
    }
    .hot-item-01 {
      position: relative;
      width: 33%;
      .hot-item-name {
        top: 0;
      }
      .hot-item-rate {
        top: 0;
      }
    }
    .hot-item-02 {
      position: relative;
      width: 34%;
      padding-top: 30px;
      .hot-item-name {
        bottom: 0;
        right: 0;
      }
      .hot-item-rate {
        bottom: 0;
      }
    }
    .hot-item-03 {
      position: relative;
      width: 33%;
      .hot-item-name {
        top: 0;
      }
      .hot-item-rate {
        top: 0;
      }
    }
    .hot-item-name {
      position: absolute;
      left: 0;
      padding: 10px 13px;
      font-size: 13px;
      background: #ff4623;
      color: #fff;
    }
    .hot-item-rate {
      position: absolute;
      left: 0;
      padding: 10px 13px;
      font-size: 12px;
      color: #ff4623;
      .rate-icon {
        width: 23px;
        height: 23px;
      }
    }
    .hot-img {
      display: block;
      width: 100%;
      height: 250px;
    }
  }
  .goods-list {
    position: relative;
    padding: 0 20px 20px 20px;
    &::after {
      position: absolute;
      left: 25%;
      right: 25%;
      bottom: 0;
      height: 1px;
      background-image: linear-gradient(to left, #ffffff, #efefef, #efefef, #ffffff);
      content: "";
    }
    .list-title {
      position: relative;
      font-size: 14px;
      color: #999;
      text-align: center;
      margin-bottom: 5px;
      .title-txt {
        padding: 15px;
        display: inline-block;
        position: relative;
        background: #fff;
        z-index: 10;
      }
    }
    .list-title::after {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 180px;
      height: 1px;
      transform: translateX(-50%);
      background: #efefef;
      z-index: -1;
      content: "";
    }
  }
  .goods {
    margin-bottom: 15px;
    box-shadow: 0 5px 10px rgba(0,0,0,0.2);
    .amount {
      line-height: 1;
      font-size: 14px;
      padding-top: 15px;
      .mark {
        font-size: 15px;
        line-height: 1;
        margin-right: 5px;
      }
      .text-overline {
        line-height: 1;
      }
    }
    .goods-avatar {
      width: 100px;
      height: 100px;
    }
    .goods-caption {
      padding: 10px;
    }
    .goods-name {
      font-size: 16px;
      color: #666;
      overflow : hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      line-clamp: 1;
      box-orient: vertical;
    }
    .goods-key {
      font-size: 13px;
      color: #ccc;
      overflow : hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      line-clamp: 1;
      box-orient: vertical;
    }
    .reservation-btn {
      width: 75px;
      background: #f3b600;
      border-radius: 30px;
      margin-left: 10px;
      line-height: 30px;
      font-size: 14px;
      color: #fff;
      text-align: center;
    }
  }
  .store {
    padding: 20px;
    .store-label {
      margin-bottom: 15px;
    }
    .store-name {
      font-size: 18px;
      color: #333;
      margin-bottom: 5px;
    }
    .store-desc {
      font-size: 14px;
      color: #999;
    }
    .store-contact {
      margin-top: 10px;
      margin-bottom: 10px;
      padding: 15px;
      font-size: 13px;
      color: #ff4925;
      text-align: center;
      .store-contact-item {
        padding: 0 20px;
      }
    }
    .store-more {
      display: inline-block;
      padding: 0 10px;
      border-radius: 20px;
      border: 1px solid #ff6900;
      color: #ff6900;
      font-size: 13px;
      line-height: 21px;
    }
    .store-icon {
      width: 35px;
      height: 35px;
      margin-bottom: 5px;
    }
  }
  .msgSender_content {
    width: 320px;
    margin: 0 auto;
  }
  .msg-item {
    width: 320px;
  }

  .appmsg_input_area {
    margin: 0 140px 0 251px;
    position: relative;
  }

  .appmsg_preview_area {
    position: absolute;
    left: 0;
    height: 100%;
  }
  .appmsg_preview_container {
    width: 250px;
    padding: 20px 0;
    border-right: 1px solid #e7e7eb;
    background-color: #f8f8f8;
    height: 100%;
  }
  .appmsg {
    position: relative;
    overflow: hidden;
    margin-bottom: 20px;
    border: 1px solid #e7e7eb;
    background-color: #fff;
    color: #666;
  }
  .appmsg_content {
    position: relative;
  }
  .appmsg_preview_container .appmsg {
    overflow: visible;
    margin-bottom: 0;
    padding: 0;
  }
  .appmsg_preview_container .appmsg_content {
    min-height: 134px;
    margin-top: -1px;
  }
  .multi .appmsg_content {
    padding: 0;
  }
  .appmsg_preview_container .appmsg_item_wrp {
    cursor: pointer;
  }
  .appmsg_preview_container .appmsg_item_wrp.current {
    position: relative;
    z-index: 1;
    margin-left: -1px;
    margin-right: -1px;
    margin-bottom: -1px;
  }
  .appmsg_preview_container .appmsg_item, .appmsg_preview_container .first_appmsg_item {
    padding: 10px;
  }
  .appmsg_preview_container .first_appmsg_item {
    border-top: 1px solid #e7e7eb;
    position: relative;
  }
  .appmsg_preview_container .appmsg_item_wrp.current .first_appmsg_item, .appmsg_preview_container .appmsg_item_wrp.current .appmsg_item {
    border-top-width: 0;
    padding: 9px 9px;
    border: 2px solid #43b548;
  }
  .cover_appmsg_item {
    position: relative;
    margin: 12px 14px;
  }
  .has_first_cover .cover_appmsg_item {
    margin: 12px 14px 12px;
  }
  .appmsg_preview_container .first_appmsg_item .cover_appmsg_item {
    margin: 0;
  }
  .appmsg_title {
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    line-height: 1.6;
    max-height: 88px;
    overflow: hidden;
    word-wrap: break-word;
    word-break: break-all;
    color: #222;
  }
  .appmsg_preview_container .cover_appmsg_item .appmsg_title {
    max-height: 28px;
  }
  .appmsg_preview_container .first_appmsg_item .appmsg_title {
    color: #fff;
    padding: 0 8px;
  }
  .has_first_cover .cover_appmsg_item .appmsg_title {
    font-size: 14px;
    padding-top: 0;
    line-height: 28px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,0.6)!important;
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr='#99000000',endcolorstr = '#99000000');
  }
  .appmsg_title a {
    display: block;
    color: #222;
  }
  .has_first_cover .cover_appmsg_item .appmsg_title a {
    padding: 0 8px;
    color: #fff;
  }
  .appmsg_thumb_wrp {
    height: 160px;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }
  .appmsg_preview_container .appmsg_thumb_wrp {
    height: 120px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
  .appmsg_thumb {
    width: 100%;
  }
  .appmsg_thumb.default {
    display: none;
    color: #c0c0c0;
    text-align: center;
    line-height: 160px;
    font-weight: 400;
    font-style: normal;
    background-color: #ececec;
    font-size: 14px;
  }
  .appmsg_preview_container .appmsg_thumb.default {
    line-height: 120px;
  }
  .editing .appmsg_thumb.default {
    display: block;
  }
  .icon_appmsg_thumb {
    width: 44px;
    height: 34px;
    vertical-align: middle;
    display: inline-block;
    overflow: hidden;
    font-size: 35px;
  }
  .appmsg_edit_mask {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(229,229,229,0.85)!important;
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr='#d9e5e5e5',endcolorstr = '#d9e5e5e5');
    text-align: center;
  }
  .appmsg_preview_container div.appmsg_edit_mask {
    top: auto;
    bottom: 0;
    height: 40px;
    line-height: 40px;
    background: rgba(0,0,0,0.5)!important;
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr='#80000000',endcolorstr = '#80000000');
    text-align: right;
  }
  .appmsg_preview_container .first_appmsg_item .appmsg_edit_mask {
    line-height: 188px;
  }
  .appmsg_preview_container .first_appmsg_item:hover .appmsg_edit_mask {
    display: block
  }
  .appmsg_preview_container .appmsg_item_wrp.current .appmsg_edit_mask {
    bottom: -1px;
  }
  .editing .cover_appmsg_item:hover .appmsg_edit_mask,.editing .appmsg_item:hover .appmsg_edit_mask {
    display: block
  }
  .icon20_common {
    width: 20px;
    height: 20px;
    vertical-align: middle;
    display: inline-block;
    overflow: hidden;
    line-height: 1.5;
    color: #fff;
  }
  .appmsg_edit_mask a {
    margin-left: 8px;
    margin-right: 8px;
  }
  .appmsg_preview_container div.appmsg_edit_mask .sort_up_white, .appmsg_preview_container div.appmsg_edit_mask .sort_down_white {
      float: left;
      margin-top: 10px;
  }

  .appmsg_item {
    position: relative;
    padding: 12px 14px;
  }
  .appmsg_preview_container .appmsg_item, .appmsg_preview_container .first_appmsg_item {
    padding: 10px;
  }
  .multi .appmsg_item {
    border-top: 1px solid #e7e7eb;
  }
  .multi .appmsg_item:after {
    content: "";
    display: block;
    height: 0;
    clear: both;
  }
  .multi .appmsg_item .appmsg_thumb_wrp {
    float: right;
    width: 78px;
    height: 78px;
    margin-left: 14px;
  }
  .appmsg_preview_container .appmsg_item .appmsg_thumb {
    width: 78px;
    height: 78px;
  }
  .appmsg_item .appmsg_thumb.default {
    line-height: 78px;
    font-size: 24px;
  }
  .appmsg_preview_container .appmsg_item .appmsg_thumb.default {
    line-height: 78px;
  }
  .create_access_primary {
    display: block;
    border: 2px dotted #d9dadc;
    line-height: 60px;
    text-align: center;
    margin-bottom: 20px;
  }
  .appmsg_add {
    margin: 20px 14px;
  }
  .appmsg_preview_container .appmsg_add {
    margin: 0;
    border-top-width: 0;
    line-height: 90px;
  }
  .icon35_common {
    width: 35px;
    height: 35px;
    vertical-align: middle;
    display: inline-block;
    font-size: 35px;
    color: #ccc;
  }
  .create_access_primary i {
    cursor: pointer;
    overflow: hidden;
  }

  .appmsg_tpl_area {
    position: absolute;
    top: 0;
    right: 0;
    width: 140px;
    height: 100%;
  }
  .appmsg_tpl_container {
    padding: 20px 0;
    border-left: 1px solid #e7e7eb;
    background-color: #fff;
    height: 100%;
  }
  .appmsg_container_hd {
    padding: 10px 20px 0;
  }
  .appmsg_container_title {
    font-weight: 400;
  }
  .appmsg_container_bd {
    padding: 15px;
    position: relative;
  }
  .appmsg_tpl_area .tpl_list {
    border: 1px solid #e7e7eb;
    border-top-width: 0;
  }
  .appmsg_tpl_area .tpl_item {
    border-top: 1px solid #e7e7eb;
    background-color: #fff;
    text-align: left;
    padding-left: 20px;
    line-height: 50px;
    cursor: pointer;
  }
  .appmsg_tpl_area .tpl_item .fa {
    width: 22px;
    height: 22px;
    vertical-align: middle;
    display: inline-block;
    margin-right: 1em;
    font-size: 20px;
    text-align: center;
  }

  .appmsg_edit_highlight_area {
    padding-bottom: 80px;
    background-color: #fcfcfc;
    .header-title {
      margin-bottom: 20px;
    }
    .header_tips {
      margin-bottom: 10px;
    }
    .content-num {
      color: #999;
      text-align: right;
    }
  }
  .appmsg_input_area .cover_preview {
    float: left;
    position: relative;
    text-align: center;
    color: #fff;
    margin-top: 10px;
    width: 120px;
    height: 120px;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }
  .appmsg_input_area .cover_preview.first_appmsg_cover {
    width: 188px;
  }

  .tool_area_wrp {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    background-color: #fff;
    border-top: 1px solid #d9dadc;
  }
  .tool_area_wrp .tool_area {
    width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }
  .tool_area_wrp .tool_area .tool_bar {
    padding: 11px 210px 11px 250px;
  }
  #submitContent {
    height: 0;
    overflow: hidden;
  }
</style>

<style lang="scss">
  .el-carousel__button {
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }
  .select-goods-box {
    .el-transfer-panel {
      text-align: left;
      width: 270px;
      .el-transfer-panel__body {
        height: 270px;
      }
    }
  }
</style>
