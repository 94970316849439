<template>
  <el-container>
    <el-main>
      <div v-if="$route.path.indexOf('_detail') === -1">
        <el-row type="flex">
          <el-col :offset="1">
            <span style="font-size: 20px">生成小程序URL Link</span>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :offset="1">
            <span class="frm-tips">小程序URL Link可以从微信外部打开小程序</span>
            <el-link target="_bank" type="primary" href="https://developers.weixin.qq.com/miniprogram/dev/framework/open-ability/url-link.html" :underline="false" style="font-size: 12px">查看详情</el-link>
          </el-col>
        </el-row>
        <el-row justify="center">
          <p class="frm-tips" style="font-size: 16px;text-align: center">可选择配置小程序页面路径和启动query参数，如不配置则默认跳转小程序主页面。</p>
          <p class="frm-tips" style="font-size: 16px;text-align: center">须为已发布的小程序的现有页面，路径为空时会跳转主页。</p>
        </el-row>
        <el-row type="flex" justify="center">
          <el-form label-width="120px" label-position="left">
            <el-form-item label="有效期" prop="is_expire">
              <el-col>
                <el-radio-group v-model="form.is_expire">
                  <el-radio-button :label=false>永久有效</el-radio-button>
                  <el-radio-button :label=true>到期失效</el-radio-button>
                </el-radio-group>
              </el-col>
            </el-form-item>
            <el-form-item label="失效时间" v-if="form.is_expire">
              <el-col>
                <el-date-picker
                  v-model="form.expire_time"
                  type="datetime"
                  placeholder="选择日期时间"
                  align="left"
                  prefix-icon="el-icon-date"
                  value-format="timestamp"
                  :picker-options="pickerOptions">
                </el-date-picker>
              </el-col>
            </el-form-item>
            <el-form-item label="页面路径及参数">
              <el-col>
                <el-radio-group v-model="page_type" @change="changePageType">
                  <el-radio-button label="index" value="index">首页</el-radio-button>
                  <el-radio-button label="custom" value="custom">自定义页面</el-radio-button>
                  <el-radio-button label="input" value="input">手动输入</el-radio-button>
                </el-radio-group>
              </el-col>
            </el-form-item>
            <el-form-item v-show="page_type === 'custom'">
              <el-col>
                <span v-if="!customPageVisible && selectCustomPageRow">{{selectCustomPageRow.page_name}}</span>
                <el-button @click="customPageVisible = true" size="mini" type="primary">选择自定义页面</el-button>
              </el-col>
            </el-form-item>
            <el-form-item>
              <el-col>
                <el-input v-model="form.path"
                  style="width: 400px"
                  maxlength="200"
                  show-word-limit
                  placeholder="pages/index?id=1">
                </el-input>
                <div class="frm-tips">参数之间使用 & 连接，如：pages/index?id=1&name=abc&mobile=123</div>
              </el-col>
            </el-form-item>
            <el-form-item label="启动query参数" hidden prop="query">
              <el-col>
                <el-input
                  placeholder="?id=1"
                  with="200px"
                  type="textarea"
                  maxlength="1024"
                  show-word-limit
                  v-model="form.query"
                  :autosize="{minRows: 2, maxRows: 4}"
                ></el-input>
                <div class="frm-tips">只支持数字，大小写英文以及部分特殊字符：!#$&'()*+,/:;=?@-._~</div>
              </el-col>
            </el-form-item>
            <el-form-item>
              <el-button type="success" @click="submitForm()">生 成</el-button>
            </el-form-item>
          </el-form>
        </el-row>
        <el-row type="flex" justify="center">
          <el-col :span="15">
            <el-collapse accordion v-model="activeNames">
            </el-collapse>
          </el-col>
        </el-row>
        <el-row type="flex" justify="center">
          <el-col :span="15">
            <el-input placeholder="请输入小程序页面路径及参数"
                      v-model="params.path"
                      style="width: 300px;margin-bottom: 15px">
              <el-button slot="append" icon="el-icon-search" @click="fetchPageList"></el-button>
            </el-input>
            <el-table :data="list"
                      v-loading="loading"
                      stripe height="293"
                      border
                      :row-class-name="tableRowClassName"
            >
              <el-table-column prop="is_expire" label="有效期" align="center" show-overflow-tooltip min-width="110">
                <template slot-scope="scope">
                  <i class="el-icon-time" v-if="scope.row.is_expire === '1'"></i>
                  <span style="margin-left: 10px" v-if="scope.row.is_expire === '1'">{{ scope.row.expire_time | datetime('yyyy-MM-dd hh:mm:ss') }}</span>
                  <span v-else>永久有效</span>
                </template>
              </el-table-column>
              <!--              <el-table-column prop="path" label="小程序页面路径" align="center" show-overflow-tooltip min-width="80"></el-table-column>-->
              <el-table-column prop="query" label="页面路径及参数" align="center" show-overflow-tooltip min-width="140">
                <template slot-scope="scope">
                  {{ scope.row.path + (scope.row.query ? ('?' + scope.row.query) : '') }}
                </template>
              </el-table-column>
              <el-table-column prop="url_scheme" label="URL Link" align="center" show-overflow-tooltip min-width="150">
                <template slot-scope="scope">
                  <el-link :underline="false" type="success"
                           v-if="!(scope.row.is_expire === '1' && scope.row.expire_time < (new Date()).getTime() / 1000)"
                           v-clipboard:copy="scope.row.url_scheme"
                           v-clipboard:success="onCopy">{{ scope.row.url_scheme }}
                  </el-link>
                  <el-link v-else :underline="false" type="info" title="URL Link 已过期">{{ scope.row.url_scheme }}</el-link>
                </template>
              </el-table-column>
              <el-table-column prop="updated_at" label="生成时间" align="center" show-overflow-tooltip min-width="100"></el-table-column>
              <el-table-column label="操作" min-width="40" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                  <el-link icon="el-icon-document-copy" type="success" title="复制URL Link" size="mini" :underline="false"
                           v-if="!(scope.row.is_expire === '1' && scope.row.expire_time < (new Date()).getTime() / 1000)"
                           v-clipboard:copy="scope.row.url_scheme"
                           v-clipboard:success="onCopy"></el-link>
                  <el-link icon="el-icon-delete" type="danger" title="删除" size="mini" :underline="false" @click="delPage(scope.row.id)"></el-link>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
        <el-row type="flex" justify="center">
          <div class="content-padded content-center">
            <el-pagination
              small
              background
              layout="total, sizes, prev, pager, next, jumper"
              @current-change="handleCurrentChange"
              @size-change="handleSizeChange"
              :current-page.sync="params.page"
              :total="total_count"
              :pager-count="5"
              :page-size="params.pageSize"
              :page-sizes="[5, 10, 20, 50, 100]"
            >
            </el-pagination>
          </div>

        </el-row>
      </div>


      <el-dialog
        title="选择自定义页面"
        :visible.sync="customPageVisible"
        width="60%">
        <el-table
          v-loading="customPageLoading"
          highlight-current-row
          :data="customPageList"
          @current-change="changeCustomPageSelect">
          <el-table-column
            prop="id"
            label="页面id"
            width="110px"
          ></el-table-column>
          <el-table-column prop="page_name" label="页面名称"></el-table-column>
          <el-table-column label="审核状态" width="150px">
            <template slot-scope="scope">
              <el-tag type="info" v-if="scope.row.examine_status == '1'"
              >待提交</el-tag
              >
              <el-tag v-else-if="scope.row.examine_status == '2'">审核中</el-tag>
              <el-tag type="danger" v-else-if="scope.row.examine_status == '3'"
              >审核未通过</el-tag
              >
              <el-tag type="success" v-else>审核通过</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="是否启用">
            <template slot-scope="scope">
              <el-tag type="warning" v-if="scope.row.is_open == '0'">禁用</el-tag>
              <el-tag type="success" v-else>启用</el-tag>
            </template>
          </el-table-column>
        </el-table>
        <div
          v-if="customPageTotalCount > customPageParams.pageSize"
          class="content-padded content-center"
        >
          <el-pagination
            background
            layout="prev, pager, next"
            @current-change="handleCustomPageCurrentChange"
            :current-page.sync="customPageParams.page"
            :total="customPageTotalCount"
            :page-size="customPageParams.pageSize"
          ></el-pagination>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="customPageVisible = false">取 消</el-button>
          <el-button type="primary" @click="chooseCustomPage">确 定</el-button>
        </span>
      </el-dialog>

    </el-main>
  </el-container>
</template>

<script>
import {mapGetters} from 'vuex'
import {getUrlSchemeList, createUrlScheme, deleteUrlScheme, updateUrlScheme, getUrlScheme} from '@/api/wechat'
import { getCustomPageList } from '@/api/wxa'

export default {
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now();
        },
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            const date = new Date(new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1);
            picker.$emit('pick', date);
          }
        }, {
          text: '明天',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() + 3600 * 1000 * 24);
            picker.$emit('pick', date);
          }
        }, {
          text: '一周后',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() + 3600 * 1000 * 24 * 7);
            picker.$emit('pick', date);
          }
        }, {
          text: '一个月',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() + 3600 * 1000 * 24 * 31);
            picker.$emit('pick', date);
          }
        }]
      },
      total_count: 0,
      loading: false,
      customPageLoading: false,
      params: {
        page: 1,
        pageSize: 5,
        path: ''
      },
      customPageParams: {
        page: 1,
        pageSize: 10,
      },
      customPageTotalCount: 0,
      activeNames: ['1'],
      list: [],
      form: {
        is_expire: false,
        expire_time: '',
        path: 'pages/index',
        query: ''
      },
      page_type: 'index',
      customPageVisible: false,
      customPageList: [],
      selectCustomPageRow: {},
      pageTypePath: {
        index: 'pages/index',
        custom: 'pages/custom/custom-page?id='
      },
    }
  },
  computed: {
    ...mapGetters([
      'wxapp_id'
    ])
  },
  methods: {
    onCopy() {
      this.$message({
        message: '复制小程序 UrlLink 成功',
        type: 'success'
      })
    },
    handleSizeChange(pageSize) {
      this.params.pageSize = pageSize;
      this.fetchPageList();
    },
    handleCurrentChange(page_num) {
      this.params.page = page_num
      this.fetchPageList();
    },
    delPage(id) {
      this.$confirm('确认删除小程序当前 UrlLink 吗？').then(_ => {
        deleteUrlScheme(id).then(res => {
          this.$message({type: 'success', message: '删除小程序 UrlLink 成功！'})
          this.fetchPageList()
        })
      })
    },
    tableRowClassName({row}) {
      let table_style = '';
      if (row.is_expire === '1' && row.expire_time < (new Date()).getTime() / 1000) {
        table_style = 'warning-row';
      }
      return table_style;
    },
    submitForm() {
      if (this.page_type === 'custom') {
        if (!this.selectCustomPageRow.id) {
          this.$message.error('请选择自定义页面');
          return false
        }
      }

      let type = 'error', message = '生成小程序 UrlLink 失败';
      this.form.wxa_app_id = this.wxapp_id;
      createUrlScheme(this.form).then(res => {
        if (res.data.data) {
          this.fetchPageList();
          type = 'success';
          message = '生成小程序 UrlLink 成功'
          this.form = {
            is_expire: false,
            expire_time: '',
            path: '',
            query: ''
          }
        }
        this.$message({
          type: type,
          message: message
        });
      });
    },
    fetchPageList() {
      this.loading = true
      getUrlSchemeList(this.params).then(response => {
        if (response.data.data.list) {
          this.list = response.data.data.list;
          this.total_count = response.data.data.total_count
        }
        this.loading = false
      })
    },

    getCustomPageList() {
      this.customPageLoading = true
      let params = JSON.parse(JSON.stringify(this.customPageParams))
      getCustomPageList (params) .then (response => {
        this.customPageList = response.data.data.list
        this.customPageTotalCount = response.data.data.total_count
        this.customPageLoading = false
      }) . catch (error => {
        this.customPageLoading = false
      })
    },
    changeCustomPageSelect(row) {
      this.selectCustomPageRow = row
    },
    chooseCustomPage() {
      this.customPageVisible = false
      this.form.path = 'pages/custom/custom-page?id=' + this.selectCustomPageRow.id
    },
    handleCustomPageCurrentChange (page_num) {
      this.customPageParams.page = page_num
      this.getCustomPageList()
    },
    changePageType() {
      let pageType = JSON.parse(JSON.stringify(this.page_type))

      if (this.pageTypePath[pageType] !== undefined) {
        this.form.path = this.pageTypePath[pageType]
      } else {
        this.form.path = ''
      }
      this.selectCustomPageRow = {}
    },
  },
  mounted() {
    if (!this.wxapp_id) {
      this.$message({
        type: 'error',
        message: '小程序未绑定，请先绑定小程序！'
      })
    }
    this.fetchPageList();

    this.getCustomPageList();
  }
}
</script>

<style>
.el-table .warning-row {
  background: oldlace;
}
</style>
