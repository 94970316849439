<template>
  <div>
    <div v-if="$route.path.indexOf('_child') === -1 && $route.path.indexOf('_detail') === -1">
      <el-row :gutter="20">
        <el-col>
          <el-input class="input-m" placeholder="会员手机号" v-model="identifier">
            <el-button slot="append" icon="el-icon-search" @click="numberSearch"></el-button>
          </el-input>
          <el-input class="input-m" placeholder="会员名称" v-model="username">
            <el-button slot="append" icon="el-icon-search" @click="usernameSearch"></el-button>
          </el-input>
          <el-button-group>
            <el-button type="primary" @click="exportPopularizeData()">导出推广员业绩</el-button>
          </el-button-group>
        </el-col>
      </el-row>
      <el-card>
        <el-table :data="list" :height="wheight-170" v-loading="loading" element-loading-text="数据加载中" :default-sort = "{prop: 'bind_date', order: 'descending'}">
          <el-table-column prop="username" label="姓名"></el-table-column>
          <el-table-column prop="mobile" label="手机号" width="150">
            <template slot-scope="scope">
              <i v-if="scope.row.mobile" class="el-icon-mobile"></i>
              {{scope.row.mobile}}
              <el-tooltip v-if="scope.row.mobile" effect="dark" content="复制" placement="top-start">
                <i v-clipboard:copy="scope.row.mobile" v-clipboard:success="onCopy" class="el-icon-document-copy"></i>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="可提现">
            <template slot-scope="scope">
              {{scope.row.cashWithdrawalRebate/100}}元
            </template>
          </el-table-column>
          <el-table-column label="已提现">
            <template slot-scope="scope">
              {{scope.row.payedRebate/100}}元
            </template>
          </el-table-column>
          <el-table-column label="申请提现">
            <template slot-scope="scope">
              {{scope.row.freezeCashWithdrawalRebate/100}}元
            </template>
          </el-table-column>
          <el-table-column label="未结算">
            <template slot-scope="scope">
              {{scope.row.noCloseRebate/100}}元
            </template>
          </el-table-column>
          <el-table-column label="佣金总额">
            <template slot-scope="scope">
              {{scope.row.rebateTotal/100}}元
            </template>
          </el-table-column>
          <el-table-column label="商品总额">
            <template slot-scope="scope">
              {{scope.row.itemTotalPrice/100}}元
            </template>
          </el-table-column>
          <el-table-column label="操作" width="90">
            <template slot-scope="scope">
              <el-button icon="edit" type="text" @click="detail(scope.row)" class="btn-gap">分佣详情</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div v-if="total_count > params.pageSize" class="content-padded content-center">
          <el-pagination
          background
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handlePage"
          :current-page.sync="params.page"
          :total='total_count'
          :page-size="params.pageSize">
          </el-pagination>
        </div>
      </el-card>
    </div>
    <router-view></router-view>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  import { getPopularizeList, exportPopularizeData } from '../../api/promotions'
  export default {
    data () {
      return {
        params: {
          page: 1,
          pageSize: 14,
          mobile: '',
          username: ''
        },
        list: [],
        loading: false,
        row: {},
        identifier: '',
        username: '',
        total_count: 0
      }
    },
    methods: {
      exportPopularizeData() {
        exportPopularizeData(this.params).then(res => {
          if (res.data.data.status == true) {
            this.$message({
              type: 'success',
              message: '已加入执行队列，请在设置-导出列表中下载'
            });
          } else {
            this.$message({
              type: 'error',
              message: '导出失败'
            });
          }
        });
      },
      onCopy () {
        this.$notify.success({
          message: '复制成功',
          showClose: true
        });
      },
      detail (row) {
        let routeData = this.$router.resolve({ path: '/marketing/popularize/popularizelist/popularizelist_detail', query: {  user_id: row.user_id }});
        window.open(routeData.href, '_blank');
      },
      usernameSearch(val) {
          this.params.username = this.username;
          this.getPopularizeListFun(this.params)
      },
      numberSearch (e) {
        this.params.page = 1
        this.params.mobile = this.identifier
        this.getPopularizeListFun(this.params)
      },
      getPopularizeListFun (filter) {
        this.loading = true
        getPopularizeList(filter).then(res => {
          this.list = res.data.data.list
          this.total_count = Number(res.data.data.total_count)
          this.loading = false
        })
      },
      handlePage (page_num) {
        this.params.page = page_num
        this.getPopularizeListFun(this.params)
      },
      handleSizeChange (val) {
        this.params.pageSize = val
        this.getPopularizeListFun(this.params)
      }
    },
    mounted () {
      this.getPopularizeListFun(this.params)
    },
    computed: {
      ...mapGetters([
        'wheight'
      ])
    }
  }
</script>
<style scoped lang="scss">
.grade-setting-col {
  line-height: 40px;
}
.btn-gap {
  margin-left:10px;
}
</style>
