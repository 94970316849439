import fetch from '@/utils/fetch'


export const page_list = '/pop/page/lists'

// 弹窗列表
export const shop_list = '/pop/lists'

// 商品列表
export const goods_list = '/goods/items?item_type=normal'

// 获取弹窗页面列表
export function getPageList(){
  return fetch({
    url: page_list,
    method: 'get'
  })
}

// 设置弹窗排序
export function setPopSort(data){
  return fetch({
    url: 'pop/sort/set',
    method: 'post',
    data
  })
}

// 获取页面设置
export function getPageSetting(page_name){
  return fetch({
    url: '/pop/pageset',
    method: 'get',
    params: {
      page_name
    }
  })
}

// 保存页面设置
export function savePageSetting(data){
  return fetch({
    url: '/pop/pageset/save',
    method: 'post',
    data
  })
}

// 删除弹窗
export function deletePop(id){
  return fetch({
    url: '/pop/delete',
    method: 'post',
    data: {
      id
    }
  })
}

// 保存弹窗
export function savePop(data){
  return fetch({
    url: '/pop/save',
    method: 'post',
    data
  })
}

// 获取弹窗详情
export function getPopDetail(id){
  return fetch({
    url: '/pop/detail',
    method: 'get',
    params: {
      id
    }
  })
}

// 获取临时店铺列表
export function getTempStores(query) {
  return fetch({
    url: '/material/relDistributor/tempList',
    method: 'get',
    params: query
  })
}

// 详情获取门店列表数据
export function getDistributorList(distributorIds) {
  return fetch({
    url: '/distributors',
    method: 'get',
    params: {
      page: 1,
      pageSize: 99999,
      distributorIds
    }
  })
}

// 详情获取商品列表数据
export function getGoodsList(item_id) {
  return fetch({
    url: goods_list,
    method: 'get',
    params: {
      page: 1,
      pageSize: 99999,
      item_id
    }
  })
}

// 装修获取优惠券列表数据
export function getDiscountList(card_id) {
  return fetch({
    url: '/discountcard/list',
    method: 'get',
    params: {
      page_no: 1,
      page_size: 10,
      card_id
    }
  })
}
