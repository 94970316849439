import fetch from '@/utils/fetch'

export const page_list = '/subscribe/custom_msg/lists'

// 获取详情
export function detail(id){
  return fetch({
    url: '/subscribe/custom_msg/info',
    method: 'get',
    params: {
      id
    }
  })
}

// 新增
export function create(data){
  return fetch({
    url: '/subscribe/custom_msg/create',
    method: 'post',
    data
  })
}

// 编辑
export function update(data){
  return fetch({
    url: '/subscribe/custom_msg/update',
    method: 'post',
    data
  })
}

// 删除
export function deleteItem(id){
  return fetch({
    url: '/subscribe/custom_msg/delete',
    method: 'post',
    data: {
      id
    }
  })
}
