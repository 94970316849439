<template>
  <div class="footer-submit-buttons">
    <div class="footer-submit-buttons-fixed">
      <el-button v-if="cancel" type="default" @click="handleClickCancel">
        取消
      </el-button>
      <el-button type="primary" @click="handleSubmit">
        提交
      </el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterSubmitButtons',
  props: {
    cancel: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    handleSubmit () {
      this.$emit('submit')
    },
    async handleClickCancel () {
      this.$emit('cancel')
    }
  }
}
</script>

<style lang="scss">
.footer-submit-buttons {
  height: 72px;
  &-fixed {
    position: fixed;
    left: 125px + 130px;
    bottom: 0;
    width: calc(100% - 125px - 130px);
    padding: 18px 0;
    background: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #f0f2f5;
    z-index: 1999;
  }
  button {
    width: 88px;
    height: 36px;
    border-radius: 2px;
    border: 1px solid #ccecff;
  }
}
</style>
