<template>
  <div class="wxapp-pop-page">
    <div class="wxapp-pop-top">
      <el-button size="medium" type="primary" @click="handleAddPop">添加弹窗</el-button>
    </div>
    <div class="wxapp-pop">
      <div class="wxapp-pop-left">
        <SpFinder
          ref="pageFinder"
          :data="pageData"
          noSelection
          :setting="pageSetting"
          fixed-row-action
          :showPager="false"
          class="clear-finder-style"
          :otherConfig="{
            highlightCurrentRow: true,
            stripe: false
          }"
          @current-change="handleCurrentChange"
        >
        </SpFinder>
      </div>
      <div class="wxapp-pop-right">
        <SpFinder
          ref="popFinder"
          :url="shop_list"
          noSelection
          :setting="popSetting"
          fixed-row-action
          :split-count="10"
          :hooks="{ beforeSearch }"
        >
        <template #tableTop>
          <div class="table-top">
            <div>
              <el-tabs v-model="activeName" type="card" @tab-click="handleClickTab">
                <el-tab-pane
                  v-for="item in StatusOption"
                  :key="item.value"
                  :label="item.label"
                  :name="item.value"
                />
              </el-tabs>
            </div>
          </div>
        </template>
        </SpFinder>
      </div>
    </div>
  </div>
</template>

<script>
import { pageSetting, popSetting, StatusOption, commonDialogActions } from './config/index'
import { getPageList, shop_list } from './api'
import { useDialog } from '@shopex/finder'

export default {
  components: {},
  computed: {
    pageSetting() {
      return pageSetting(this)
    },
    popSetting() {
      return popSetting(this)
    }
  },
  data () {
    return {
      activeName: 'all',
      pageData: [],
      pageOptions: [], // 新增弹窗使用
      shop_list,
      StatusOption,
      use_page: 'index' // 首页
    }
  },
  async mounted () {
    const res = await getPageList()
    const { data } = res.data
    this.pageData = Object.keys(data).map(key => ({
      id: key,
      key,
      name: data[key]
    }))
    this.pageOptions = Object.keys(data).map(key => ({
      label: data[key],
      value: key
    }))
    const row = this.pageData[0]
    this.$refs.pageFinder.$refs.finderTable.$refs.finderTable.$refs.table.setCurrentRow(row);
  },
  methods: {
    handleCurrentChange(val) {
      this.use_page = val.key
      this.refresh()
    },
    handleAddPop() {
      useDialog(this, {
        title: `添加弹窗`,
        width: '80%',
        pageOptions: this.pageOptions,
        component: () => import('./components/pop-setting.vue'),
        actions: commonDialogActions
      }).then(arg => {
        if(arg) this.refresh()
      })
    },
    refresh() {
      this.$refs.popFinder.refresh()
    },
    handleClickTab() {
      this.refresh()
    },
    beforeSearch(params) {
      return {
        ...params,
        use_page: this.use_page,
        status: this.activeName
      }
    }
  },
}
</script>

<style scoped lang="scss">
.wxapp-pop-page {
  .wxapp-pop-top {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 8px;
  }
  .wxapp-pop {
    display: flex;
    justify-content: space-between;
    &-left {
      width: 210px;
      margin-right: 12px;
    }
    &-right {
      flex: 1;
    }
    .table-top {
      margin-bottom: 12px;
      display: flex;
      justify-content: space-between;
    }
  }
}

::v-deep .sp-finder .cell .sp-finder-actions__wrap {
  justify-content: center !important;
}

::v-deep .sp-finder .cell .el-input-number {
  width: 90% !important;
}

</style>
