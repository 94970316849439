<template>
  <div class="container question-statistics-container" v-loading="pageLoading">
    <div><el-button style="margin-bottom: 10px" type="primary" size="mini" @click="exportSubmitList(1)">导出明细</el-button></div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>一级问卷项</span>
        <!-- <el-button style="float: right;" type="primary" size="mini" @click="exportSubmitList(1)">导出明细</el-button> -->
      </div>
      <div v-for="pitem in question_first_list" :class="firstSelectId == pitem.id ? 'question_list select' : 'question_list'" :key="pitem.id">
        <div class="option-hd" @click="onFirstSelectChange({ value: pitem.id, pitem, type: 'parent' })">
          <div class="hd">{{ pitem.subject }}</div>
        </div>
        <el-radio-group v-model="firstSelectOption" size="mini" class="comp-option-radio">
          <el-radio v-for="c_item in pitem.answer" :label="c_item.answer_id" :key="c_item.answer_id" border  @click.native.prevent="onFirstSelectChange({ value: c_item.answer_id, pitem, type: 'son' })">
            <div class="radio_answer_content">{{ c_item.content }}</div>
            <div class="radio_answer_submit">{{ c_item.submit_count }}</div>
          </el-radio>
        </el-radio-group>
      </div>
    </el-card>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>二级问卷项</span>
        <!-- <el-button style="float: right;" type="primary" size="mini" @click="exportSubmitList(2)">导出明细</el-button> -->
      </div>
      <div v-for="pitem in question_second_list" :class="secondSelectId == pitem.id ? 'question_list select' : 'question_list'" :key="pitem.id">
        <div class="option-hd" @click="onSecondSelectChange({ value: pitem.id, pitem, type: 'parent' })">
          <div class="hd">{{ pitem.subject }}</div>
        </div>
        <el-radio-group v-model="secondSelectOption" size="mini" class="comp-option-radio">
          <el-radio v-for="c_item in pitem.answer" :label="c_item.answer_id" :key="c_item.answer_id" border  @click.native.prevent="onSecondSelectChange({ value: c_item.answer_id, pitem, type: 'son' })">
            <div class="radio_answer_content">{{ c_item.content }}</div>
            <div class="radio_answer_submit">{{ c_item.submit_count }}</div>
          </el-radio>
        </el-radio-group>
      </div>
    </el-card>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>三级问卷项</span>
        <!-- <el-button style="float: right;" type="primary" size="mini" @click="exportSubmitList(3)">导出明细</el-button> -->
      </div>
      <div v-for="pitem in question_third_list" :class="thirdSelectId == pitem.id ? 'question_list select' : 'question_list'" :key="pitem.id">
        <div class="option-hd" @click="onThirdSelectChange({ value: pitem.id, pitem, type: 'parent' })">
          <div class="hd">{{ pitem.subject }}</div>
        </div>
        <el-radio-group v-model="thirdSelectOption" size="mini" class="comp-option-radio">
          <el-radio v-for="c_item in pitem.answer" :label="c_item.answer_id" :key="c_item.answer_id" border  @click.native.prevent="onThirdSelectChange({ value: c_item.answer_id, pitem, type: 'son' })">
            <div class="radio_answer_content">{{ c_item.content }}</div>
            <div class="radio_answer_submit">{{ c_item.submit_count }}</div>
          </el-radio>
        </el-radio-group>
      </div>
    </el-card>
    <el-dialog width="450px" title="请选择提交问卷时间" :visible="exportDialog" @close="closeDialog" append-to-body destroy-on-close >
      <el-date-picker
        v-model="create_time"
        type="datetimerange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        value-format="timestamp"
        :default-time="['00:00:00', '23:59:59']"
      />
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="saveExportAction">确 定</el-button>
    </span>
  </el-dialog>
  </div>
</template>

<script>
import { getSumbitGroupList, getQuestionnaireLevelQuestionList, getQuestionnaireSubmitGroupList, getQuestionnaireSubmitList } from '@/api/wxa'

// 组件
import SkuSelector from '@/components/function/skuSelector'
import couponPicker from '@/components/coupon_gift_picker'
import GoodsSelect from '@/components/goodsSelect'
import CustomizeSelect from '@/components/function/customizeSelect'

import CompFirstDialog from './comp/comp-first-dialog.vue'
import CompOption from './comp/comp-option.vue'

import { mapGetters } from "vuex";

export default {
  inject: ['refresh'],
  components: {
    SkuSelector,
    couponPicker,
    GoodsSelect,
    CustomizeSelect,
    CompFirstDialog,
    CompOption
  },
  data () {
    return {
      pageLoading: false,
      question_first_list: [],
      question_second_list: [],
      question_third_list: [],
      firstSelectId: null,
      firstSelectOption: null,
      secondSelectId: null,
      secondSelectOption: null,
      thirdSelectId: null,
      thirdSelectOption: null,
      routerId: null,
      exportDialog: false,
      create_time: []
    }
  },
  computed: {
    ...mapGetters(["wheight"])
  },
  methods: {
    async getQuestionDetail () {
      this.pageLoading = true
      const res = await getSumbitGroupList({ tpl_id: this.routerId })
      const response = res.data.data
      const { one = [], two = [], three = [] } = response || {}
      this.question_first_list = one
      this.question_second_list = two
      this.question_third_list = three
      this.pageLoading = false
    },
    async getQuestionLevelList (params) {
      this.pageLoading = true
      console.log(params, 'getQuestionLevelList')
      const { level, type, value, pitem } = params
      let data = { tpl_id: this.routerId, question_level: level }
      if (type == 'parent') {
        data['parent_list_id'] = value
      } else if (type == 'son') {
        if (!value) { // 如果子选项没有选中值，则传上一级父问卷的id 用parent_list_id字段
          data['parent_list_id'] = pitem.id
        } else {
          data['parent_answer_id'] = value
        }
      }
      const res = await getQuestionnaireSubmitGroupList(data)
      if (level == 2) {
        this.question_second_list = res.data.data
      } else if (level == 3) {
        this.question_third_list = res.data.data
      }
      this.pageLoading = false
    },
    onFirstSelectChange (params) {
      console.log(params, 'onFirstSelectChange')
      const { pitem, value, type } = params
      if (!value || value != this.firstSelectOption) {
        this.secondSelectId = null
        this.secondSelectOption = null
        this.thirdSelectId = null
        this.thirdSelectOption = null
      }
      if (type == 'parent') {
        this.firstSelectOption = null // 点击父元素，取消子元素选中的值
        if (this.firstSelectId == pitem.id) {
          this.firstSelectId = null
          this.getQuestionLevelList({...params, type: '', level: 2}) // 父问卷取消选中时，获取二级，不需要传参数
          this.getQuestionLevelList({...params, type: '', level: 3}) // 同时获取三级，不需要传参数
          return
        }
      } else if (type == 'son') {
        if (this.firstSelectOption == value) {
          this.firstSelectOption = null
          this.getQuestionLevelList({ ...params, value: '', level: 2, type })
          this.getQuestionLevelList({ ...params, value: '', level: 3, type: '' })
          return
        }
        this.firstSelectOption = value // 点击子元素时，更改子组件选中的值
      }
      this.firstSelectId = pitem.id
      this.getQuestionLevelList({ ...params, level: 2, value, type }) // 一级父/子问卷中时，获取二级问卷，父传parent_list_id 子传parent_list_id
      this.getQuestionLevelList({ ...params, level: 3, value, type: '' }) // 同时获取三级全部问卷，不需要传参数
    },
    onSecondSelectChange (params) {
      console.log(params, 'onSecondSelectChange')
      const { pitem, value, type } = params
      if (!value || value != this.secondSelectOption) {
        this.thirdSelectId = null
        this.thirdSelectOption = null
      }
      if (type == 'parent') {
        this.secondSelectOption = null
        if (this.secondSelectId == pitem.id) {
          this.secondSelectId = null
          this.getQuestionLevelList({ ...params, type: '', level: 3, value: '' }) // 二级父问卷取消时，重新获取三级全部问卷，不需要传参数
          return
        }
      } else if (type == 'son') {
        if (this.secondSelectOption == value) {
          this.secondSelectOption = null
          this.getQuestionLevelList({ ...params, value: '', level: 3 })
          return
        }
        this.secondSelectOption = value // 点击子元素时，更改子组件选中的值
      }
      this.secondSelectId = pitem.id
      this.getQuestionLevelList({ ...params, value, level: 3 }) // 二级父/子问卷中时，获取三级问卷，父传parent_list_id 子传parent_list_id
    },
    onThirdSelectChange (params) {
      console.log(params, 'onThirdSelectChange')
      const { pitem, value, type } = params
      if (!value || value != this.thirdSelectOption) {
        this.thirdSelectId = null
        this.thirdSelectOption = null
      }
      if (type == 'parent') {
        this.thirdSelectOption = null // 点击父元素，取消子元素选中的值
        if (this.thirdSelectId == pitem.id) { // 选中值和传入值相等时，取消选中
          this.thirdSelectId = null
          return
        }
      } else if (type == 'son') {
        if (this.thirdSelectOption == value) {
          this.thirdSelectOption = null
          return
        }
        this.thirdSelectOption = value // 点击子元素时，更改子组件选中的值
      }
      this.thirdSelectId = pitem.id
    },
    exportSubmitList(level) {
      this.exportDialog = true
    },
    closeDialog () {
      this.exportDialog = false
      this.create_time = []
    },
    saveExportAction () {
      const params = {
        'tpl_id': this.routerId,
        'export': 1,
        create_time: this.create_time
      }
      if (this.create_time.length == 0) {
        return this.$message.error('请选择时间范围')
      }
      getQuestionnaireSubmitList(params).then(response => {
        this.$message.success('导出成功，请前往设置-导出列表查看')
        this.closeDialog()
      })
    }
  },
  mounted () {
    const id = this.$route.query.id
    if (id) {
      this.routerId = id
      this.getQuestionDetail()
    }
  }
}
</script>

<style lang="scss">
.question-statistics-container {
  .active {
    color: #5B8FF9 !important;
  }
  .border-active {
    border: 2px solid #5B8FF9 !important;
  }
  .statistics-card {
    min-height: 65%;
    .el-card__body {
      width: 100%;
    }
  }
  .box-card {
    margin-right: 10px;
    .el-card__body {
      min-height: 200px;
      max-height: 400px;
      overflow-y: auto;
      display: flex;
      &::-webkit-scrollbar {
        width: 5px;
        height: 10px;
      }
      &::-webkit-scrollbar-track {
        background-color: #fff;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        background-color: #a8a6ad;

      }
    }
    &:last-child {
      margin-bottom: 20px;
      margin-right: 0px;
    }
    .option-hd {
      cursor: pointer;
      margin-bottom: 10px;
      .hd {
        color: #606266;
        font-weight: 400;
      }
    }
    .comp-option-radio {
      text-align: center;
      width: 100%;
      .el-radio {
        display: block;
        text-align: center;
        margin: 0 auto 10px !important;
        // width: 90%;
        border-radius: 5px;
        &:first-child {
          margin-top: 10px !important;
        }
        .el-radio__inner {
          display: none;
        }
      }
    }
  }
  .radio_answer_content {
    float: left;
    line-height: 15px;
  }
  .radio_answer_submit {
    float: right;
    line-height: 15px;
    color: #1f82e0;
  }
  .question_list {
    border: 1px solid #ebeef5;
    border-radius: 4px;
    min-width: 22%;
    padding: 20px;
    margin-right: 20px;
    :first-child {
      margin-right: 0px;
    }
  }
  .question_list.select {
    border: 1px solid #409EFF;

  }
}
</style>
