<template>
  <div>
    <el-tabs v-model="activeName" type="border-card">
      <el-tab-pane label="自闭环设置" name="base">
        <div>
          开启全局自闭环&nbsp;&nbsp;&nbsp;&nbsp;
          <el-switch v-model="form.open_status" @change="changeSwitch" active-value="1" inactive-value="2">
          </el-switch>
          <span style="margin-left: 100px"></span>
          <el-tag v-if=showHide  type="warning">当前配置下，仅下列门店可使用订单自闭环</el-tag>
          <el-tag v-else type="danger">当前配置下，所有门店均可使用订单自闭环</el-tag>
        </div>
      </el-tab-pane>
    </el-tabs>

    <el-divider></el-divider>
    <el-card class="box-card" style="margin-top: 10px">
      <div slot="header" class="clearfix">
        <el-button type="primary" size="mini"  @click="addDistributorAction" >添加门店</el-button>
        <span  class="frm-tips" style="color: #F56C6C"  v-if=!showHide>【已开启全局自闭环，所有门店均可使用自闭环】</span>
        <el-button style="float: right; padding: 3px 0" type="text" @click="removeShop('all')">一键移除</el-button>

      </div>
      <div>
        <el-table :data="list" stripe  v-loading="loading" element-loading-text="数据加载中">
          <el-table-column prop="distributor_id" label="店铺ID" min-width="200px"></el-table-column>
          <el-table-column prop="name" label="门店名称" min-width="200px"></el-table-column>
          <el-table-column prop="shop_code" label="Apple ID" min-width="200px"></el-table-column>
          <el-table-column label="操作" min-width="270">
            <template slot-scope="scope">
              <el-button size="mini"  type="warning" @click="removeShop(scope.row.distributor_id)" plain >移除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="content-padded content-center">
          <el-pagination background layout="total, sizes, prev, pager, next, jumper" @current-change="handleCurrentChange"
                         @size-change="handleSizeChange" :current-page.sync="params.page" :page-sizes="[10, 20, 50]"
                         :total="total_count" :page-size="params.pageSize">
          </el-pagination>
        </div>
      </div>
    </el-card>


    <DistributorSelect
      :store-visible="distributorVisible"
      :is-valid="true"
      :relDataIds="distributor_info"
      :get-status="setDistributorStatus"
      @chooseStore="chooseDistributorAction"
      @closeStoreDialog="closeDistributorDialogAction"
      returnType="selectRow"
    ></DistributorSelect>
  </div>
</template>
<script>

import {getSelfHandleStatus, removeShop, setSelfHandleShops, setSelfHandleStatus} from '@/api/tool'
import DistributorSelect from "@/components/storeListSelect";
import {delTaxstrategy} from "@/api/crossborder";

export default {
  components: {
    DistributorSelect
  },
  data() {
    return {
      setDistributorStatus: false,
      loading:false,
      distributorVisible:false,
      btnload:false,
      btn_name:'保存配置',
      list: [],
      total_count: 0,
      form: {
        open_status: '1',
        hide_address: '1',
      },
      distributor_info: [],
      showHide:false,
      activeName: 'base',
      params: {
        page: 1,
        pageSize: 10
      },
      relDistributorIds: [],
      dataForm:{
        distributor_id:[]
      }
    }
  },
  methods: {
    addDistributorAction() {
      this.distributorVisible = true;
      this.setDistributorStatus = true;
      this.relDistributorIds = this.dataForm.distributor_id;
    },

    saveTcSetting() {
      this.btnload =true
      this.btn_name ='处理中'
      setSelfHandleStatus(this.form).then(res => {
        this.$message({ type: 'success', message: '保存成功' })
        this.btnload =false
        this.btn_name ='保存配置'
      })
    },

    chooseDistributorAction(data) {
      this.distributorVisible = false;
      if (!data || data === null || data.length <= 0) {
        this.distributor_info = [];
        return;
      }
      this.distributor_info = data;
      this.dataForm.distributor_id = [];
      for (let i = 0; i < data.length; i++) {
        const id = data[i].distributor_id;
        this.dataForm.distributor_id.push(Number(id));
      }
      console.log(this.dataForm)
      setSelfHandleShops(this.dataForm).then(res => {
        this.getPiiSetting()
      })
    },
    closeDistributorDialogAction() {
      this.distributorVisible = false;
    },
    getPiiSetting() {
      this.loading =true
      getSelfHandleStatus(this.params).then(res => {
        let data = res.data.data
        this.form = {...data}
        if(this.form.open_status === '2'){
          this.showHide=true
        }else{
          this.showHide=false
        }
        this.list =data.distributorList.list
        this.total_count = Number(data.distributorList.total_count);
        this.loading =false
      })
    },
    removeShop(distributor_id) {
      if(distributor_id === 'all'){
        this.$confirm('确认移除所有门店吗？此操作不可逆！')
          .then(_ => {
            this.loading =true
            let delParams = {
              distributor_id: distributor_id
            };
            removeShop(delParams).then(res => {
              this.getPiiSetting()
            })
          })
          .catch(_ => {
            this.$message({
              type: 'info',
              message: '已取消'
            })
            return
          })
      }else{
        this.loading =true
        let delParams = {
          distributor_id: distributor_id
        };
        removeShop(delParams).then(res => {
          this.getPiiSetting()
        })
      }

    },
    handleCurrentChange(page_num) {
      this.loading = false
      this.params.page = page_num
      this.getPiiSetting()
    },
    handleSizeChange(pageSize) {
      this.loading = false
      this.params.page = 1
      this.params.pageSize = pageSize
      this.getPiiSetting()
    },
    changeSwitch(val) {
      this.form.open_status = val
      console.log(val);
      if(val === '2'){
        this.showHide=true
        this.form.hide_address ='1'
      }else{
        this.showHide=false
        this.form.hide_address ='2'

      }
      this.saveTcSetting()
      console.log(this.showHide);
    },
    changeSwitchHide(val) {
      this.form.hide_address = val
    }
  },
  mounted() {
    this.getPiiSetting()
  }
}
</script>
<style scoped type="text/css" lang="scss"></style>
