<style lang="scss" scoped>
.el-tabs {
  margin: -20px;
  padding-left: 10px;
}

.tabbar-setting {
  display: flex;
  height: calc(100vh - 110px);
  overflow: hidden;

  .tabbar-setting-content {
    flex: 1;
    min-width: 375px;
    display: flex;
    align-items: center;
    justify-content: center;

    .content {
      min-width: 375px;
      max-width: 375px;
      height: 667px;
      background: #f5f5f5;
      position: relative;

      &-img {
        background-color: #fff;
        border: 1px solid #f5f5f5;
        box-sizing: border-box;
        display: block;
      }

      .popover-text {
        position: absolute;
        bottom: 55px;
        max-width: 355px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 10px;
        border-radius: 10px;
        line-height: 1;

        // &:after {

        // }

      }

      .popper__arrow {
        position: absolute;
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 10px solid transparent;
        bottom: 48px;
      }

      .nav-priview {
        display: flex;
        align-items: flex-start;
        position: absolute;
        width: 375px;
        height: 55px;
        bottom: 0px;


        &-item {
          height: 55px;
          flex: 1;
          position: relative;

          .nav-setting-item-content {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

          }

          &-text {
            font-size: 10px;
            line-height: 10px;
            padding-top: 3px;
            padding-bottom: 3px;
          }
        }

      }
    }
  }

  .tabbar-setting-form {
    width: 450px;
    min-width: 450px;
    background: #fff;
    border-left: 1px solid #eaeaea;
    box-sizing: border-box;
    position: relative;
    height: 100%;
    overflow: hidden;

    .form-title {
      color: #000;
      padding: 10px 0px 10px 20px;
      border-bottom: 1px solid #eaeaea;
    }

    .form-content {
      overflow-y: scroll;
      height: calc(100% - 100px);
      overflow-x: hidden;
      padding: 0 20px;


      .form-item {
        margin-top: 15px;

        display: flex;
        align-items: center;
        justify-content: space-between;

        &_column {
          flex-direction: column;
          align-items: flex-start;
        }

        &_full {
          background-color: #f8f8f8;
          justify-content: center;
          align-items: center;
          padding: 10px 0;
          border: 1px solid #eaeaea;
        }

        &_label {
          margin-right: 15px;
          font-size: 13px;
        }

        .form-item_content {
          width: 100%;
          background-color: #fff;
          border: 1px solid #eaeaea;
          margin-top: 10px;

          &-row {
            display: flex;
            align-items: center;
            padding: 10px;

            .content-row_item {
              flex: 1;
              display: flex;
              align-items: center;

              .lable {
                padding-right: 5px;
                min-width: 40px;
              }
            }
          }

          &_item {
            display: flex;
            padding-bottom: 10px;
            padding-left: 10px;

            &:first-child {
              padding-top: 10px;
            }

            .lable {
              font-size: 13px;
              width: 100px;
            }
          }

          .nav-setting-item {
            background-color: #fff;

            &-header {
              display: flex;
              align-items: center;
              justify-content: space-between;
              border-bottom: 1px solid #eaeaea;
              padding: 10px 10px;
              font-size: 13px;

              .icon {
                margin-right: 10px;
              }

            }

            .drag-content {
              padding: 10px 20px;
              overflow: hidden;
              border-bottom: 1px solid #eaeaea;
            }


            &-default {
              display: flex;
              align-items: center;
              justify-content: space-between;

              .default_item {
                width: 50%;
                display: flex;
                align-items: center;
                justify-content: flex-start;

                &:not(:last-child) {
                  margin-bottom: 10px;
                }

                &_text {
                  display: inline-block;
                  width: 80px;
                  font-size: 13px;
                }

              }

              .picker-box {
                border: 1px solid #eaeaea;
                padding: 5px 10px;
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-radius: 5px;
                font-size: 13px;
              }
            }

            &:last-child {
              .drag-content {
                border: none;
              }
            }
          }
        }


        .btns {
          margin-top: 10px;
          margin-bottom: 10px;

          .max-text {
            font-size: 11px;
          }
        }
      }
    }



    .footer {
      position: absolute;
      bottom: 0px;
      left: 0px;
      right: 0px;
      height: 60px;
      box-sizing: border-box;
      padding: 0px 20px;
      display: flex;
      align-items: center;
      border-top: 1px solid #eaeaea;
    }
  }
}
</style>
<style lang="scss">
.tabbar-setting {

  .sp-image-picker {
    .image-item {
      height: 45px !important;
      width: 45px !important;

      .icon-camera {
        font-size: 12px;
      }
    }
  }

}
</style>

<template>
  <el-tabs v-model="platform" @tab-click="handleChangeTab">
    <el-tab-pane label="微信小程序" v-if="admin_type !== 'order'" name="wxapp"></el-tab-pane>
    <el-tab-pane label="微信订单小程序" v-if="admin_type === 'order' && login_type === 'disti'" name="wxapp"></el-tab-pane>
    <el-tab-pane label="抖音小程序" v-if="login_type === 'disti' && admin_type === 'order'" name="byte_mini"></el-tab-pane>
    <div class="tabbar-setting">
      <div class="tabbar-setting-content">
        <div class="content">
          <el-image class="content-img" :src="weappHeaderDark" style="width: 100%; height: 64px;"></el-image>
          <div class='nav-priview' :style="`background: ${tabbar.config.backgroundColor}`" v-if="tabbar">
            <div class='nav-priview-item' :key="`nav-priview_${key}`" v-for="(navitem, key) in tabbar.data">
              <div class="popover-text" :style='settingPlacement(key)'
                v-if="navitem.data && navitem.data?.show_content">
                {{ navitem.data?.show_content }}
              </div>
              <div class="popper__arrow" v-if="navitem.data && navitem.data?.show_content" :style='settingarrow(key)' />
              <div class="nav-setting-item-content" slot="reference" @click="handleChangeCurIndex(key)">
                <SpImage :src="curIndex == key ? navitem.selectedIconPath : navitem.iconPath" width="25" height="25"
                  fit="'fill'" />
                <span class='nav-priview-item-text'
                  :style='`color: ${curIndex == key ? tabbar.config.selectedColor : tabbar.config.color}`'>{{
                    navitem.text }}</span>
              </div>
            </div>
          </div>

        </div>

      </div>
      <div class="tabbar-setting-form">
        <div class="form-title">导航设置</div>
        <div class="form-content">
          <div class="form-item">
            <div class="form-item_label">是否实验</div>
            <el-select v-model="tabInfo.abId" placeholder="请选择" size="small" @change="handleChangeAbid">
              <el-option v-for="item in experimentList" :key="item.value" :label="item.title" :value="item.value" />
            </el-select>
          </div>
          <div class="form-item form-item_column" v-if="tabInfo.abId">
            <div class="form-item_label">选择实验</div>
            <el-radio-group v-model="abGroundId" size="mini" @change="handleChangeAbGroundId">
              <el-radio-button v-for="item of tabInfo.abData" :key="item.value" :label="item.value"
                style="margin-top: 5px;">
                {{ item.label }}
              </el-radio-button>
            </el-radio-group>
          </div>
          <div v-for="(item, index) of tabInfo.abData" :key="`${item.value}__item`"
            v-if="item.value == abGroundId && refresh">
            <div class="form-item form-item_full" v-if="tabInfo.abId">
              <div class="form-item_label">实验设置拷贝填充</div>
              <el-dropdown split-button type="primary" size="small">
                选择拷贝数据
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-for="ele in filtetData" :key="ele.value"
                    @click.native="handleCopy(ele.value, index)">
                    {{ ele.label }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <div class="form-item  form-item_column">
              <div class="form-item_label">导航配色设置</div>
              <div class="form-item_content">
                <div class="form-item_content_item">
                  <div class="lable">背景颜色</div>
                  <el-color-picker v-model="item.config.backgroundColor" size="mini"
                    @change="handleChangeColor('backgroundColor', index, $event)"></el-color-picker>
                </div>
                <div class="form-item_content_item">
                  <div class="lable">导航项名称色</div>
                  <div class="">
                    <el-color-picker v-model="item.config.color" size="mini"
                      @change="handleChangeColor('color', index, $event)"></el-color-picker>
                    <div class="lable">未选中</div>
                  </div>
                  <div class="">
                    <el-color-picker v-model="item.config.selectedColor" size="mini"
                      @change="handleChangeColor('selectedColor', index, $event)"></el-color-picker>
                    <div class="lable">选中</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-item  form-item_column">
              <div class="form-item_label">气泡设置</div>
              <div class="form-item_content form-item_content-row">
                <div class="content-row_item">
                  <div class="lable">背景颜色</div>
                  <el-color-picker v-model="item.config.popbackground" size="mini"
                    @change="handleChangeColor('popbackground', index, $event)"></el-color-picker>
                </div>
                <div class="content-row_item">
                  <div class="lable">字体颜色</div>
                  <el-color-picker v-model="item.config.popcolor" size="mini"
                    @change="handleChangeColor('popcolor', index, $event)"></el-color-picker>
                </div>
                <div class="content-row_item">
                  <div class="lable">字号</div>
                  <el-input-number v-model="item.config.popSize" :min="0" size="mini"
                    controls-position="right"></el-input-number>
                </div>
              </div>
            </div>
            <div class="form-item form-item_column">
              <div class="form-item_label">导航项设置</div>
              <div class="form-item_content">
                <draggable handle='.icon-bars' :animation='300' :scroll="true" v-model="item.data"
                  chosenClass='chosenClass'>
                  <div class='nav-setting-item' :key="`nav-setting-item${key}`" v-for="(ele, key) in item.data">
                    <div class='nav-setting-item-header'>
                      <span>导航{{ key + 1 }}</span>
                      <div>
                        <i :class="`${collapseIndexs.indexOf(key) == -1 ? 'el-icon-arrow-down' : 'el-icon-arrow-right'} icon`"
                          @click="handleShowNav(key)"></i>
                        <i class="iconfont icon-bars mover icon" />
                        <i class="iconfont icon-trash-alt" @click="handleDelNav(index, key)" />
                      </div>
                    </div>
                    <div class='drag-content' :style="dragStyle(key)">
                      <div class='nav-setting-item-default'>
                        <div class='default_item'>
                          <span class='default_item_text'>未选中icon</span>
                          <SpImagePicker size="small" class="video-link" text="" v-model="ele.iconPath" />
                        </div>
                        <div class='default_item'>
                          <span class='default_item_text'>名称</span>
                          <el-input size='small' v-model="ele.text" />
                        </div>
                      </div>
                      <div class='nav-setting-item-default'>
                        <div class='default_item'>
                          <span class='default_item_text'>选中icon</span>
                          <SpImagePicker size="small" class="video-link" text="" v-model="ele.selectedIconPath" />
                        </div>
                        <div class='default_item'>
                          <span class='default_item_text'>页面</span>
                          <el-select v-model="ele.pagePath">
                            <el-option v-for="option in pathoptions" :key="option.value" :label="option.label"
                              :value="option.value"></el-option>
                          </el-select>
                        </div>
                      </div>
                      <div class='nav-setting-item-default'>
                        <span class='default_item_text'>图片营销</span>
                        <div class="picker-box" @click="handleClicikNav(ele, index, key)">
                          <span>{{ setLabelName(ele) }}</span>
                          <div>
                            <i class="el-icon-edit"></i>
                            <i class="el-icon-delete" @click.stop="handleDelmarket(ele, index, key)"
                              style="margin-left: 10px;"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </draggable>
              </div>
              <div class='btns'>
                <el-button size='small' type='primary' plain @click="addNav(index)">
                  添加导航
                </el-button>
                <span class='max-text'>最多可添加5个</span>
              </div>
            </div>

          </div>
        </div>

        <div class="footer">
          <el-button size='small' type='primary' @click="saveTabInfo">保存</el-button>
        </div>
      </div>
    </div>
  </el-tabs>
</template>

<script>
import { mapGetters } from 'vuex'
import { cloneDeep,isEmpty } from "lodash";
import draggable from "vuedraggable"
import { DEFAULTTABBAR, DEFAULTDYTABBAR, PATHOPTIONS, DYPATHOPTIONS } from "@/consts"
import { pickBy } from "@/utils";
const weappHeaderDark = require('@/assets/imgs/weapp-header-dark.png')
export default {
  components: { draggable },
  data() {
    return {
      weappHeaderDark,
      tabInfo: {
        abId: '',
        abData: [],
        abName: '',
      },
      experimentList: [],
      abGroundId: '',
      collapseIndexs: [],
      curIndex: 0,
      platform: 'wxapp',
      refresh: true
    }
  },
  created() {
    this.getExperimentList()
    this.getTemplateSetInfo()
  },
  methods: {
    async getTemplateSetInfo() {
      const res = await this.$api.template.getPagesTemplateSetInfo({ platform: this.platform, version: 2 })
      if (!res?.tab_bar) {
        this.$set(this.tabInfo, 'abName', '')
        this.$set(this.tabInfo, 'abData', [{
          label: "",
          value: "",
          config: {
            backgroundColor: "#fff",
            color: "#000",
            selectedColor: "#687BF2",
            popbackground: "#fff",
            popcolor: "#000",
            popSize: 12,
          },
          data: this.platform == 'wxapp' ? cloneDeep(DEFAULTTABBAR) : cloneDeep(DEFAULTDYTABBAR)
        }])
      } else {
        this.tabInfo = JSON.parse(res.tab_bar);
      }
    },
    dragStyle(index) {
      const indexs = this.collapseIndexs.indexOf(index)
      if (indexs !== -1) {
        return {
          height: 0,
          padding: 0,
          border: 'none'
        }
      }

    },
    async getExperimentList() {
      const { experiments } = await this.$api.abtest.getExperimentList({ name: '' })
      this.experimentList = [{ title: '无实验', value: '' }, ...pickBy(experiments, { title: 'name', value: 'id' })]
    },
    async handleChangeAbid(e) {
      this.abGroundId = ''
      if (!e) {
        this.$set(this.tabInfo, 'abName', '')
        this.$set(this.tabInfo, 'abData', [{
          label: "",
          value: "",
          config: {
            backgroundColor: "#fff",
            color: "#000",
            selectedColor: "#687BF2",
            popbackground: "#fff",
            popcolor: "#000",
            popSize: 12,
          },
          data: this.platform == 'wxapp' ? cloneDeep(DEFAULTTABBAR) : cloneDeep(DEFAULTDYTABBAR)
        }])
        return
      }
      const result = await this.getAbDetails(e)
      const abName = result[result.length - 1].name
      result.map(item => {
        item.data = this.platform == 'wxapp' ? cloneDeep(DEFAULTTABBAR) : cloneDeep(DEFAULTDYTABBAR)
      })
      this.$set(this.tabInfo, 'abData', result)
      this.$set(this.tabInfo, 'abName', abName)
    },
    async handleChangeAbGroundId(e) {
      this.abGroundId = e
      this.refresh = false
      this.$nextTick(() => {
        this.refresh = true
      })
    },
    async getAbDetails(id) {
      if (!id) return []
      const { experiment } = await this.$api.abtest.getExperimentDetail({ id })
      let options = pickBy(experiment.experiment_groups, {
        label: 'cname',
        value: 'group_variables[0].value',
        name: 'group_variables[0].name',
      })
      options = [{ label: '兜底组', value: '' }, ...options]
      const data = options.map(item => {
        return {
          label: item.label,
          value: item.value,
          name: item.name,
          config: {
            backgroundColor: "#fff",
            color: "#000",
            selectedColor: "#687BF2",
            popbackground: "#fff",
            popcolor: "#000",
            popSize: 12,
          },
          data: []
        }
      })
      return data
    },
    handleDelNav(index, key) {
      this.tabInfo.abData[index].data.splice(key, 1)
    },
    addNav(index) {
      if (this.tabInfo.abData[index].data.length >= 5) {
        this.$message.error('最多可添加5个')
        return
      }
      this.tabInfo.abData[index].data.push({
        pagePath: "",
        text: "",
        name: "",
        iconPath: "",
        selectedIconPath: "",
        data: null
      });
    },
    handleShowNav(index) {
      let indexs = this.collapseIndexs.indexOf(index)
      if (indexs == -1) {
        this.collapseIndexs.push(index)
      } else {
        this.collapseIndexs.splice(indexs, 1)
      }
    },
    handleChangeCurIndex(index) {
      this.curIndex = index
    },
    handleCopy(e, index) {
      const data = this.tabInfo.abData.find(item => item.value === e)
      this.$set(this.tabInfo.abData[index], 'data', cloneDeep(data.data))
      this.$set(this.tabInfo.abData[index], 'config', cloneDeep(data.config))
    },
    async handleClicikNav(ele, index, key) {
      const _data = ele?.data
      let otherData = []
      this.tabbar.data.forEach(item => {
        if (item.data && item.data?.id != _data?.id) {
          otherData.push(item.data)
        }
      })
      const { data } = await this.$picker.navmarket({ list: _data ? [_data] : [], multiple: false, selectList: otherData, platform: this.platform })
      ele.data = data[0] || null
      this.$set(this.tabInfo.abData[index].data, key, ele)
    },
    handleDelmarket(ele, index, key) {
      this.$set(this.tabInfo.abData[index].data[key], 'data', null)
    },
    setLabelName(ele) {
      if (ele?.data) {
        return `${ele.data.id}:${ele.data.promotion_name}`
      } else {
        return '选择营销'
      }
    },
    async saveTabInfo() {
      const params = {
        tab_bar: JSON.stringify(this.tabInfo),
        platform: this.platform,
        version: 2
      }
      await this.$api.template.setPagesTemplate(params)
      this.$message({
        message: "操作成功",
        type: "success"
      });
    },
    settingPlacement(i) {
      let size = this.tabbar.data.length
      const floorNum = Math.floor((size + 1) / 2);
      const ceilNum = Math.ceil((size + 1) / 2);
      let num = i + 1
      const style = {
        background: `${this.tabbar.config.popbackground}`,
        color: `${this.tabbar.config.popcolor}`,
        fontSize: `${this.tabbar.config.popSize}px`
      }
      if (floorNum == num || ceilNum == num) {
        return {
          left: '50%',
          transform: 'translateX(-50%)',
          ...style
        }
      } else if (num < floorNum) {
        let left = 375 / size * i
        return {
          left: '10px',
          maxWidth: `${355 - left}px`,
          ...style

        }
      } else {
        let right = 375 / size * (size - num)
        return {
          right: '10px',
          maxWidth: `${355 - right}px`,
          ...style
        }
      }
    },
    settingarrow(i) {
      let size = this.tabbar.data.length
      let left = 375 / size * 0.5 - 5
      return {
        left: `${left}px`,
        borderTopColor: `${this.tabbar.config.popbackground}`,
        color: `${this.tabbar.config.popcolor}`,
        fontSize: `${this.tabbar.config.popSize}px`
      }
    },
    handleChangeTab(tab, event) {
      this.abGroundId = ''
      this.curIndex = 0
      this.getTemplateSetInfo()
    },
    handleChangeColor(key, index, color) {
      this.$set(this.tabInfo.abData[index].config, key, color ? color : '')
    }
  },
  computed: {
    ...mapGetters(['login_type', 'admin_type']),
    popperClass() {
      return {
        padding: 0,

      }
    },
    filtetData() {
      return this.tabInfo.abData.filter(item => item.value !== this.abGroundId)
    },
    tabbar() {
      const _data = this.tabInfo.abData.find(item => item.value === this.abGroundId)
      return _data
    },
    visible(e) {
      return this.tabInfo.abData.length > 1
    },
    pathoptions() {
      return this.platform == 'wxapp' ? PATHOPTIONS : DYPATHOPTIONS
    }
  }

}
</script>
