import { render, staticRenderFns } from "./promoterSetting.vue?vue&type=template&id=6885ff9d"
import script from "./promoterSetting.vue?vue&type=script&lang=js"
export * from "./promoterSetting.vue?vue&type=script&lang=js"
import style0 from "./promoterSetting.vue?vue&type=style&index=0&id=6885ff9d&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports