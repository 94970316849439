<template>
  <div>
    <el-tabs v-model="activeName" type="border-card">
      <el-tab-pane label="PII配置" name="base">
        <div>
          是否开启PII&nbsp;&nbsp;&nbsp;&nbsp;
          <el-switch v-model="form.open_status" @change="changeSwitch" active-value="1" inactive-value="2">
          </el-switch>
        </div>
        <div v-show="showHide" style="margin-top: 10px" disabled>
          隐藏加密地址&nbsp;&nbsp;&nbsp;&nbsp;
          <el-switch v-model="form.hide_address" @change="changeSwitchHide" active-value="1" inactive-value="2" disabled>
          </el-switch>
        </div>
  </el-tab-pane>
    </el-tabs>
    <div style="text-align: center; margin-top: 20px">
      <el-button type="primary" @click="saveTcSetting">保存配置</el-button>
    </div>
  </div>
</template>
<script>

import { getPiiSetting, setPiiSetting } from '@/api/piiInfo'

export default {
  components: {
  },
  data() {
    return {
      form: {
        open_status: '1',
        hide_address: '1',
      },
      showHide:false,
      activeName: 'base'
    }
  },
  methods: {
    saveTcSetting() {
      setPiiSetting(this.form).then(res => {
        this.$message({ type: 'success', message: '保存成功' })
      })
    },
    getPiiSetting() {
      getPiiSetting().then(res => {
        let data = res.data.data
        this.form = {...data}
        if(this.form.open_status === '2'){
          this.showHide=true
        }else{
          this.showHide=false
        }
      })
    },
    changeSwitch(val) {
      this.form.open_status = val
      console.log(val);
      if(val === '2'){
        this.showHide=true
        this.form.hide_address ='1'
      }else{
        this.showHide=false
        this.form.hide_address ='2'

      }
      console.log(this.showHide);
    },
    changeSwitchHide(val) {
      this.form.hide_address = val
    }
  },
  mounted() {
    this.getPiiSetting()
  }
}
</script>
<style scoped type="text/css" lang="scss"></style>
