<template>
  <div class="">
    <el-row class="filter-header" :gutter="20">
      <el-col>
        <el-date-picker v-model="itemCreated" type="datetimerange" :picker-options="pickerOptions" start-placeholder="开始日期时间" ange-separator="至" end-placeholder="结束日期时间" value-format="timestamp" align="left" @change="datePicker" :default-time="['00:00:00', '23:59:59']" placeholder="选择日期时间范围">
        </el-date-picker>
        <el-select v-model="params.option_type" placeholder="全部接口" clearable @change="onSearch">
          <el-option v-for="item in options" clearable :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-button type="primary" @click="exportData">导出</el-button>
      </el-col>
    </el-row>

    <el-tabs v-model="params.status" @tab-click="onSearch">
      <el-tab-pane v-for="(item, index) in tabsList" :key="index" :label="item.label" :name="item.value">
        <el-table :data="tableList" style="width: 100%" v-loading="loading">
          <el-table-column prop="option_type" label="调用接口"></el-table-column>
          <el-table-column prop="type" label="调用用户类型"></el-table-column>
          <el-table-column prop="account" label="调用系统账号"></el-table-column>
          <el-table-column prop="option_scene" label="使用场景"></el-table-column>
          <el-table-column prop="status" label="调用结果">
            <template slot-scope="scope">
              <el-button v-if="scope.row.status == '1'" size="mini" type="primary">成功</el-button>
              <el-button v-if="scope.row.status == '2'" size="mini" type="danger">失败</el-button>
            </template>
          </el-table-column>
          <el-table-column prop="created" label="调用时间" width="200">
            <template slot-scope="scope">
              <span>{{ scope.row.created | datetime('yyyy-MM-dd hh:mm:ss') }}</span>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>

    <div class="pagination">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="params.page" :page-sizes="[10, 20, 30, 50]" :page-size="params.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total_count">
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { getPiiOptionType, getPiLog } from '@/api/piiInfo'

export default {
  data() {
    return {
      options: [],
      tableList: [],
      loading: false,
      total_count: 0,
      itemCreated: '',
      params: {
        page: 1,
        pageSize: 10,
        option_type: '',
        status: '',
        created: ''
      },
      time_start_begin: '',
      time_start_end: '',
      tabsList: [
        { label: '全部', value: '' },
        { label: '失败', value: '2' }
      ],
      pickerOptions: {
        shortcuts: [
          {
            text: '今日',
            onClick(picker) {
              const start = new Date();
              const end = new Date();
              start.setTime(new Date(new Date().toLocaleDateString()).getTime());
              end.setTime(new Date(new Date().toLocaleDateString()).getTime() + 3600 * 1000 * 24 - 1000);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '最近一周',
            onClick(picker) {
              const start = new Date();
              const end = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 6);
              end.setTime(end.getTime() - 3600 * 1000 * 24 * 0);
              picker.$emit('pick', [start, end]);
            }
          }]
      },
    }
  },
  methods: {
    optionList() {
      getPiiOptionType().then(res => {
        let data = res.data.data
        let arr = []
        for (let i in data)
          arr.push(
            {
              label: data[i],
              value: i
            }
          )
        this.options = arr
      })
    },
    handleSizeChange(page_size) {
      this.params.pageSize = page_size
      this.getDataList()
    },
    handleCurrentChange(page_num) {
      this.params.page = page_num
      this.getDataList()
    },
    datePicker(val) {
      if (val) {
        this.params.created = [val[0] / 1000, val[1] / 1000]
      } else {
        this.params.created = ''
      }
      this.onSearch()
    },
    onSearch() {
      this.params.page = 1
      this.getDataList()
    },
    getDataList() {
      this.loading = true
      getPiLog(this.params).then(response => {
        let data = response.data.data
        this.tableList = data.list
        this.total_count = data.total_count
        this.loading = false
      }) 
    },
    exportData() {
      let obg = { ...this.params }
      obg.export = true
      getPiLog(obg).then(response => {
        this.$message({
          message: '已加入执行队列，请在设置-导出列表中下载',
          type: 'success'
        });
      }) 
    }
  },
  mounted() {
    this.optionList()
    this.getDataList();
  }
}
</script>
<style lang="scss">
.pagination{
  margin-top: 20px;;
  text-align: center;
}
</style>
