<template>
  <div>
    <SpFinder no-selection ref="finder" :setting="setting" row-actions-width="430" :row-actions-align="'left'"
      :fixed-row-action="true" url="/pagestemplate/lists?page_type=custom_module" :hooks="{
        beforeSearch: beforeSearch,
        afterSearch: afterSearch
      }" :split-count="8">
      <template slot="tableTop">
        <el-tabs v-model="activeName" type="card" @tab-click="handleTabClick">
          <el-tab-pane :label="`全部(${examine_status_total_count})`" name="all"></el-tab-pane>
          <el-tab-pane :label="`待提交(${examine_status_before_count})`" name="waiting"></el-tab-pane>
          <el-tab-pane :label="`待审核(${examine_status_waiting_count})`" name="examine"></el-tab-pane>
          <el-tab-pane :label="`审核未通过(${examine_status_refuse_count})`" name="refuse"></el-tab-pane>
          <el-tab-pane :label="`审核已通过(${examine_status_pass_count})`" name="pass"></el-tab-pane>
        </el-tabs>
      </template>
    </SpFinder>
    <!-- 复制页面 -->
    <SpDialog ref="copyDialogRef" v-model="copyDialog" title="复制页面" :modal="false" :form="copyForm"
      :form-list="copyFormList" @onSubmit="onCopyFormSubmit" />
    <!-- 查看关联模板 -->
    <SpDialog class="view-template-dialog" v-model="viewTemplateDialog" title="查看关联模板" :modal="false"
      :form="viewTemplateForm" :form-list="viewTemplateFormList" @onSubmit="onViewTemplateFormSubmit" />
    <SpDialog ref="examineDialogRef" v-model="examineDialog" :title="examineTitle" :modal="false" :form="examineForm"
      :form-list="examineFormList" @onSubmit="handleExamineFormSubmit" />
  </div>
</template>

<script>
import { createSetting, useDialog } from '@shopex/finder'
import { addPagesTemplate } from '@/api/template'
import { EXAMINE_STATUS, EXAMINE_STATUS_TYPE, MODULETYPE } from '@/consts'
import { isObject, isArray } from '@/utils'

export default {
  data() {
    return {
      setting: createSetting({
        search: [{ name: '模块ID', key: 'pages_template_id' }, { name: '模块名称', key: 'template_title' }],
        columns: [
          { name: '模块ID', key: 'pages_template_id', minWidth: 80 },
          { name: 'AB实验组件', key: 'pendant_data', render: (h, { row }) => h('span', {}, this.setType(row.pendant_data, row.pages_template_id)), minWidth: 100 },
          { name: '模块名称', key: 'template_title', minWidth: 260 },
          {
            name: '审核状态',
            key: 'examine_status', minWidth: 100,
            render: (h, { row }) =>
              h(
                'ElTag',
                {
                  props: {
                    type: EXAMINE_STATUS_TYPE[row.examine_status]
                  }
                },
                EXAMINE_STATUS[row.examine_status]
              )
          },
          { name: '模版更新状态', key: 'replace_status', minWidth: 120 },
          { name: '最后更新时间', key: 'last_updated_time', minWidth: 160 },
          {
            name: '是否启用',
            key: 'status',
            showType: 'switch',
            componentProps: {
              inactiveValue: 2,
              activeValue: 1,
              change: (v, row) => {
                this.handleExamineTem(v, row)
              }
            }, width: 100
          },
        ],
        actions: [
          {
            name: '编辑',
            key: 'edit',
            type: 'button',
            buttonType: 'text',
            action: {
              handler: (row) => {
                this.handleDialog(row)
              }
            }
          },
          {
            name: '装修',
            key: 'detail',
            type: 'button',
            buttonType: 'text',
            action: {
              handler: (row) => {
                let includesAb = isArray(row[0].pendant_data) || isObject(row[0].pendant_data) && row[0].pendant_data.is_ab
                this.$router.push({
                  path: this.matchInternalRoute('wxapp/manage/decorate'),
                  query: {
                    scene: includesAb ? 1002 : 1012,
                    template_id: row[0].pages_template_id,
                  }
                })
              }
            }
          },
          {
            name: '查看关联模板',
            key: 'bind',
            type: 'button',
            buttonType: 'text',
            action: {
              handler: async (row) => {
                this.viewTemplateForm.pages_template_id = row[0].pages_template_id
                this.viewTemplateDialog = true
              }
            }
          },
          {
            name: '复制',
            key: 'bind',
            type: 'button',
            buttonType: 'text',
            action: {
              handler: async (row) => {
                this.copyForm.pages_template_id = row[0].pages_template_id
                this.copyForm.template_title = `${row[0].template_title}_复制`
                this.copyDialog = true
              }
            }
          },
          {
            name: '审核记录',
            key: 'edit',
            type: 'button',
            buttonType: 'text',
            action: {
              handler: (row) => {
                this.handleLogDialog(row)
              }
            }
          },
          {
            name: '提交审核',
            key: 'edit',
            type: 'button',
            buttonType: 'text',
            visible: (row) => {
              return row.examine_status == 1
            },
            action: {
              handler: (row) => {
                this.handleExamineDialog(row[0])
              }
            }
          },
          {
            name: '变更更新时间',
            key: 'edit',
            type: 'button',
            buttonType: 'text',
            visible: (row) => {
              return row.examine_status == 4 && row.update_cron_replace_time
            },
            action: {
              handler: (row) => {
                this.handleChangeExamineTimeDialog(row[0])
              }
            }
          },
          {
            name: '撤销审核',
            key: 'edit',
            type: 'button',
            buttonType: 'text',
            visible: (row) => {
              return row.examine_status == 2
            },
            action: {
              handler: (row) => {
                this.handleRevertExamineDialog(row[0])
              }
            }
          },
          {
            name: '新增模块',
            key: 'add',
            slot: 'header',
            type: 'button',
            buttonType: 'primary',
            size: 'small',
            action: {
              handler: () => {
                this.handleDialog()
              }
            }
          }
        ]
      }),
      copyDialog: false,
      copyForm: {
        pages_template_id: '',
        template_title: ''
      },
      copyFormList: [
        {
          label: '页面名称',
          key: 'template_title',
          type: 'input',
          maxlength: 150,
          placeholder: '请输入页面名称',
          required: true,
          message: '不能为空'
        }
      ],
      viewTemplateDialog: false,
      viewTemplateForm: {
        pages_template_id: '',
        templateList: []
      },
      viewTemplateFormList: [
        {
          key: 'templateList',
          type: 'component',
          component: () => {
            return (
              <SpFinder
                no-selection
                ref='tFinder'
                props={{
                  setting: {
                    columns: [
                      { name: 'ID', key: 'pages_template_id', width: 120 },
                      { name: '模块名称', key: 'template_title' }
                    ]
                  },
                  hooks: {
                    beforeSearch: (params) => {
                      const { pageSize: page_size, page: page_no } = params
                      const { pages_template_id } = this.viewTemplateForm
                      params = {
                        pages_template_id,
                        page_size,
                        page: page_no
                      }
                      return params
                    }
                  }
                }}
                url='/pagestemplate/customModule/template/list'
              />
            )
          }
        }
      ],
      examineDialog: false,
      examineForm: {
        is_auto_replace: 1,
        cron_replace_time: ''
      },
      examineFormList: [{
        label: '模块更新方式',
        key: 'is_auto_replace',
        component: ({ key }) => {
          return <el-radio-group v-model={this.examineForm[key]} size="small" onChange={(e) => {
            this.$set(this.examineForm, 'cron_replace_time', '')
          }}>
            <el-radio-button label="1">自动更新</el-radio-button>
            <el-radio-button label="2">定时更新</el-radio-button>
          </el-radio-group>
        }
      }, {
        label: '定时更新时间',
        key: 'cron_replace_time',
        isShow: () => {
          return this.examineForm.is_auto_replace == 2
        },
        component: ({ key }) => {
          return <el-date-picker
            v-model={this.examineForm[key]}
            type="datetime"
            picker-options={
              {
                disabledDate(time) {
                  return time.getTime() < Date.now() - 3600 * 1000 * 24;
                }
              }
            }
            value-format='timestamp'
            placeholder="选择日期时间">
          </el-date-picker>
        }
      }],
      examineDialog: false,
      examineTitle: '',
      examine_status_total_count: 0,
      examine_status_waiting_count: 0,
      examine_status_refuse_count: 0,
      examine_status_pass_count: 0,
      examine_status_before_count: 0,
      activeName: 'all',
    }
  },
  methods: {
    setType(pendant_data, id) {
      if (isObject(pendant_data)) {
        return !pendant_data.is_ab ? '否' : '是'
      } else {
        return ''
      }
    },

    async gotoDecorate(item) {
      const { pages_template_id, pendant_data } = item
      let types = []
      if (isObject(pendant_data)) {
        Object.keys(pendant_data).forEach(key => {
          if (pendant_data[key]) {
            types.push(key)
          }
        })
      }
      this.$router.push({
        path: this.matchInternalRoute('wxapp/manage/decorate'),
        query: {
          scene: 1002,
          template_id: pages_template_id,
          types: types.join(',')
        }
      })
    },
    hooks: () => {
      return {
        beforeSearch: (params) => {
          debugger
          const { pageSize: page_size, page: page_no, ...query } = params
          params = {
            ...query,
            page_size,
            page_no
          }
          return params
        }
      }
    },
    async onCopyFormSubmit() {
      const { pages_template_id, template_title } = this.copyForm
      await this.$api.template.copyPagesTemplate({
        pages_template_id,
        template_title
      })
      this.copyDialog = false
      this.$refs.finder.refresh()
    },
    onViewTemplateFormSubmit() {
      this.viewTemplateDialog = false
    },
    async handleExamineTem(v, row) {
      const { examine_status, status } = row
      if (examine_status != 4) {
        await this.$alert('该模块尚未通过审核！', '提示', {
          showConfirmButton: false,
          type: 'warning'
        }).catch(() => { })
        row.status = v == 1 ? 2 : 1
        return
      }
      this.$confirm(`确定${status == 2 ? '禁用' : '启用'}该模块吗？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          await this.$api.template.modifyPagesTemplateStatus({
            pages_template_id: row.pages_template_id,
            template_name: row.template_name,
            status: v
          })
          this.$message({
            type: 'success',
            message: '提交审核成功!'
          })
          this.$nextTick(() => {
            this.$refs.finder.refresh()
          })
        })
        .catch(() => {
          row.status = v == 1 ? 2 : 1
        })
    },
    async handleExamineFormSubmit() {
      const now = new Date();
      const halfHourLater = new Date(now.getTime());
      const timestap = Date.parse(halfHourLater)
      const { is_auto_replace, cron_replace_time, log_id, telId } = this.examineForm
      if (is_auto_replace == 2 && cron_replace_time < timestap) {
        this.$message.error('定时更新时间不能小于当前时间')
        this.$set(this.examineForm, 'cron_replace_time', '')
        return
      }
      if (log_id) {
        const { is_auto_replace, cron_replace_time, log_id } = this.examineForm
        await this.$api.template.updateReplaceTime({
          log_id,
          is_auto_replace,
          cron_replace_time: cron_replace_time / 1000,
        })
        this.$message({
          type: 'success',
          message: '更新时间成功!'
        })
        this.$nextTick(() => {
          this.examineDialog = false
          this.$refs.finder.refresh()
        })
        return
      }
      this.$confirm('确定提交审核？审核期间将不能编辑该模块。', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          await this.$api.template.submitExamineTemplate({
            template_id: telId,
            is_auto_replace,
            cron_replace_time: cron_replace_time / 1000
          })
          this.$message({
            type: 'success',
            message: '提交审核成功!'
          })
          this.$nextTick(() => {
            this.examineDialog = false
            this.$refs.finder.refresh()
          })
        })
    },

    beforeSearch(params) {
      const { pageSize: page_size, page: page_no, ...query } = params
      const status = {
        'all': '',
        'waiting': '1',
        'examine': '2',
        'refuse': '3',
        'pass': '4'
      }

      params = {
        ...query,
        page_size,
        page_no,
        examine_status: status[this.activeName]
      }
      return params
    },
    afterSearch(res) {
      const { data } = res.data
      this.examine_status_before_count = data.examine_status_before_count
      this.examine_status_total_count = data.examine_status_total_count
      this.examine_status_waiting_count = data.examine_status_waiting_count
      this.examine_status_refuse_count = data.examine_status_refuse_count
      this.examine_status_pass_count = data.examine_status_pass_count
      return data
    },
    handleDialog(row) {
      useDialog(null, {
        title: `模块`,
        width: '700px',
        field: row
          ? row[0]
          : {
            template_title: '', //模块名称
            template_pic: '', //模块图片
            page_type: 'custom_module', //固定值custom_module
            distributor_id: 0, //固定值0
            pages_template_id: '' //模块id，更新时必填
          },
        component: () => import('./comps/addTem.vue'),
        actions: [
          {
            label: '关闭',
            key: 'close',
            type: 'default',
            size: 'small'
          },
          { label: '确定', key: 'save', type: 'primary', size: 'small' }
        ]
      }).then(async (args) => {
        if (!args) return
        await addPagesTemplate(args)
        this.$message.success('操作成功')
        this.$nextTick(() => {
          this.$refs.finder.refresh()
        })
      })
    },
    handleLogDialog(row) {
      useDialog(null, {
        title: `审核记录`,
        id: row[0].pages_template_id,
        component: () => import('./comps/examineLog.vue'),
        actions: [
          {
            label: '关闭',
            key: 'close',
            type: 'default',
            size: 'medium'
          }
        ]
      })
    },
    handleExamineDialog(row) {
      this.examineForm = {
        is_auto_replace: 1,
        cron_replace_time: '',
        telId: row.pages_template_id,
      }
      if (row.status == 1) {
        this.examineDialog = true
        this.examineTitle = '提交审核'
      } else {
        this.handleExamineFormSubmit()
      }
    },
    // 变更更新时间
    async handleChangeExamineTimeDialog(row) {
      this.examineForm = {
        is_auto_replace: 1,
        cron_replace_time: '',
        log_id: row.log_id
      }
      this.examineTitle = '变更更新时间'
      this.examineDialog = true
    },
    async handleRevertExamineDialog(row) {
      this.$confirm('确定撤销审核？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          await this.$api.template.revertExamineTemplate({ log_id: row.log_id })
          this.$message({
            type: 'success',
            message: '撤销审核成功!'
          })
          this.$nextTick(() => {
            this.$refs.finder.refresh()
          })
        })
        .catch(() => { })
    },
    handleTabClick() {
      this.$refs.finder.refresh(true)
    },
  }
}
</script>

<style lang="scss" scoped></style>
<style lang="scss">
.sp-finder-hd {
  padding-top: 10px !important;
}

.view-template-dialog {
  .sp-finder-hd {
    display: none;
  }

  .el-dialog__body {
    padding: 0 !important;

    .el-form {
      margin: 0 !important;
    }

    .el-form-item {
      margin-bottom: 0 !important;
    }
  }
}
</style>
