<template>
  <div>
    <SpFinder no-selection ref="finder" :setting="setting" row-actions-width="430" :row-actions-align="'left'"
      :fixed-row-action="true" :split-count="6" url="/wxa/customizepageagreement/list" :hooks="{
        beforeSearch: beforeSearch,
      }">
      <template slot="tableTop">
        <el-tabs v-model="platform" type="card" @tab-click="handleTabPlatformClick">
          <el-tab-pane label="微信小程序" name="wxapp"></el-tab-pane>
          <el-tab-pane label="抖音小程序" name="byte_mini"></el-tab-pane>
        </el-tabs>
      </template>
    </SpFinder>
    <SpDialog ref="customPageDialogRef" v-model="customPageDialog" :title="customPageTitle" :modal="false"
      :form="customPage" :form-list="customPageList" @onSubmit="onCustomPageSubmit" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { createSetting, useDialog } from '@shopex/finder'
import { createCustomPage, editCustomPage } from '@/api/wxa'
import { CUSTOMPAGE_TYPE } from '@/consts'
export default {
  data() {
    return {
      setting: createSetting({
        search: [{ name: '页面ID', key: 'id' }, { name: '页面名称', key: 'page_name' }],
        columns: [
          { name: '页面ID', key: 'id', minWidth: 80 },
          { name: '页面名称', key: 'page_name', minWidth: 220 },
          {
            name: '页面类型', minWidth: 100,
            key: 'page_type',
            render: (h, { row }) =>
              h(
                'ElTag',
                {
                  props: {
                    type: 'info'
                  }
                },
                CUSTOMPAGE_TYPE.find(item => item.value == row.page_type)?.label
              )
          },
          {
            name: '是否启用',
            key: 'is_open', minWidth: 100,
            render: (h, { row }) =>
              h(
                'ElTag',
                {
                  props: {
                    type: row.is_open == '0' ? 'warning' : 'success'
                  }
                },
                row.is_open == '0' ? '禁用' : '启用'
              )
          },
        ],
        actions: [
          {
            name: '新增页面',
            key: 'add',
            slot: 'header',
            type: 'button',
            buttonType: 'primary',
            size: 'small',
            action: {
              handler: () => {
                this.handleAddCustomPage()
              }
            }
          },
          {
            name: '编辑',
            key: 'edit',
            type: 'button',
            buttonType: 'text',
            action: {
              handler: (row) => {
                this.handleEditCustomPage(row[0])
              }
            }
          },
          {
            name: '页面装修',
            key: 'detail',
            type: 'button',
            buttonType: 'text',
            action: {
              handler: (row) => {
                this.handleDecorationPage(row[0])
              }
            }
          },
        ]
      }),
      platform: 'wxapp',
      customPageTitle: '新增页面',
      customPage: {
        id: '',
        page_name: '',
        page_description: '',
        page_share_title: '',
        page_share_desc: '',
        page_share_imageUrl: '',
        page_imageUrl: '',
        is_open: true,
        sync_avarto: false,
        page_type: '',
        platform: ''
      },
      customPageList: [
        {
          label: '页面名称',
          key: 'page_name',
          type: 'input',
          maxlength: 30,
          placeholder: '请输入页面名称',
          required: true,
          message: '不能为空'
        }, {
          label: '使用平台',
          key: 'platform',
          component: ({ key }) => {
            return (
              <div>
                <el-input value={this.customPage[key] == 'wxapp' ? '微信小程序' : '抖音小程序'} disabled={true}></el-input>
              </div>
            )
          },
        }, {
          label: '页面描述',
          key: 'page_description',
          type: 'input',
          maxlength: 150,
          placeholder: '请输入页面描述',
        }, {
          label: '分享标题',
          key: 'page_share_title',
          type: 'input',
          maxlength: 150,
          placeholder: '请输入分享标题',
        }, {
          label: '页面类型',
          key: 'page_type',
          type: 'select',
          options: CUSTOMPAGE_TYPE,
          required: true,
          message: '页面类型不能为空'
        }, {
          label: '分享图片',
          key: 'page_share_imageUrl',
          component: ({ key }) => {
            return (
              <div on-click={() => this.handleImgChange()}>
                <SpImage src={this.customPage[key]} class="banner-uploader" />
              </div>
            )
          },
        }, {
          label: '是否启用',
          key: 'is_open',
          type: 'switch',
        }],
      customPageDialog: false,
    }
  },
  methods: {
    beforeSearch(params) {
      params = {
        ...params,
        page_type: ['private', 'useterm', 'ecprivate', 'register_vip_private'],
        platform: this.platform,
        getAll: true
      }
      return params
    },
    //切换抖音小程序还是微信小程序
    handleTabPlatformClick() {
      this.$refs.finder.refresh(true)
    },
    //选择分享图片
    async handleImgChange() {
      const { data } = await this.$picker.image({
        data: { url: this.customPage.page_share_imageUrl }
      })
      this.$set(this.customPage, 'page_share_imageUrl', data.url)
    },
    //新增/修改页面
    async onCustomPageSubmit() {
      if (this.customPage.id) {
        const { id, ...data } = this.customPage
        await editCustomPage(id, data)
      } else {
        await createCustomPage({ template_name: this.template_name, ...this.customPage })
      }
      this.$message({
        type: 'success',
        message: '保存页面成功'
      })
      this.customPageDialog = false
      this.$nextTick(() => {
        this.$refs.finder.refresh(true)
      })
    },
    //新增页面
    handleAddCustomPage() {
      this.customPage = {
        id: '',
        page_name: '',
        page_description: '',
        page_share_title: '',
        page_share_desc: '',
        page_share_imageUrl: '',
        page_imageUrl: '',
        is_open: true,
        sync_avarto: false,
        page_type: '',
        platform: this.platform
      }
      this.customPageDialog = true
      this.customPageTitle = '新增页面'
    },
    //编辑页面
    async handleEditCustomPage(row) {
      const { is_open, ...data } = row
      this.customPageDialog = true
      this.customPageTitle = '编辑页面'
      this.customPage = { is_open: is_open == 1, ...data }
      this.customPage.platform = this.platform
    },
    //页面装修
    async handleDecorationPage(row) {
      const { id, platform } = row
      if (platform === 'byte_mini') {
        this.$router.push({
          path: this.matchInternalRoute('wxapp/manage/decorate'),
          query: {
            scene: 1008,
            template_id: id,
            template_douyin: 1,
            iscustompage: 1
          }
        })
      } else {
        this.$router.push({
          path: this.matchInternalRoute('wxapp/manage/decorate'),
          query: {
            scene: 1005,
            template_id: id,
            iscustompage: 1
          }
        })
      }
    },
  },
  computed: {
    ...mapGetters(['wheight', 'template_name', 'login_type', 'admin_type'])
  },
}
</script>

<style lang="scss" scoped></style>
