import { createSetting } from '@shopex/finder'
import { MessageBox } from 'element-ui'
import { deleteItem } from '../api'

export const pageSetting = (vm) => createSetting({
  search: [
    { name: 'ID', key: 'id' },
    { name: '消息名称', key: 'msg_name' }
  ],
  columns: [
    { name: 'ID', key: 'id', width: 80 },
    { name: '消息名称', key: 'msg_name', minWidth: 120 },
    { name: '消息模板', key: 'msg_template_name', minWidth: 120 },
    {
      name: '消息类型',
      key: 'msg_type',
      minWidth: 120,
      formatter: (v) => {
        const options = {
          once: '一次性订阅',
          long: '长期订阅'
        }
        return options[v]
      }
    },
    {
      name: '适用平台',
      key: 'platform',
      minWidth: 120,
      formatter: (v) => {
        const options = {
          wxapp: '微信小程序',
          bytemin_app: '抖音小程序'
        }
        return options[v]
      }
    },
  ],
  actions: [
    {
      name: '创建消息通知',
      slot: 'header',
      type: 'button',
      buttonType: 'primary',
      action: {
        handler: () => {
          vm.$router.push({ path: vm.matchHidePage('operation') })
        }
      }
    },
    {
      name: '编辑',
      type: 'button',
      buttonType: 'text',
      action: {
        handler: (row) => {
          const id = row[0].id
          vm.$router.push({ path: vm.matchHidePage('operation'), query: { id } })
        }
      }
    },
    // {
    //   name: '删除',
    //   type: 'button',
    //   buttonType: 'text',
    //   action: {
    //     handler: async (row) => {
    //       MessageBox.confirm('请确认是否删除该消息？', '提示').then(async () => {
    //         const id = row[0].id
    //         await deleteItem(id)
    //         vm.$message.success('删除成功')
    //         vm.refresh()
    //       })
    //     }
    //   }
    // }
  ]
})
