<template>
  <div>
    <SpFinder ref="finder" title="导航营销" :setting="setting" row-actions-width="180" :row-actions-align="'left'"
      :fixed-row-action="true" url="navigation/promotion/list" :hooks="{
        beforeSearch: beforeSearch,
        afterSearch: afterSearch
      }">
    </SpFinder>
    <SpDialog class="nav-marketing-dialog" ref="navMarketingRef" v-model="showNavMarketing" :title="title"
      :form="navMarketing" :form-list="navMarketingList" @onSubmit="onNavMarketingSubmit" />
  </div>
</template>

<script>
import { MARKETTYPE, PLATFORM } from '@/consts'
import CompHotPicker from '@/view/decorate/comps/comp-hotpicker'
import { createSetting } from '@shopex/finder'
import CompPickerLink from '@/view/decorate/comps/comp-pickerLink'
export default {
  data() {
    return {
      setting: createSetting({
        search: [{ name: '营销名称', key: 'promotion_name', placeholder: "营销名称" }, { name: '营销ID', key: 'id', placeholder: "营销ID" }],
        columns: [
          { name: '营销ID', key: 'id', minWidth: 80 },
          { name: '营销类型', key: 'show_type', minWidth: 120, render: (h, { row }) => h('span', MARKETTYPE.find(item => item.label == row.show_type)?.name) },
          { name: '营销名称', key: 'promotion_name', minWidth: 240 },
          {
            name: '适用平台',
            key: 'platform',
            minWidth: 120,
            render: (h, { row }) => h('span', PLATFORM.find(item => item.label == row.platform)?.name)
          },
          { name: '有效期', key: 'start_time', minWidth: 240, render: (h, { row }) => h('span', `${row.start_time} 至 ${row.end_time}`) },

        ],
        actions: [
          {
            name: '创建导航营销',
            key: 'add',
            slot: 'header',
            type: 'button',
            buttonType: 'primary',
            size: 'small',
            action: {
              handler: () => {
                this.handleAddnavMarketing()
              }
            }
          }, {
            name: '编辑',
            key: 'edit',
            type: 'button',
            buttonType: 'text',
            action: {
              handler: (row) => {
                this.handleEditnavMarketing(row[0])
              }
            }
          },
          {
            name: '删除',
            key: 'edit',
            type: 'button',
            buttonType: 'text',
            action: {
              handler: async (row) => {
                await this.$api.template.deleteMarketing({ id: row[0].id })
                this.$refs.finder.refresh()
              }
            }
          },
        ]
      }),
      title: '',
      showNavMarketing: false,
      navMarketing: {
        promotion_name: '',
        marketTime: [],
        platform: '',
        show_type: 1,
        show_content: '',
        page_setting: null
      },
      show: true

    }
  },
  methods: {
    beforeSearch(params) {
      const { pageSize: page_size, ...query } = params
      params = {
        ...query,
        page_size,
        platform: ''
      }
      // this.platform
      return params
    },
    afterSearch(res) {
      return res.data
    },
    handleAddnavMarketing(e) {
      this.showNavMarketing = true
      this.title = '创建营销'
      this.navMarketing = {
        promotion_name: '',
        marketTime: [],
        platform: 'wxapp',
        show_type: 1,
        show_content: '',
        page_setting: null,
        start_time: '',
        end_time: ''
      }
    },
    handleEditnavMarketing(e) {
      this.showNavMarketing = true
      this.title = '编辑营销'
      const { id, promotion_name, start_time, end_time, platform, show_type, show_content, page_setting } = e
      this.navMarketing = {
        id,
        promotion_name,
        marketTime: [start_time, end_time],
        platform,
        show_type,
        show_content,
        page_setting: JSON.parse(page_setting),
        start_time,
        end_time
      }
    },
    async onNavMarketingSubmit() {
      const { page_setting, ...data } = this.navMarketing
      const res = await this.$api.template.createOrUpdateMarketing({ ...data, page_setting: JSON.stringify(page_setting) })
      this.$message.success('操作成功')
      this.showNavMarketing = false
      this.$refs.finder.refresh()
    },
    handleSelectTime(e) {
      if (!e) {
        this.navMarketing.start_time = ''
        this.navMarketing.end_time = ''
      } else {
        this.navMarketing.start_time = e[0]
        this.navMarketing.end_time = e[1]
      }

    },
    handleClear() {
      this.show = false
      this.$set(this.navMarketing, 'page_setting', null)
      this.$nextTick(() => {
        this.show = true
      })
    }
  },
  computed: {
    navMarketingList() {
      const { show } = this
      const {platform,page_setting} = this.navMarketing
      return [{
        label: '营销名称',
        key: 'promotion_name',
        type: 'input',
        maxlength: 30,
        placeholder: '请输入页面名称',
        required: true,
        message: '页面名称不能为空'
      },
      {
        label: '营销时间',
        key: 'marketTime',
        required: true,
        message: '营销时间不能为空',
        component: ({ key }) => {
          return <el-date-picker
            v-model={this.navMarketing[key]}
            picker-options={
              {
                disabledDate(time) {
                  return time.getTime() < Date.now() - 3600 * 1000 * 24;
                }
              }
            }
            on-change={(e) => this.handleSelectTime(e)}
            type="datetimerange"
            value-format="yyyy-MM-dd HH:mm:ss"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        }
      },
      {
        label: '适用平台',
        key: 'platform',
        type: 'radio',
        options: PLATFORM,
      }, {
        label: '营销形式',
        key: 'show_type',
        type: 'radio-button',
        options: MARKETTYPE,
      }, {
        label: '气泡信息',
        key: 'show_content',
        type: 'input',
        maxlength: 30,
        placeholder: '请输入气泡信息',
        required: true,
        message: '气泡信息不能为空'
      }, {
        label: '路径设置',
        key: 'page_setting',
        component: ({ key }) => {
          return (show && <div class='path-setting'>
            <CompHotPicker platform={platform} isShowH5Link={false} hiddenbtntype={true} v-model={this.navMarketing[key]} />
            {/* <el-button size='mini' type='text' onClick={() => this.handleClear()}>清除</el-button> */}
          </div>)
        }
      }
      ]
    }
  },

}
</script>

<style lang="scss" scoped></style>

<style lang="scss">
.nav-marketing-dialog {
  .sp-form .el-form-item {
    margin-bottom: 15px !important;
  }

  .el-form-item__error {
    padding-top: 0px !important;
  }

  .path-setting {
    // width: 240px;
    // height: 40px;
    // box-sizing: border-box;
    // padding: 10px;
    // border: 1px solid #DCDFE6;
    // border-radius: 4px;
    // display: flex;
    // align-items: center;
    // cursor: pointer;
  }
}
</style>
