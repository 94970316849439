<template>
  <div class="comp-option">
    <div class="second-hd" @click="optionClick" v-if="(curlevel == 2 || curlevel == 3) && data.parent_answer_info">
      <div class="hd">
        <div class="hd-title">关联{{ curlevel == 2 ? '一' : '二' }}级问卷</div>
        <el-tooltip class="item" effect="dark" :content="data.parent_answer_info.subject" placement="bottom-start">
          <div class="hd-content">{{ data.parent_answer_info.subject|subStr(20) }}</div>
        </el-tooltip>
      </div>
      <div class="hd">
        <div class="hd-title">关联{{ curlevel == 2 ? '一' : '二' }}级问卷选项</div>
        <el-tooltip class="item" effect="dark" :content="data.parent_answer_info.content" placement="bottom-start">
          <div class="hd-content">{{ data.parent_answer_info.content|subStr(20) }}</div>
        </el-tooltip>
      </div>
    </div>
    <div class="option-hd" @click="optionClick">
      <div class="hd">
        <div class="hd-title">问卷标题</div>
        <div class="hd-content">{{ data.subject }}</div>
      </div>
      <el-button style="float: right;" plain size="mini" type="primary" v-show="data.qu_type == '1'">单选项</el-button>
      <el-button style="float: right;" plain size="mini" type="success" v-show="data.qu_type == '2'">多选项</el-button>
      <el-button style="float: right;" plain size="mini" type="danger" v-show="data.qu_type == '3'">输入框</el-button>
    </div>
    <div class="option-body" v-if="data.qu_type == '1' || data.qu_type == '2'">
      <div class="hd-title">问卷选项</div>
      <el-radio-group v-model="radioValue" size="mini" class="comp-option-radio">
        <el-tooltip v-for="c_item in data.answer"  :key="c_item.answer_id" class="item" effect="dark" :content="c_item.content" placement="bottom">
          <el-radio  :label="c_item.answer_id" border @click.native.prevent="radioChange(c_item)">
            {{ c_item.content|subStr(20) }}
          </el-radio>
        </el-tooltip>
      </el-radio-group>
      <!-- <el-checkbox-group v-model="checkboxValue" size="mini" v-if="data.qu_type == '2'" :max="maxSelect" @change="selectChange" class="comp-option-checkbox">
        <el-checkbox v-for="c_item in data.answer" :label="c_item.content" :key="c_item.answer_id" border></el-checkbox>
      </el-checkbox-group> -->
    </div>
    <div class="option-foot">
      <el-popover
        placement="top"
        width="300"
        popper-class="popper-class"
        @show="showChange"
        v-model="popoverVisible">
        <el-input v-model="sortValue" placeholder="请输入调研选项" style="width:90%;margin:15px;" class="option" />
        <div style="text-align: right; margin: 0">
          <el-button size="mini" type="text" @click="popoverVisible = false">取消</el-button>
          <el-button type="primary" size="mini" @click="onScopedChange({ type: 'sort', value: sortValue })">确定</el-button>
        </div>
        <el-button class="left" slot="reference" type="text" icon="el-icon-edit-outline option-edit">排序: {{ data.sort }}</el-button>
      </el-popover>
      <!-- <div class="left" @click="onScopedChange('sort')">排序：{{ data.sort }}<el-icon class="el-icon-edit-outline option-edit"></el-icon></div> -->
      <div class="right">
        <el-button type="text" @click="onScopedChange({ type: 'edit' })">编辑</el-button>
        <el-button type="text" @click="onScopedChange({ type: 'delete' })" class="del">删除</el-button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CompOption',
  props: {
    curlevel: {
      type: Number,
      default: 1
    },
    data: {
      type: Object,
      default: {}
    },
    select: {
      type: Number | String,
      default: null
    },
    sid: {
      type: String,
      default: ''
    }
  },
  computed: {
  },
  watch: {
    select (nVal, oVal) {
      this.radioValue = nVal
    }
  },
  data () {
    return {
      radioValue: null,
      maxSelect: 1000,
      popoverVisible: false,
      sortValue: ''
    }
  },
  methods: {
    optionClick () {
      this.$emit('onChange', { value: this.data.id, type: 'parent' })
    },
    radioChange (c_item) {
      // if ((this.curlevel == 2 || this.curlevel == 3) && !this.sid) return
      if (c_item.answer_id == this.radioValue) {
        this.radioValue = null
      } else {
        this.radioValue = c_item.answer_id
      }
      this.$emit('onChange', { value: this.radioValue, type: 'son' })
    },
    onScopedChange (obj) {
      this.popoverVisible = false
      this.$emit('onScoped', obj)
    },
    showChange () {
      this.sortValue = this.data.sort
    }
  }
}
</script>

<style lang="scss">
.comp-option {
  .second-hd {
    display: flex;
    flex-direction: column;
    padding: 20px 20px 0px 20px;
    background: #efefef;
    .hd {
      margin-bottom: 15px;
    }
  }
  .option-hd {
    cursor: pointer;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 20px 20px 0px 20px;
    margin-bottom: 20px;
    .hd {
      width: 77%;
    }
  }
  .option-body {
    padding: 0px 20px 20px 20px;
  }
  .hd-title {
    color: #A4A6A5;
    font-weight: 400;
  }
  .hd-content {
    margin-top: 3px;
  }
  .comp-option-checkbox, .comp-option-radio {
    text-align: center;
    width: 100%;
    .el-checkbox, .el-radio {
      display: block;
      text-align: center;
      margin: 0 auto 10px !important;
      // width: 90%;
      border-radius: 5px;
      &:first-child {
        margin-top: 10px !important;
      }
      .el-checkbox__inner, .el-radio__inner {
        display: none;
      }
    }
  }
  .option-foot {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px 0px 20px;
    border-top: 1px solid #ebeef5;
    .left {
      display: flex;
      align-items: center;
    }
    .right {
      .del {
        margin-left: 10px;
      }
    }
    .option-edit {
      color: #409EFF;
      margin-left: 10px;
      font-size: 18px;
    }
  }
}
</style>
