<template>
  <div class="custom-message-operation" v-loading="loading">
    <el-form ref="form" :model="formModel" :rules="formRules" label-width="120px"  label-position="left">
      <el-form-item label="消息名称" prop="msg_name">
        <el-input type="text" v-model="formModel.msg_name" />
      </el-form-item>
      <el-form-item label="消息模板" prop="msg_template">
        <el-checkbox-group v-model="formModel.msg_template">
          <el-checkbox v-for="item in tempOptions" :label="item.value" :key="item.value">
            {{ item.label }}
          </el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="模板内容">
        <div class="temp-content">
          <div class="temp-content-left" v-if="formModel.msg_template.includes('presaleStartNotice')">
            <el-form-item label="开始推送时间" prop="presaleStartNotice.send_start_time">
              <el-date-picker
                v-model="formModel.presaleStartNotice.send_start_time"
                type="datetime"
                value-format="timestamp"
                placeholder="选择日期时间"
                :picker-options="pickerOptions"
              />
            </el-form-item>
            <div class="temp-content-wrapper">
              <div class="temp-content-wrapper-title">
                <span class="cell-start">*</span>
                预售开始提醒
              </div>
              <div class="temp-content-wrapper-content">
                <el-form-item label="商品" label-width="100px" prop="presaleStartNotice.item_name">
                  <el-input type="text" v-model="formModel.presaleStartNotice.item_name" />
                </el-form-item>
                <el-form-item label="预售时间" label-width="100px" prop="presaleStartNotice.presale_time">
                  <el-date-picker
                    v-model="formModel.presaleStartNotice.presale_time"
                    type="datetime"
                    value-format="timestamp"
                    placeholder="选择日期时间"
                    :picker-options="pickerOptions"
                  />
                </el-form-item>
              </div>
              <div class="temp-content-wrapper-bottom">
                <div>{{ getLabelName(formModel.presaleStartNotice.localValue) }}</div>
                <div class="set-path" @click="handleSetPath('presaleStartNotice')">设置路径</div>
              </div>
            </div>
            <el-form-item label="结束推送时间">
              <el-date-picker
                v-model="formModel.presaleStartNotice.send_end_time"
                type="datetime"
                value-format="timestamp"
                placeholder="选择日期时间"
                :picker-options="pickerOptions"
              />
            </el-form-item>
          </div>
          <div class="temp-content-right" v-if="formModel.msg_template.includes('newProductSaleStartNotice')">
            <el-form-item label="开始推送时间" prop="newProductSaleStartNotice.send_start_time">
              <el-date-picker
                v-model="formModel.newProductSaleStartNotice.send_start_time"
                type="datetime"
                value-format="timestamp"
                placeholder="选择日期时间"
                :picker-options="pickerOptions"
              />
            </el-form-item>
            <div class="temp-content-wrapper">
              <div class="temp-content-wrapper-title">
                <span class="cell-start">*</span>
                新品开售提醒
              </div>
              <div class="temp-content-wrapper-content">
                <el-form-item label="新品名称" label-width="100px" prop="newProductSaleStartNotice.item_name">
                  <el-input type="text" v-model="formModel.newProductSaleStartNotice.item_name" />
                </el-form-item>
                <el-form-item label="开售时间" label-width="100px" prop="newProductSaleStartNotice.sale_time">
                  <el-date-picker
                    v-model="formModel.newProductSaleStartNotice.sale_time"
                    type="datetime"
                    value-format="timestamp"
                    placeholder="选择日期时间"
                    :picker-options="pickerOptions"
                  />
                </el-form-item>
                <el-form-item label="温馨提示" label-width="100px" prop="newProductSaleStartNotice.remark">
                  <el-input type="text" v-model="formModel.newProductSaleStartNotice.remark" />
                </el-form-item>
              </div>
              <div class="temp-content-wrapper-bottom">
                <div class="set-name">{{ getLabelName(formModel.newProductSaleStartNotice.localValue) }}</div>
                <div class="set-path" @click="handleSetPath('newProductSaleStartNotice')">设置路径</div>
              </div>
            </div>
            <el-form-item label="结束推送时间">
              <el-date-picker
                v-model="formModel.newProductSaleStartNotice.send_end_time"
                type="datetime"
                value-format="timestamp"
                placeholder="选择日期时间"
                :picker-options="pickerOptions"
              />
            </el-form-item>
          </div>
        </div>
      </el-form-item>
    </el-form>
    <FooterSubmitButtons @cancel="hanldeCancel" @submit="handleSubmit"/>
  </div>
</template>

<script>
import { create, update, detail } from './api'
import { LINK_PATH } from '@/consts'
import { mapGetters } from 'vuex'
import FooterSubmitButtons from './components/footer-submit-buttons'

export default {
  components: { FooterSubmitButtons },
  data () {
    return {
      loading: false,
      id: null,
      formModel: {
        msg_name: '',
        msg_template: ['presaleStartNotice', 'newProductSaleStartNotice'],
        presaleStartNotice: {
          template_type: 'presaleStartNotice',
          send_start_time: null,
          send_end_time: null,
          path: '',
          item_name: '',
          presale_time: null,
          localValue: {},
        },
        newProductSaleStartNotice: {
          template_type: 'newProductSaleStartNotice',
          send_start_time: null,
          send_end_time: null,
          path: '',
          item_name: '',
          remark: '',
          sale_time: null,
          localValue: {}
        },
      },
      formRules: {
        msg_name: [
          { required: true, message: '请输入消息名称', trigger: 'blur' },
        ],
        msg_template: [
          { required: true, message: '请选择消息模板', trigger: 'blur' },
        ],
        'presaleStartNotice.send_start_time': [
          { required: true, message: '请选择时间', trigger: 'blur' },
        ],
        'presaleStartNotice.item_name': [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
        'presaleStartNotice.presale_time': [
          { required: true, message: '请选择时间', trigger: 'blur' },
        ],
        'newProductSaleStartNotice.send_start_time': [
          { required: true, message: '请选择时间', trigger: 'blur' },
        ],
        'newProductSaleStartNotice.item_name': [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
        'newProductSaleStartNotice.remark': [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
        'newProductSaleStartNotice.sale_time': [
          { required: true, message: '请选择时间', trigger: 'blur' },
        ],
      },
      tempOptions: [
        { label: '预售开始提醒', value: 'presaleStartNotice' },
        { label: '新品开售提醒', value: 'newProductSaleStartNotice' },
      ],
      tempOptionsEnum: {
        presaleStartNotice: '预售开始提醒',
        newProductSaleStartNotice: '新品开售提醒'
      },
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() < Date.now() - 8.64e7
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      'wheight',
      'wxapp_id',
      'template_name'
    ])
  },
  async mounted() {
    const id = this.$route.query.id
    if(id) {
      this.id = id
      this.initDetail()
    }
  },
  methods: {
    async initDetail() {
      try {
        this.loading = true
        const { data : { data }} = await detail(this.id)
        const { msg_name, msg_template, msg_template_content } = data
        this.formModel.msg_name = msg_name
        this.formModel.msg_template = msg_template
        msg_template_content.forEach(item => {
          const fitem = this.formatTimeBy1000('up', item)
          const key = fitem.template_type
          this.formModel[key] = {
            ...fitem
          }
        })
      } finally {
        this.loading = false
      }
    },
    getLabelName(value) {
      const { linkPage, title, id } = value
      if (linkPage) {
        return `${LINK_PATH[linkPage]}：${id ? '[' + id + ']' : ''} ${title}`
      } else {
        return ''
      }
    },
    async handleSetPath(key) {
      const { linkPage, id, ...ret } = this.formModel[key].localValue
      const res = await this.$picker.path({
        data: id,
        tab: linkPage,
        multiple: false,
        wxapp_id: this.wxapp_id,
        template_name: this.template_name,
        isNotice: true
      })
      this.formModel[key].localValue = {
        ...res,
      }
      this.formModel[key].path = JSON.stringify(this.formModel[key].localValue)
    },
    /**
     * @desc 根据时间戳格式化到s
     * @param {String} type up *1000 |  down /1000
     * @param {Object} data
    */
    formatTimeBy1000(type, obj) {
      switch (type) {
        case 'up':
          if(obj.send_start_time) obj.send_start_time = Number(obj.send_start_time) * 1000
          if(obj.send_end_time) obj.send_end_time = Number(obj.send_end_time) * 1000
          if(obj.presale_time) obj.presale_time = Number(obj.presale_time) * 1000
          if(obj.sale_time) obj.sale_time = Number(obj.sale_time) * 1000
          return obj
        case 'down':
          if(obj.send_start_time) obj.send_start_time = Number(obj.send_start_time) / 1000
          if(obj.send_end_time) obj.send_end_time = Number(obj.send_end_time) / 1000
          if(obj.presale_time) obj.presale_time = Number(obj.presale_time)  / 1000
          if(obj.sale_time) obj.sale_time = Number(obj.sale_time)  / 1000
          return obj
      }
    },
    hanldeCancel() {
      this.$router.go(-1)
    },
    async handleSubmit() {
      try {
        await this.$refs.form.validate()
        const { msg_name, msg_template }  = this.formModel
        let msg_template_content = []
        for (const key of msg_template) {
          let item = this.formModel[key]
          const title = this.tempOptionsEnum[key]
          const { send_start_time, presale_time, sale_time, send_end_time, path } = item
          const curtimestamp = Date.now()
          if(send_start_time < curtimestamp) {
            this.$message.error(`${title}的开始推送时间需大于当前时间`)
            return
          }
          if(presale_time && presale_time < curtimestamp) {
            this.$message.error(`${title}的预售时间需大于当前时间`)
            return
          }
          if(sale_time && sale_time < curtimestamp) {
            this.$message.error(`${title}的开售时间需大于当前时间`)
            return
          }
          if(send_end_time && send_end_time < send_start_time) {
            this.$message.error(`${title}的结束推送时间需大于开始推送时间`)
            return
          }
          if(!path) {
            this.$message.error(`${title}的跳转路径不能为空`)
            return
          }
          item = this.formatTimeBy1000('down', {...item})
          msg_template_content.push(item)
        }
        const data = {
          msg_name,
          msg_template,
          msg_template_content
        }
        this.loading = true
        if(this.id) {
          data.id = this.id
          await update(data)
        } else {
          await create(data)
        }
        this.$message.success('保存成功')
        this.hanldeCancel()
      } finally{
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.custom-message-operation {
  position: relative;
  ::v-deep .el-input {
    width: 250px;
  }
  .temp-content {
    display: flex;
    flex-wrap: nowrap;
    .cell-start {
      color: #F56C6C;
    }
    &-left {
      width: 35%;
      min-width: 450px;
    }
    &-right {
      width: 35%;
      min-width: 450px;
      margin-left: 24px;
    }
    &-wrapper {
      border: 1px solid #e7e7eb;
      margin: 24px 0;
      &-title {
        padding: 0 12px;
        background-color: #edfefc;
        border-bottom: 1px solid #e7e7eb;
      }
      &-content {
        height: 200px;
        padding: 12px;
        display: flex;
        flex-direction: column;
        .el-form-item {
          margin: 10px 0;
        }
        ::v-deep .el-form-item__label:before {
          display: none;
        }
      }
      &-bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid #e7e7eb;
        padding: 0 12px;
        background-color: #edfefc;
        .set-name {
          width: calc(100% - 40px - 24px);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .set-path {
          color: #409eff;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
