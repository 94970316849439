<template>
  <div>
    <div v-if="$route.path.indexOf('_detail') === -1">
      <el-row v-if="login_type == 'disti'" :gutter="20">
        <el-col :span="4">
          <el-button type="primary" icon="plus" @click="openDialog()"
            >添加页面</el-button
          >
        </el-col>
      </el-row>
      <el-table
        :stripe="true"
        :data="list"
        :height="wheight - 140"
        v-loading="loading"
      >
        <el-table-column
          prop="id"
          label="页面id"
          width="110px"
        ></el-table-column>
        <el-table-column prop="page_name" label="页面名称"></el-table-column>

        <!--
        <el-table-column label="审核状态" width="150px">
          <template slot-scope="scope">
            <el-tag type="info" v-if="scope.row.examine_status == '1'"
              >待提交</el-tag
            >
            <el-tag v-else-if="scope.row.examine_status == '2'">审核中</el-tag>
            <el-tag type="danger" v-else-if="scope.row.examine_status == '3'"
              >审核未通过</el-tag
            >
            <el-tag type="success" v-else>审核通过</el-tag>
          </template>
        </el-table-column>
        -->

        <el-table-column label="页面类型" width="150px">
          <template slot-scope="scope">
            <el-tag type="info" v-if="scope.row.page_type == 'ec'">EC自定义</el-tag>
          </template>
        </el-table-column>

        <el-table-column label="是否启用">
          <template slot-scope="scope">
            <el-tag type="warning" v-if="scope.row.is_open == '0'">禁用</el-tag>
            <el-tag type="success" v-else>启用</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" min-width="60px" max-width="200px">
          <template slot-scope="scope">
            <div>
              <el-button  v-if="login_type == 'disti'" type="text" @click="openDialog(scope.row)"
                >编辑</el-button
              >
              <!-- <el-button
                type="text"
                class="op_btn"
                @click="delPage(scope.row.id)"
                >删除</el-button
              > -->
              <el-button
                type="text"
                class="op_btn"
                v-if="login_type == 'disti'"
                @click="temDialog(scope.row.id)"
                >页面装修</el-button
              >
              <!--
                <el-button
                class="op_btn"
                type="text"
                @click="handleExamineLog(scope.row)"
                >审核记录</el-button
              >
              <span v-if="login_type == 'disti'">
                <el-button
                  class="op_btn"
                  v-if="scope.row.examine_status == '1'"
                  type="text"
                  @click="submitExamine(scope.row)"
                  >提交审核</el-button
                >
              </span>
              <span
                v-else-if="login_type === 'admin' || login_type === 'staff'"
              >
                <el-button
                  class="op_btn"
                  v-if="scope.row.examine_status == '2'"
                  type="text"
                  @click="handleExamineLog(scope.row)"
                  >审核</el-button
                >
              </span>
              -->

            </div>
          </template>
        </el-table-column>
      </el-table>

      <div
        v-if="total_count > params.pageSize"
        class="content-padded content-center"
      >
        <el-pagination
          background
          layout="prev, pager, next"
          @current-change="handleCurrentChange"
          :current-page.sync="params.page"
          :total="total_count"
          :page-size="params.pageSize"
        ></el-pagination>
      </div>

      <el-dialog
        :title="dialogTitle"
        :visible.sync="page_dialog"
        :close-on-click-modal="false"
        :before-close="handleCancel"
      >
        <el-form v-model="pageForm" label-width="200px">
          <el-form-item label="页面名称">
            <el-input
              v-model="pageForm.page_name"
              placeholder="页面名称"
              style="width: 55%"
            ></el-input>
          </el-form-item>
          <el-form-item label="页面描述">
            <el-input
              v-model="pageForm.page_description"
              placeholder="页面描述"
              style="width: 55%"
            ></el-input>
          </el-form-item>
          <el-form-item label="分享标题">
            <el-input
              v-model="pageForm.page_share_title"
              placeholder="分享标题"
              style="width: 55%"
            ></el-input>
          </el-form-item>
          <el-form-item label="页面类型">
          <el-select clearable v-model="pageForm.page_type" placeholder="选择页面类型" @change="numberSearch()">
            <el-option
              v-for="item in page_type"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          </el-form-item>
          <!-- <el-form-item label="分享描述">
            <el-input v-model="pageForm.page_share_desc" placeholder="分享描述" style="width: 55%;"></el-input>
          </el-form-item> -->
          <el-form-item label="分享图片">
            <div @click="handleImgChange()" class="upload-box">
              <img
                v-if="pageForm.page_share_imageUrl"
                :src="wximageurl + pageForm.page_share_imageUrl"
                class="avatar"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </div>
            <imgPicker
              :dialog-visible="imgDialog"
              :sc-status="isGetImage"
              @chooseImg="pickImg"
              @closeImgDialog="closeImgDialog"
            ></imgPicker>
          </el-form-item>
          <el-form-item label="是否启用">
            <el-switch v-model="pageForm.is_open"></el-switch>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer content-center">
          <el-button type="primary" @click="savePage">确认保存</el-button>
        </div>
      </el-dialog>

      <el-dialog
        :visible.sync="template_dialog"
        width="80%"
        title="编辑页面"
        fullscreen
        lock-scroll
      >
        <shopDecoration
          :id="pageForm.id"
          @saved="closeDialog"
          usage="page"
          :template_name="template_name"
        />
      </el-dialog>
    </div>
    <router-view></router-view>

    <ExamineTemplate
      @closeDialog="closeExamineDialog"
      :examineDialogVisible="examineDialogVisible"
      :templateId="selectTemplate.id"
      templateType="custompage"
    ></ExamineTemplate>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import {
  getCustomPageAgreementList,
  // getCustomPageList,
  createCustomPage,
  delCustomPage,
  editCustomPage
} from '@/api/wxa'
import ExamineTemplate from '@/components/function/examineTemplate'
import { submitExamineTemplate } from '@/api/template'
import shopDecoration from '@/components/function/shopDecoration'
import imgPicker from '@/components/imageselect'
export default {
  components: {
    shopDecoration,
    imgPicker,
    ExamineTemplate
  },
  data () {
    return {
      selectTemplate: {},
      examineDialogVisible: false,
      imgDialog: false,
      isGetImage: false,
      template_dialog: false,
      page_dialog: false,
      total_count: 0,
      dialogTitle: '新增页面',
      loading: false,

      page_page_type: 0,

      page_type: [
        {
          value: 'ec',
          label: 'Ec自定义'
        }
      ],

      page_type_list: ['useterm','private'],
      params: {
        page: 1,
        pageSize: 20,
        page_type: ['ec'],
        getAll: true

      },
      pageForm: {
        id: '',
        page_name: '',
        page_description: '',
        page_share_title: '',
        page_share_desc: '',
        page_share_imageUrl: '',
        page_type: '',
        is_open: false
      },
      list: []
    }
  },
  computed: {
    ...mapGetters(['wheight', 'template_name', 'login_type'])
  },
  methods: {
    closeExamineDialog () {
      this.examineDialogVisible = false
      this.fetchPageList()
    },

    handleExamineLog (item) {
      this.selectTemplate = item
      this.examineDialogVisible = true
    },

    temDialog (id, type) {
      this.$router.push({
        path: this.matchInternalRoute('wxapp/manage/decorate'),
        query: {
          scene: 1005,
          template_id: id,
          iscustompage:1
        }
      })
      // this.pageForm.id = id
      // this.template_dialog = true
    },
    closeDialog () {
      this.template_dialog = false
    },
    handleCurrentChange (page_num) {
      this.params.page = page_num
      this.fetchPageList()
    },
    delPage (id) {
      this.$confirm('确认删除当前页面吗？').then(_ => {
        delCustomPage(id).then(res => {
          this.$message({ type: 'success', message: '操作成功！' })
          this.fetchPageList()
        })
      })
    },
    openDialog (detail = null) {
      this.page_dialog = true
      if (detail) {
        this.pageForm = detail
        if (detail.is_open == 1) {
          this.pageForm.is_open = true
        }
        this.dialogTitle = '编辑页面'
      } else {
        this.dialogTitle = '新增页面'
        this.pageForm = {
          id: '',
          page_name: '',
          page_description: '',
          page_share_title: '',
          page_share_desc: '',
          page_share_imageUrl: '',
          is_open: false
        }
      }
    },

    numberSearch(e) {
    },

    savePage () {
      let { page_name, page_description, page_share_title, page_share_desc, page_share_imageUrl, is_open, id, page_type } = this.pageForm
      const params = {
        page_name,
        page_description,
        is_open,
        page_share_title,
        page_share_desc,
        page_share_imageUrl,
        page_type:page_type,
        template_name: this.template_name

      }

      console.log(params)
      console.log(params.page_type)
      if(params.page_type === undefined || params.page_type ===  ""){
        this.$message.error('请选择页面类型')
        return
      }

      console.log("savepage:",params)

      if (this.dialogTitle == '编辑页面') {
        editCustomPage(id, params).then(res => {
          this.page_dialog = false
          this.fetchPageList()
          this.$message({
            type: 'success',
            message: '保存页面成功'
          })
        })
      }
      if (this.dialogTitle == '新增页面') {
        createCustomPage(params).then(res => {
          this.page_dialog = false
          this.fetchPageList()
          this.$message({
            type: 'success',
            message: '保存页面成功'
          })
        })
      }
    },
    fetchPageList () {
      this.loading = true
      Object.assign(this.params, { template_name: this.template_name })
      getCustomPageAgreementList(this.params).then(response => {
        if (response.data.data.list) {
          this.list = response.data.data.list
          this.total_count = response.data.data.total_count
        }
        this.loading = false
      })
    },
    handleCancel () {
      this.page_dialog = false
    },
    // 上传卡封面
    handleImgChange () {
      this.imgDialog = true
      this.isGetImage = true
    },

    pickImg (data) {
      this.pageForm.page_share_imageUrl = data.url
      this.imgDialog = false
    },
    closeImgDialog () {
      this.imgDialog = false
    },
    submitExamine (row) {
      this.listLoading = true
      this.$confirm('确认提交审核?审核期间将不能编辑该模版', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let params = { template_id: row.id, template_type: 'custompage' }
        submitExamineTemplate(params).then(response => {
          if (response.data.data.success == true) {
            this.$message.success('提交成功')
          } else {
            this.$message.error('提交失败')
          }
          this.fetchPageList()
        }).catch(error => {
          this.$message.error('提交失败')
          this.fetchPageList()
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
        this.listLoading = false
      })
    }
  },
  mounted () {
    this.fetchPageList()
  }
}
</script>

<style>
.op_btn {
  margin-top: 3px;
}
</style>
