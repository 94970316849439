import { createSetting } from '@shopex/finder'
import { batchDelete, update } from '../api'
import { useDialog } from '@shopex/finder'

const commonActions = [
  { label: '取消', key: 'close', size: 'default' },
  { label: '提交', key: 'save', type: 'primary', size: 'default' }
]

export const tableSetting = (vm) => createSetting({
  search: [
    { name: '地址片段', key: 'content' },
    {
      name: '添加时间',
      key: 'addTime',
      type: 'date-time-picker',
      valueFormat: 'timestamp',
      startPlaceholder: '开始时间',
      endPlaceholder: '结束时间'
    },
  ],
  columns: [
    { name: '地址片段关键词', key: 'content', minWidth: 200 },
    {
      name: '风控状态',
      key: 'disabled',
      showType: 'switch',
      minWidth: 120,
      componentProps: {
        activeValue: false,
        inactiveValue: true,
        change: (v, row) => {
          update({ disabled: v, id: row.id }).then(res => {
            vm.$message.success('修改成功')
            vm.refresh()
          })
        }
      }
    },
    { name: '添加时间', key: 'create_date', minWidth: 180 },
  ],
  actions: [
    {
      name: '添加地址关键词',
      slot: 'header',
      type: 'button',
      buttonType: 'primary',
      action: {
        handler: async () => {
          useDialog(null, {
            title: `添加地址关键词`,
            width: '30%',
            component: () => import('../detail.vue'),
            actions: commonActions
          }).then((args) => {
            if(args) {
              vm.refresh()
            }
          })
        }
      }
    },
    {
      name: '导入',
      slot: 'header',
      type: 'button',
      buttonType: 'primary',
      action: {
        handler: () => {
          vm.openImportDialog()
        }
      }
    },
    {
      name: '批量删除',
      slot: 'header',
      type: 'button',
      buttonType: 'primary',
      action: {
        handler: async (rows) => {
          if(!rows?.length) {
            vm.$message.warning('请选择')
            return
          }
          vm.$confirm('请确认是否批量删除？', '提示').then(async () => {
            const ids = rows.map(v => v.id)
            await batchDelete(ids)
            vm.$message.success('删除成功')
            vm.refresh()
          })
        }
      }
    },
    {
      name: '编辑',
      type: 'button',
      buttonType: 'text',
      action: {
        handler: async (row) => {
          const item = row[0]
          useDialog(null, {
            title: `编辑地址关键词`,
            width: '30%',
            detailData: {
              id: item.id,
              content: item.content,
              disabled: item.disabled
            },
            component: () => import('../detail.vue'),
            actions: commonActions
          }).then((args) => {
            if(args) {
              vm.refresh()
            }
          })
        }
      }
    },
    {
      name: '删除',
      type: 'button',
      buttonType: 'text',
      action: {
        handler: async (rows) => {
          vm.$confirm('此操作将删除该地址关键词, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(async () => {
            await batchDelete(rows[0].id)
            vm.$message.success('删除成功')
            vm.refresh()
          })
        }
      }
    },
  ]
})
