<template>
  <div class="section section-white">
    <el-form ref="form" :model="form" label-position="left" label-width="120px">
      <div class="section-body">
        <el-form-item label="店招封面">
          <div class="frm-tips">只能上传jpg/png文件，且不超过2M （建议尺寸：320px * 100px）</div>
          <div>
            <div @click="handleImgChange" class="upload-box">
              <img v-if="form.banner_img" :src="wximageurl + form.banner_img" class="avatar"/>
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </div>
          </div>
          <imgPicker :dialog-visible="imgDialog" :sc-status="isGetImage" @chooseImg="pickImg" @closeImgDialog="closeImgDialog"></imgPicker>
        </el-form-item>
        <el-form-item label="推广员计划">
          <el-alert title="商家可邀请员工、合作伙伴、朋友等作为推广员一起推广您的店铺或商品, 成功推广后给予推广员奖励，以此给店铺带来更多的曝光和销售提升" type="info" close-text=" " class="alert-text"> </el-alert>
          <el-switch v-model="form.isOpenPopularize" :width="50" active-value="true" inactive-value="false" active-text="开启" inactive-text="关闭" active-color="#13ce66"></el-switch>
        </el-form-item>
        <el-form-item label="推广商品">
          <el-radio-group v-model="form.goods">
            <div class="frm-tips">
              <p>全部商品，表示所有的商品推广进行返佣。指定推广商品后，只有设置指定的商品可以进行推广返佣</p>
              <p>注意：将指定推广商品模式切换到全部商品模式，不会清空以前的选择，重新再切回指定商品模式，保留以前的选择商品</p>
            </div>
            <el-radio label="all">全部商品</el-radio>
            <el-radio label="select">指定推广商品</el-radio>
          </el-radio-group>
        </el-form-item>
        <template v-if="form.isOpenPopularize == 'true'">
          <el-form-item label="提现限制">
              用户最少申请提现 <el-input type="number" required min="1" v-model="form.limit_rebate" placeholder="" style="width: 100px;" />元
          </el-form-item>
          <el-form-item label="结算限制">
              订单完成 <el-input type="number" required min="0" v-model="form.limit_time" placeholder="" style="width: 100px;" /> 天后结算
          </el-form-item>
          <el-form-item label="成为推广员门槛">
              <el-radio-group v-model="form.change_promoter.type">
                  <el-radio label="no_threshold">无门槛成为推广员</el-radio>
                  <el-radio label="internal">仅内部可推广</el-radio>
                  <el-radio label="vip_grade">成为指定付费等级会员</el-radio>
                  <el-radio label="consume_money">会员消费达到指定金额</el-radio>
                  <el-radio label="order_num">会员已完成订单数</el-radio>
              </el-radio-group>
              <div v-show="form.change_promoter.type == 'vip_grade'" class="gap-text">
                选择付费等级
                <el-select v-model="form.change_promoter.filter.vip_grade" placeholder="请选择">
                  <el-option
                    v-for="item in vipGradeList"
                    :key="item.lv_type"
                    :label="item.grade_name"
                    :value="item.lv_type">
                  </el-option>
                </el-select>
              </div>
              <div v-show="form.change_promoter.type == 'consume_money'" class="gap-text">
                达到 <el-input type="number" required min="0" v-model="form.change_promoter.filter.consume_money" placeholder="" style="width: 100px;" onkeyup="this.value=this.value.replace(/\D/g,'')" /> 元
              </div>
              <div v-show="form.change_promoter.type == 'order_num'" class="gap-text">
                完成 <el-input type="number" required min="0" v-model="form.change_promoter.filter.order_num" placeholder="" style="width: 100px;" /> 笔
              </div>
          </el-form-item>
          <el-form-item label="返佣类型">
              <el-radio-group v-model="form.commission_type">
                  <el-radio label="money">金额</el-radio>
                  <el-radio v-if="system_mode === 'platform'" label="point">积分</el-radio>
              </el-radio-group>
          </el-form-item>
          <el-form-item label="返佣计算类型">
            <div class="frm-tips">如果商品未指定，返佣计算类型，那么则使用当前设置的通用方式，如果在商品中指定了返佣模式，那么指定商品则使用自己的返佣方式</div>
            <el-radio-group v-model="form.popularize_ratio.type" @change='changePopularizeRatioType'>
              <el-radio label="profit">按利润分佣</el-radio>
              <el-radio label="order_money">按订单金额分佣</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="推广员奖金设置">
              <div v-show="form.popularize_ratio.type == 'profit'">
                  <el-alert title="商品利润 ×  百分比，其中商品利润为【支付金额-运费-商品成本价】" type="info" close-text=" " class="alert-text"> </el-alert>
                  <el-row v-for="(item,key) in form.popularize_ratio.profit" :key="key">
                      <el-col :span="2">
                          {{item.name}}：
                      </el-col>
                      <el-col :span="4">
                          <el-input type="number" required min="0" v-model="form.popularize_ratio.profit[key].ratio" maxlength=3 placeholder=""><template slot="append">%</template></el-input>
                      </el-col>
                  </el-row>
              </div>
              <div v-show="form.popularize_ratio.type == 'order_money'">
                  <el-alert title="订单金额 × 百分比，其中订单金额为【支付金额-运费】" type="info" close-text=" " class="alert-text"> </el-alert>
                  <el-row v-for="(item,key) in form.popularize_ratio.order_money" :key="key">
                      <el-col :span="2">
                          {{item.name}}：
                      </el-col>
                      <el-col :span="4">
                          <el-input type="number" required min="0" v-model="form.popularize_ratio.order_money[key].ratio" maxlength=3 placeholder="">
                            <template slot="append">%</template>
                          </el-input>
                      </el-col>
                  </el-row>
              </div>
          </el-form-item>
          <el-form-item label="虚拟店铺">
              <el-switch v-model="form.isOpenShop" :width="50" active-value="true" inactive-value="false" active-text="开启" inactive-text="关闭" active-color="#13ce66"></el-switch>
          </el-form-item>
          <el-form-item  v-if="form.isOpenShop == 'true'">
            <p v-for="(item, index) in FormworkList" :key="index">
              <template v-if="form.custompage_template_id == item.id">
                {{item.page_name}}
              </template>
            </p>
            <el-button  @click="batchChooseFormwork">设置虚拟店首页</el-button>

            <div class="frm-tips">还未装修虚拟店首页？请先<a style="cursor: pointer;" @click="linkTo()">点击此处</a>加一个自定义页面</div>
          </el-form-item>
          <el-dialog title="选择虚拟店首页模板" class="right-dialog" :visible.sync="FormworkVisible">
            <template>
                <el-form-item v-if="FormworkList.length > 0" v-loading="loading">
                  <el-radio-group v-model="form.custompage_template_id">
                    <el-radio class="radioItem" :label="item.id" v-for="(item, index) in FormworkList" :key="index" @change="onSubmitFormwork(item)">{{item.page_name}}</el-radio>
                  </el-radio-group>
                  <el-pagination
                    class="pagination"
                    layout="prev, pager, next"
                    :current-page="params.page"
                    :total="total_count"
                    @current-change="handleCurrentChange"
                  >
                  </el-pagination>
                </el-form-item>
                <p class="font-bold nopost" v-else>暂无模板，请先<a style="cursor: pointer;" @click="linkTo()">点击此处</a>添加自定义页面</p>
            </template>
          </el-dialog>
          <hr/>
          <el-form-item label="充值返佣">
            <div class="frm-tips">充值返佣，只支持积分返佣</div>
            <el-switch v-model="form.isOpenRecharge" :width="50" active-value="true" inactive-value="false" active-text="开启" inactive-text="关闭" active-color="#13ce66"></el-switch>
          </el-form-item>
          <el-form-item label="充值返佣设置" v-if="form.isOpenRecharge == 'true'">
              <div>
                  <el-row v-for="(item,key) in form.recharge.profit" :key="key">
                      <el-col :span="2">
                          {{item.name}}：
                      </el-col>
                      <el-col :span="4">
                          <el-input type="number" required min="0" v-model="form.recharge.profit[key].ratio" maxlength=3 placeholder=""><template slot="append">%</template></el-input>
                      </el-col>
                  </el-row>
              </div>
          </el-form-item>
          <!--
          <el-form-item label="推广引导页面">
              <el-switch v-model="form.isOpenGuide" :width="50" active-value="true" inactive-value="false" active-text="开启" inactive-text="关闭" active-color="#13ce66"></el-switch>
              <div v-show="form.isOpenGuide == 'true'">
                <div @click="handleImgChange" class="upload-box">
                  <img v-if="form.guideImg" :src="wximageurl + form.guideImg" class="pic"/>
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </div>
                <imgPicker :dialog-visible="imgDialog" :sc-status="isGetImage" @chooseImg="pickImg" @closeImgDialog="closeImgDialog"></imgPicker>
              </div>
          </el-form-item>
          -->
        </template>
      </div>
      <div class="section-footer with-border content-center">
        <el-button type="primary" @click="save">保 存</el-button>
      </div>
    </el-form>
  </div>
</template>
<script>
  import { getPopularizeSetting, setPopularizeSetting } from '../../api/promotions'
  import { listVipGrade } from '../../api/cardticket'
  import { getCustomPageList } from '@/api/wxa'
  import imgPicker from '../../components/imageselect'
  import { getWeappId }  from '../../api/template'
  export default {
    components: {
      imgPicker
    },
    data () {
      return {
          params: {
              page: 1,
              pageSize: 10,
          },
          form: {
            goods: 'all',
            commission_type: 'money',
            isOpenPopularize: false,
            change_promoter: {
                type: 'no_threshold',
                filter: {
                  no_threshold: 0,
                  vip_grade: 'vip',
                  consume_money: 0,
                  order_num: 0,
                }
            },
            popularize_ratio: {
                order_money: [],
                profit: {
                  first_level: {
                    name: '上级',
                    ratio: 0
                  },
                  second_level: {
                    name: '上上级',
                    ratio: 0
                  }
                },
                type: 'profit'
            },
            isOpenShop: false,
            isOpenGuide: false,
            isOpenRecharge: false,
            recharge: {
              profit: {
                first_level: {
                  name: '上级',
                  ratio: 0
                },
                second_level: {
                  name: '上上级',
                  ratio: 0
                }
              }
            },
            // guideImg: '',
            banner_img: ''
          },
          vipGradeList: {},
          imgDialog: false,
          isGetImage: false,
          custompage_template_id:0,
          FormworkVisible: false,
          FormworkList: []
      }
    },
    methods: {
      linkTo () {
          let link = ''
          getWeappId().then(res => {
              var data = res.data.data.weappid
              var tempName = res.data.data.tempName
              if (data) {
                  link = this.matchInternalRoute('custompage')
                  this.$store.dispatch('setWxappId', data)
                  this.$store.dispatch('setTemplateName', tempName)
              }
              this.$router.push({
                  path: link
              })
          })
      },
      changePopularizeRatioType(type) {
        console.log(type)
        this.$confirm('切换返佣计算类型，将清空单品比例返佣设置（固定金额不影响），之前配置过的单品返佣比例需要重新配置', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
        }).catch(() => {
          if (type == 'order_money') {
            this.form.popularize_ratio.type = 'profit'
          } else {
            this.form.popularize_ratio.type = 'order_money'
          }
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
      },
      handleImgChange () {
        this.imgDialog = true
        this.isGetImage = true
      },
      pickImg (data) {
        // this.form.guideImg = data.url
        this.form.banner_img = data.url
        this.imgDialog = false
      },
      closeImgDialog () {
        this.imgDialog = false
      },
      save() {
        let plusReg = /^[1-9](\d+)?$/
        let countReg = /^\d+$/

        if(this.form.isOpenPopularize) {
          if (this.form.change_promoter.type == 'vip_grade') {
            if (this.form.change_promoter.filter.vip_grade == '' || this.form.change_promoter.filter.vip_grade == '请选择') {
              this.$message({message: '请选择成为指定付费等级会员', type: 'error'})
              return
            }
          }
          //
          // if (this.form.isOpenGuide && this.form.guideImg == '') {
          //   //this.$message({message: '请选择图片', type: 'error'})
          //   //return
          // }

        }

        setPopularizeSetting(this.form).then(res => {
            this.$message({message: '保存成功', type: 'success'})
        })
      },
      // 自定义页面模板列表
      getFormworkList() {
          this.loading = true
          getCustomPageList(this.params).then(response => {
              this.FormworkList = response.data.data.list
              this.total_count = response.data.data.total_count
              this.loading = false
          })
      },
      handleCurrentChange: function (val) {
        this.params.page = val
        this.getFormworkList()
      },
      // 拉取自定义列表数据
      batchChooseFormwork() {
          this.FormworkVisible = true
          this.params.page = 1
          this.getFormworkList()
      },
      onSubmitFormwork(item) {
          this.FormworkVisible = false
      }
    },
    mounted () {
      // this.getFormworkList()
      getPopularizeSetting().then(res => {
        this.form = res.data.data
        this.form.goods = this.form.goods ? this.form.goods : 'all'
        if (typeof this.form.recharge == 'undefined') {
          this.form.recharge = {
            profit: {
              first_level: {
                name: '上级',
                ratio: 0
              },
              second_level: {
                name: '上上级',
                ratio: 0
              }
            }
          }
        }
      }),
      listVipGrade().then(response => {
        if(response != undefined && response.data.data && response.data.data.length >0) {
          this.vipGradeList = response.data.data
        }
      })
      // getWeappId().then(response => {
      //     var res = response.data.weappid
      //     this.weappid = res
      // })
    }
  }
</script>
<style scoped lang="scss">
/*.upload-box {*/
/*  display: inline-block;*/
/*  border: 1px dashed #c0ccda;*/
/*  border-radius: 6px;*/
/*  cursor: pointer;*/
/*  overflow: hidden;*/
/*  width: 148px;*/
/*  height: 148px;*/
/*  line-height: 145px;*/
/*  margin-top: 20px;*/
/*  text-align: center;*/
/*  .avatar-uploader-icon {*/
/*    font-size: 38px;*/
/*  }*/
/*  .pic {*/
/*    max-width: 148px;*/
/*    max-height: 148px;*/
/*    vertical-align: middle;*/
/*  }*/
/*}*/
.alert-text {
  padding: 0 20px;
  margin-bottom: 20px;
}
.gap-text {
  margin-top: 20px;
}
.radioItem {
  display: block;
  margin: 10px 0;
}
.pagination {
  margin-top: 30px;
  text-align: center;
}
</style>
