<template>
  <div>
    <mallListDistributor v-if="store" :rel-store="store" template-type="distributor" />
  </div>
</template>

<script>
  import mallListDistributor from '@/components/function/mallListDistributor'
    export default {
      components: {
        mallListDistributor
      },
      data () {
        return {
          store: null
        }
      },
      mounted () {
        this.store = {id: '0'}
      }
    }
</script>
