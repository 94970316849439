import fetch from '@/utils/fetch'

export const List = '/secure/black_address/lists'

export function create (data) {
  return fetch({
    url: '/secure/black_address/add',
    method: 'post',
    data
  })
}

export function update (data) {
  return fetch({
	  url: '/secure/black_address/update',
	  method: 'post',
	  data
  })
}
export function detail (id) {
  return fetch({
	  url: '/secure/black_address/delete',
	  method: 'post',
	  data: {
      id
    }
  })
}

export function batchDelete (ids) {
  return fetch({
    url: '/secure/black_address/delete',
    method: 'post',
    data: {
      ids
    }
  })
}
