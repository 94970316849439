<template>
  <transition name="fade" mode="out-in">
    <mallDistributor></mallDistributor>
  </transition>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { getWxaAuthList } from '../../api/wxa'
  // mall
  import mallDistributor from '@/view/wxapp/template/mall/distributor'

  export default {
    data() {
      return {
        activeIndex: '',
        currentCode: '',
        wxapps: [],
      }
    },
    components: {
      mallDistributor: mallDistributor,
    },
    computed: {
      ...mapGetters([
        'wxapp_id',
        'template_name'
      ])
    },
    methods: {
      chooseTemp () {
        this.$router.push({path: this.matchInternalRoute('wxapp_templ')})
      },
      handleGoodsChange (index) {
        this.$emit('bindLinks', index)
      },
    },
    mounted () {
      if(this.active){
        this.activeIndex = this.active
      }
      if (this.$route.query.wxappid !== undefined) {
        this.$store.dispatch('setWxappId', this.$route.query.wxappid)
      }
      if (this.$route.query.templatename) {
        this.$store.dispatch('setTemplateName', this.$route.query.templatename)
      }
    }
  }
</script>
