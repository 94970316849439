import fetch from '@/utils/fetch'

export function getMemberData (query) {
  return fetch({
    url: '/members/guarantee/distiSearch',
    method: 'get',
    params: query
  })
}

/** 修改sn */
export function deviceSn (data) {
  return fetch({
    url: '/members/guarantee/deviceSn',
    method: 'post',
    data
  })
}
